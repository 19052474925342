/* eslint-disable no-unused-vars */
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
/* eslint-enable no-unused-vars */

export default function HorseFeedRow({ horse, barns }) {
  function clickLink() {
    if (horse) {
      return '/horses/feed/' + horse.id;
    }
    return '/horses';
  }

  return (
    <LinkContainer to={clickLink()}>
      <tr key={horse.id}>
        <td>{horse.name}</td>
        <td>{horse.feed}</td>
        <td>{horse.barnName}</td>
      </tr>
    </LinkContainer>
  );
}

HorseFeedRow.propTypes = {
  horse: PropTypes.object.isRequired,
};
