/* AUTO GEN */
/* Barn.js */
export class Barn {
  constructor(
    id, 
    userId, 
    name, 
    dataStatus,
    barnOwnerName
  ) {
    this.id = id;
    this.userId = userId;
    this.name = name;
    this.dataStatus = dataStatus;
    this.barnOwnerName = barnOwnerName;
  }
}

export function barnToClass(barn) {
  if (barn) {
    return new Barn(
      barn.id, 
      barn.userId, 
      barn.name, 
      barn.dataStatus,
      barn.barnOwnerName
    );
  } else {
    return null;
  }
}

export function barnsToClassArray(barns) {
  if (barns) {
    const barnsArray = barns.map((item) => {
      return barnToClass(item);
    });
    return barnsArray;
  }
}

