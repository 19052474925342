/* (( *** AUTO GEN *** )) */
/* permissionActions.js */
import axios from 'axios';
import { SET_PERMISSIONS, PERMISSION_FETCHED } from '../types';
import { permissionToClass, permissionsToClassArray } from '../../classes/Permission';

export function permissionFetched(permission) {
  return {
    type: PERMISSION_FETCHED,
    permission: permissionToClass(permission),
  };
}

export function setPermissions(permissions) {
  return {
    type: SET_PERMISSIONS,
    permissions: permissionsToClassArray(permissions),
  };
}

export const fetchPermission = (id) => async (dispatch) => {
  const res = await axios.get('/api/permissions/read/' + id);
  dispatch(permissionFetched(res.data));
};
export const fetchPermissions = () => async (dispatch) => {
  const res = await axios.get('/api/permissions/read');
  dispatch(setPermissions(res.data));
};

export const fetchPermissionsById = (id) => async (dispatch) => {
  const res = await axios.get('/api/permissions/read/' + id);
  dispatch(setPermissions(res.data));
};

export const createPermission = (values) => async (dispatch) => {
  const res = await axios.post('/api/permissions/create', values);
  dispatch(permissionFetched(res.data));
};

export const updatePermission = (values) => async (dispatch) => {
  const res = await axios.post('/api/permissions/update', values);
  dispatch(permissionFetched(res.data));
};

export const updatePermissionProperties = (values) => async (dispatch) => {
  // {
  //   id, 
  //   properties
  // }
  const res = await axios.post('/api/permissions/properties/update', values);
  dispatch(permissionFetched(res.data));
};

export const addPermissionProperty = (values) => async (dispatch) => {
  // {
  //   permissionId, 
  //   propertyTypeId,
  //   propertyName,
  // }
  const res = await axios.post('/api/permissions/properties/add', values);
  dispatch(permissionFetched(res.data));
};

export const deletePermission = (values) => async (dispatch) => {
  const res = await axios.post('/api/permissions/update', values);
  dispatch(setPermissions(res.data));
};
