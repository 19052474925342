/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import TackSaleTableRow from './TackSaleTableRow';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TackSaleTable extends Component {
  renderTacks() {
    return _.values(this.props.tacks).map((tack) => {
      if (tack && tack.dataStatus < 3) {
        return (
          <TackSaleTableRow tack={tack} key={tack.id} auth={this.props.auth} />
        );
      } else {
        return null;
      }
    });
  }

  render() {
    return (
      <Table striped bordered    hover>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>{this.renderTacks()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ tacks, auth }) {
  return { tacks, auth };
}

export default connect(mapStateToProps)(TackSaleTable);
