/* (( *** AUTO GEN *** )) */
/* communityUserActions.js */
import axios from 'axios';
import { SET_COMMUNITY_USERS, COMMUNITY_USER_FETCHED } from '../types';

export function communityUserFetched(communityUser) {
  return {
    type: COMMUNITY_USER_FETCHED,
    communityUser,
  };
}

export function setCommunityUsers(communityUsers) {
  return {
    type: SET_COMMUNITY_USERS,
    communityUsers,
  };
}

export const fetchCommunityUser = (id) => async (dispatch) => {
  const res = await axios.get('/api/communityusers/read/' + id);
  dispatch(communityUserFetched(res.data));
};

export const fetchCommunityUsers = () => async (dispatch) => {
  const res = await axios.get('/api/communityusers/read');
  dispatch(setCommunityUsers(res.data));
};

export const createCommunityUser = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityusers/create', values);
  dispatch(communityUserFetched(res.data));
};

export const updateCommunityUser = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityusers/update', values);
  dispatch(communityUserFetched(res.data));
};
