import axios from 'axios';
import { SALE_TACK_FETCHED, SET_SALE_TACKS } from './types';

export function tackSaleFetched(tacksale) {
  return {
    type: SALE_TACK_FETCHED,
    tacksale,
  };
}

export function setTackSales(tacksale) {
  return {
    type: SET_SALE_TACKS,
    tacksale,
  };
}

export const fetchTackSales = () => async (dispatch) => {
  const res = await axios.get('/api/tack/sales');
  dispatch(setTackSales(res.data));
};

export const fetchTackSale = (id) => async (dispatch) => {
  const res = await axios.get('/api/tack/sales/' + id);
  console.log(res.data);
  dispatch(tackSaleFetched(res.data));
};
