const EVENT_TYPE_RHT = 1;
const EVENT_TYPE_SHT = 3;

export function eventImage(event) {
  if (event != null) {
    switch (event.type) {
      case 0:
        return '/images/events/thumb_UNK.jpg';
      case 1:
        return '/images/events/thumb_HT.jpg';
      case 2:
        return '/images/events/thumb_CT.jpg';
      case 3:
        return '/images/events/thumb_SHT.jpg';
      case 4:
        return '/images/events/thumb_DRESSAGE.jpg';
      case 5:
        return '/images/events/thumb_CLINIC.jpg';
      case 6:
        return '/images/events/thumb_XCTests.jpg';
      case 7:
        return '/images/events/thumb_YEH.jpg';
      default:
        return '/images/events/thumb_NEW_EVENT.jpg';
    }
  } else {
    return '/images/events/thumb_NEW_EVENT.jpg';
  }
}

export function getEventTypeText(event) {
  if (event != null) {
    switch (event.type) {
      case 'RECOGNIZED_HT':
        return 'Recognized Horse Trials';
      case 'SCHOOLING_HT':
        return 'Schooling Horse Trials';
      case 'COMBINED_TEST':
        return 'Combined Test';
      case 'DRESSAGE_TOC':
        return 'Dressage Tests-of-Choice';
      case 'JUMP_ROUNDS':
        return 'Jump Rounds';
      case 'OPEN_SHOW':
        return 'Open Show';
      default:
        return '';
    }
  }
  return '';
}

export function isOnlineEntryType(event) {
  switch (event.type) {
    case EVENT_TYPE_RHT:
      return true;
    case EVENT_TYPE_SHT:
      return true;
    default:
      return false;
  }
}
