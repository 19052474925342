import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';

export function createOptions(idArray) {
  var zeroExists = false;
  if (idArray) {
    let array = _.map(idArray, (idobj) => {
      if (parseInt(idobj.id) === 0) {
        zeroExists = true;
      }
      return {
        label: idobj.name,
        id: idobj.id,
      };
    });
    if (!zeroExists) {
      array.splice(0, 0, { label: 'None', id: 0 });
    }
    return array;
  }
  return null;
}

export function ObjectsCombo({ options, label, barnId, valueUpdated, horse }) {
  const [value, setValue] = React.useState(
    options.find((x) => x.id === barnId)
  );

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        valueUpdated(newValue, horse);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="controllable-states-demo"
      options={options}
      sx={{ width: 300 }}
      disableClearable //removes ability to zero out to null
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" />
      )}
    />
  );
}
