import React from 'react';

import { Alert, Tooltip } from '@mui/material';

function tipText(property) {
  if (property) {
    if (property.getTypeName) {
      return 'Name: ' + property.name + '\r\nType: ' + property.getTypeName();
    }
    return 'Name: ' + property.name + '\r\nType: Undef';
  }
}

// THE FOLLOWING IS NEEDED to be sure the Tooltip text CRLFs.  No idea why.

// title={
//     <span style={{ whiteSpace: 'pre-line' }}>{tipText(testData)}</span>
//   }

export const PropertyItem = ({ property }) => {
  return (
    <Tooltip
      title={
        <span style={{ whiteSpace: 'pre-line' }}>{tipText(property)}</span>
      }
      placement="left"
    >
      <Alert severity="success" onClose={() => {}}>
        {property.toString()}
      </Alert>
    </Tooltip>
  );
};
