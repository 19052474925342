import axios from 'axios';
import {
  FETCH_USER,
  ADD_TACK,
  TACK_FETCHED,
  SET_TACK,
  SET_TACK_PHOTOS,
  TACK_PHOTO_FETCHED,
} from './types';

export function addTack(tack) {
  return {
    type: ADD_TACK,
    tack,
  };
}

export function tackFetched(tack) {
  return {
    type: TACK_FETCHED,
    tack,
  };
}

export function setTacks(tacks) {
  return {
    type: SET_TACK,
    tacks,
  };
}

export function setTackPhotos(tackPhotos) {
  return {
    type: SET_TACK_PHOTOS,
    tackPhotos,
  };
}

export function tackPhotoFetched(tackPhoto) {
  return {
    type: TACK_PHOTO_FETCHED,
    tackPhoto,
  };
}

export const submitTack = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/tack/create', values);
  history.push('/tack');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchTacks = () => async (dispatch) => {
  const res = await axios.get('/api/tack/read');
  dispatch(setTacks(res.data));
};

export const saveTack = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/tack/create', values);
  dispatch(tackFetched(res.data));
};

export const fetchTack = (id) => async (dispatch) => {
  const res = await axios.get('/api/tack/read/' + id);
  dispatch(tackFetched(res.data));
};

export const updateTack = (values) => async (dispatch) => {
  const res = await axios.post('/api/tack/update', values);
  dispatch(tackFetched(res.data));
};

export const addPhotoToTack = (values) => async (dispatch) => {
  const res = await axios.post('/api/tack/photos/new/multi', values);
  dispatch(setTackPhotos(res.data));
};

export const setMainPhotoForTack = (values) => async (dispatch) => {
  const res = await axios.post('/api/tack/photos/setmain', values);
  dispatch(tackFetched(res.data));
};

export const deleteTackPhoto = (values) => async (dispatch) => {
  console.log(values);
  const res = await axios.post('/api/tack/photos/delete', values);
  dispatch(setTackPhotos(res.data));
};

export const fetchTackPhotos = (tackId) => async (dispatch) => {
  const res = await axios.get('/api/tack/read/photos/fortack/' + tackId);
  dispatch(setTackPhotos(res.data));
};
