import React from 'react';
import { Alert } from 'react-bootstrap';
import { Clock } from 'react-bootstrap-icons';
import XCCourseInfoTable from '../core/XCCourseInfoTable';

export default function XCCourseInfo() {
  return (
    <div>
      <Alert>
        <h2>
          <Clock />
          &nbsp;&nbsp; XC Course Info
        </h2>
      </Alert>
      <XCCourseInfoTable />
    </div>
  );
}
