import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalObjSelector,
  renderFormTitle,
  okCancel,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createProductCategory,
  updateProductCategory,
} from '../../../redux/actions/store/productCategoriesActions';
const _ = require('lodash');

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const ProductCategoryForm = (props) => {
  const dispatch = useDispatch();

  function submitProductCategory(productCategory) {
    if (productCategory && productCategory.id) {
      return dispatch(updateProductCategory(productCategory)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createProductCategory(productCategory)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id:
      props.productCategory && props.productCategory.id
        ? props.productCategory.id
        : null,
    parentCategoryId:
      props.productCategory && props.productCategory.parentCategoryId
        ? props.productCategory.parentCategoryId
        : null,
    categoryName:
      props.productCategory && props.productCategory.categoryName
        ? props.productCategory.categoryName
        : '',
    dataStatus:
      props.productCategory && props.productCategory.dataStatus
        ? props.productCategory.dataStatus
        : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.productCategory) {
      Object.assign(props.productCategory, values);
      submitProductCategory(props.productCategory);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitProductCategory(newData);
    }
    if (props.onOkClick) {
      props.onOkClick();
    }
  };

  function productCategoriesList() {
    var zeroExists = false;
    if (props.productCategories) {
      let array = _.map(props.productCategories, (idobj) => {
        if (parseInt(idobj.id) === 0) {
          zeroExists = true;
        }
        return {
          value: idobj.id,
          key: idobj.categoryName,
        };
      });
      if (!zeroExists) {
        array.splice(0, 0, { value: null, key: 'None' });
      }
      return array;
    }
    return null;
  }

  return (
    <div>
      {renderFormTitle(props.productCategory, 'Product Category')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalObjSelector(
              'parentCategoryId',
              'Parent Category',
              productCategoriesList()
            )}
            {finalTextField('categoryName', 'Category Name')}

            {okCancel(props.onOkClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default ProductCategoryForm;
