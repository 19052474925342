/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Image,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import { fetchTackSale } from '../../../redux/actions/tackSaleActions';
import TackSalePhotoCard from '../../core/TackSalePhotoCard';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TackListingPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      photos: this.props.tacksale ? this.props.tacksale.photos : [],
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchTackSale(this.props.match.params.id);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tacksale) {
      this.setState({
        photos: nextProps.tacksale.photos,
      });
    }
  };

  getTackName() {
    if (this.props.tacksale) {
      return this.props.tacksale.name;
    }
    return 'loading';
  }

  getBriefDescription() {
    if (this.props.tacksale) {
      return this.props.tacksale.description;
    }
    return '';
  }

  getGeneralInformation() {
    if (this.props.tacksale) {
      return (
        <ListGroup>
          <ListGroupItem>Price: ${this.props.tacksale.price}</ListGroupItem>
          <ListGroupItem>Brand: {this.props.tacksale.brandName}</ListGroupItem>
          <ListGroupItem>Color: {this.props.tacksale.color}</ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  renderPhotos() {
    if (this.state.photos && this.state.photos.length > 0) {
      return this.state.photos.map((photo) => {
        return (
          <tr key={photo.id}>
            <td>
              <div key={photo.id}>
                <hr />
                <TackSalePhotoCard tackPhoto={photo} key={photo.id} />
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return (
        <div>
          <Image
            src="/images/FGFarmLogo150h.PNG"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      );
    }
  }

  render() {
    const photosPanel = (
      <Card variant="danger">
        <Card.Header>
          <h2>Photos</h2>
        </Card.Header>
        <Card.Body>
          <Table striped bordered    hover>
            <thead>
              <tr>
                <th>Photos</th>
              </tr>
            </thead>
            <tbody>{this.renderPhotos()}</tbody>
          </Table></Card.Body>
        <Card.Footer />
      </Card>
    );

    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Alert>
              <Easel />
              {this.getTackName()}
              <small> You are welcome here at Full Gallop</small>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="warning">
              <Card.Header>
                <Card.Title    >Description</Card.Title>
              </Card.Header>
              <Card.Body>{this.getBriefDescription()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >
                  General Information
                </Card.Title>
              </Card.Header>
              <Card.Body>{this.getGeneralInformation()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {photosPanel}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Cell Phone</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroupItem>Lara Anderson - (803) 215-6590</ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Email</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroupItem>
                    Lara Anderson - fullgallopfarm@gmail.com
                  </ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  horse: state.horses.find((item) => item._id === props.match.params._id),
      tacksale: state.tackSales[props.match.params.id],
      // auth: state.auth,
    };
  }

  return {
    tacksale: null,
  };
}

export default connect(mapStateToProps, {
  fetchTackSale,
})(TackListingPage);
