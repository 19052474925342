// ShoppingCart.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, Button } from '@mui/material';
import { css } from '@emotion/react';
import { selectCartItems, removeItem, clearCart } from '../../../redux/slices/cartSlice';

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const handleRemoveItem = (item) => {
    dispatch(removeItem(item));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  return (
    <div css={container}>
      <Typography variant="h5" gutterBottom>Shopping Cart</Typography>
      {cartItems.map(item => (
        <Grid key={item.id} container justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography>{item.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography>${item.price}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button variant="outlined" color="error" onClick={() => handleRemoveItem(item)}>Remove</Button>
          </Grid>
        </Grid>
      ))}
      {cartItems.length > 0 && (
        <Button variant="outlined" color="error" onClick={handleClearCart}>Clear Cart</Button>
      )}
    </div>
  );
};

const container = css`
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
`;

export default ShoppingCart;
