/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { textField, textArea, boolField } from '../../core/CoreForms';
import { Col, Image, Form, Button, ButtonToolbar } from 'react-bootstrap';
import ModalImage from 'react-modal-image';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
/* eslint-enable no-unused-vars */

class TrainerForm extends Component {
  state = {
    id: this.props.trainer ? this.props.trainer.id : null,
    bio: this.props.trainer ? this.props.trainer.bio : '',
    trainingPhilosophy: this.props.trainer
      ? this.props.trainer.trainingPhilosophy
      : '',
    experience: this.props.trainer ? this.props.trainer.experience : '',
    services: this.props.trainer ? this.props.trainer.services : '',
    photoURL: this.props.trainer ? this.props.trainer.photoURL : '',
    active: this.props.trainer ? this.props.trainer.active : false,
    errors: '',
    loading: false,
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.trainer) {
      this.setState({
        id: nextProps.trainer.id,
        bio: nextProps.trainer.bio,
        trainingPhilosophy: nextProps.trainer.trainingPhilosophy,
        experience: nextProps.trainer.experience,
        services: nextProps.trainer.services,
        photoURL: nextProps.trainer.photoURL,
        active: nextProps.trainer.active,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const {
        id,
        bio,
        trainingPhilosophy,
        experience,
        services,
        photoURL,
        active,
      } = this.state;
      this.setState({ loading: true });
      this.props
        .saveTrainer({
          id,
          bio,
          trainingPhilosophy,
          experience,
          services,
          photoURL,
          active,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderFormTitle() {
    if (this.state.id && this.state.id > 0) {
      return (
        <div>
          <h1>Edit Trainer</h1>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Add Trainer</h1>
        </div>
      );
    }
  }

  cancelButton() {
    return <Button variant="warning">Cancel</Button>;
  }

  saveButton() {
    return (
      <Button type="submit" variant="primary">
        Save
      </Button>
    );
  }

  okCancelButtons() {
    return (
      <ButtonToolbar>
        <LinkContainer to={'/account/trainer'}>{this.cancelButton()}</LinkContainer>
        {this.saveButton()}
      </ButtonToolbar>
    );
  }

  /* eslint-disable max-len */
  render() {
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        {this.renderFormTitle()}
        {!!this.state.errors.global && (
          <div className="ui negative message">
            <p>{this.state.errors.global}</p>
          </div>
        )}
        {textArea('bio', 'Bio', this)}
        {textArea('trainingPhilosophy', 'Training Philosophy', this)}
        {textArea('experience', 'Experience', this)}
        {textArea('services', 'Services', this)}
        {boolField('active', 'Active', this)}
        <div className="field">{this.okCancelButtons('/account')}</div>
      </Form>
    );
    return (
      <div>
        <Col xs={12} sm={12} md={12}>
          "Photo"
        </Col>
        <Col xs={12} sm={12} md={12}>
          {form}
        </Col>
      </div>
    );
  }
}

export default TrainerForm;
