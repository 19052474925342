/* (--- AUTO GEN ---) */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchCommunityUsers } from '../../../redux/actions/users/communityUserActions';
import _ from 'lodash';
import CommunityUsersTableRow from './CommunityUsersTableRow';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class CommunityUsersTable extends Component {
  componentDidMount() {
    this.props.fetchCommunityUsers();
  }

  renderCommunityUsers() {
    return _.values(this.props.communityUsers).map((communityUser) => {
      return (
        <CommunityUsersTableRow
          communityUser={communityUser}
          key={communityUser.id}
          auth={this.props.auth}
        />
      );
    });
  }

  render() {
    return (
      <Table striped bordered    hover>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>{this.renderCommunityUsers()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ communityUsers, auth }) {
  return { communityUsers, auth };
}

export default connect(mapStateToProps, { fetchCommunityUsers })(
  CommunityUsersTable
);
