import { EVENT_LEVELS_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_LEVELS_FETCHED:
        console.log(action);
      return [ ...action.eventLevels ];
    //   case EVENT_LEVEL_FETCHED:
    //     return [ ...state, action.division ];
    default:
      return state;
  }
}
