import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createOculusEvent } from '../../../../redux/actions/oculus/oculusEventActions';
import _ from 'lodash';

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  CardMedia,
  CardActionArea,
  Chip as MuiChip,
  Typography as MuiTypography,
  Stack,
} from '@mui/material';
import { spacing } from '@mui/system';

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== 'dark' &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

// const Chip = styled(MuiChip)`
//   top: 16px;
//   right: 16px;
//   height: 20px;
//   padding: 4px 0;
//   font-size: 85%;

//   span {
//     padding-left: ${(props) => props.theme.spacing(2)};
//     padding-right: ${(props) => props.theme.spacing(2)};
//   }
// `;

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

function pedigreeEmptyOrPartial(horse) {
  return JSON.stringify(horse.pedigree).toLowerCase().includes('unknown');
}

function getPedigreeBadge(horse) {
  if (horse && horse.pedigree && !pedigreeEmptyOrPartial(horse)) {
    return <Chip label="Pedigree" color="info" />;
  }
  return '';
}

function getPhotoCount(horse) {
  if (horse && horse.photos) {
    return horse.photos.length;
  } else {
    return 0;
  }
}

function getPhotoLabel(horse) {
  return getPhotoCount(horse) + ' Photos';
}

function getVideoCount(horse) {
  if (horse && horse.videos) {
    return horse.videos.length;
  } else {
    return 0;
  }
}

function getVideoLabel(horse) {
  return getVideoCount(horse) + ' Videos';
}

function getPhotosVideosBadges(horse) {
  if (horse) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        {getPhotoCount(horse) ? (
          <Chip label={getPhotoLabel(horse)} color="error" />
        ) : (
          ''
        )}
        {getVideoCount(horse) ? (
          <Chip label={getVideoLabel(horse)} color="warning" />
        ) : (
          ''
        )}
        {getPedigreeBadge(horse)}
      </Stack>
    );
  }
  return '';
}

function getIllustration(horse) {
  if (horse) {
    return horse.getMainPhotoURLThumb();
  } else return null;
}

function formatAsDollars(dollarValue) {
  if (dollarValue) {
    let formatting_options = {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    };
    let dollarString = new Intl.NumberFormat('en-US', formatting_options);
    return dollarString.format(dollarValue);
  }
}

function getPrice(horse) {
  if (!horse.sold) {
    return <div>{formatAsDollars(horse.price)}</div>;
  } else {
    return <div>SOLD</div>;
  }
}

const HorseStats = ({ auth }) => {
  const dispatch = useDispatch();

  function getExampleSaleHorse(saleshorses) {
    if (saleshorses) {
      return _.sample(saleshorses);
    }
    return null;
  }

  const saleHorseExample = useSelector((state) =>
    getExampleSaleHorse(state.saleshorses)
  );

  function getSaleHorseLink(horse) {
    if (horse) {
      return '/sales/horse/' + horse.id;
    }
  }

  function oculusData() {
    return {
      dataType: 'horse',
      horseId: saleHorseExample ? saleHorseExample.id : 0,
      name: saleHorseExample ? saleHorseExample.name : ' ',
    };
  }

  function oculusEvent(sender, type, horse) {
    return {
      userId: auth ? auth.id : null,
      sender,
      session: window.sessionStorage.getItem('SID'),
      type,
      data: oculusData(),
      objectId: saleHorseExample ? saleHorseExample.id : null,
    };
  }

  function handleClick() {
    dispatch(
      createOculusEvent(oculusEvent('HorseStats', 4))
    );
  }

  return (
    <>
      {saleHorseExample ? (
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            width: 345,
            '&:hover': {
              boxShadow: 'md',
              borderColor: 'neutral.outlinedHoverBorder',
            },
            // display: 'flex',
          }}
          illustration={getIllustration(saleHorseExample)}
          onClick={handleClick}
        >
          <CardActionArea
            sx={{
              width: 345,
              '&:hover': {
                boxShadow: 'md',
                borderColor: 'neutral.outlinedHoverBorder',
              },
              display: 'flex',
            }}
            component={Link}
            to={getSaleHorseLink(saleHorseExample)}
          >
            <CardMedia
              component="img"
              sx={{ width: '120px', height: 'fit-content', borderRadius: 2 }}
              image={getIllustration(saleHorseExample)}
              alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="h6" mb={4}>
                  {saleHorseExample.name}
                </Typography>
                <Typography variant="h3" mb={3}>
                  <Box fontWeight="fontWeightRegular">
                    {getPrice(saleHorseExample)}
                  </Box>
                </Typography>
              </CardContent>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'left', pl: 1, pb: 1 }}>
              {getPhotosVideosBadges(saleHorseExample)}
            </Box>
          </CardActionArea>
        </Card>
      ) : (
        ''
      )}
    </>
  );
};

export default HorseStats;
