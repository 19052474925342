import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchHorse } from '../../../redux/actions/horseActions';
import { fetchHorseSalesViewsByDayForWeek } from '../../../redux/actions/oculus/reporting/reportingActions';
import {
  Col,
  Card,
  Button,
  Container,
  Alert,
  Row,
  Tab,
  Tabs,
  Badge,
} from 'react-bootstrap';
import { Stack } from '@mui/material';
import { Easel } from 'react-bootstrap-icons';
import HorseDocumentManagementPanel from './core/HorseDocumentManagementPanel';
import HorseVideoManagementPanel from './core/HorseVideoManagementPanel';
import HorsePhotoManagementPanel from './core/HorsePhotoManagementPanel';
import HorseDetailsPanel from './core/HorseDetailsPanel';
import HorsePedigree from './core/HorsePedigree';
import HorseLogs from './core/HorseLogs';
import HelmetTracker from '../../core/HelmetTracker';

const cardTheme = 'dark';

export function HorseManagement() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(fetchHorse(id));
      dispatch(fetchHorseSalesViewsByDayForWeek(id));
    }
  }, [dispatch, id]);

  const horse = useSelector(
    (state) =>
      state.horses.find((m) => {
        return m.id === parseInt(id);
      }),
    shallowEqual
  );
  const horseViews = useSelector(
    (state) => state.reporting.singleHorseSalesViews
  );

  function getPhotosCount() {
    if (horse && horse.photos) return horse.photos.length;
    else return 0;
  }

  function getDocumentsCount() {
    if (horse && horse.documents) return horse.documents.length;
    else return 0;
  }

  function getLogsCount() {
    if (horse && horse.horseLogs) return horse.horseLogs.length;
    else return 0;
  }

  function getVideosCount() {
    if (horse && horse.videos) return horse.videos.length;
    else return 0;
  }

  function getPhotosTabTitle() {
    return (
      <>
        Photos &nbsp;
        <Badge bg="danger">{getPhotosCount()}</Badge>
      </>
    );
  }

  function getVideosTabTitle() {
    return (
      <>
        Videos &nbsp;
        <Badge bg="success">{getVideosCount()}</Badge>
      </>
    );
  }

  function getDocumentsTabTitle() {
    return (
      <>
        Documents &nbsp;
        <Badge bg="info">{getDocumentsCount()}</Badge>
      </>
    );
  }

  function getLogsTabTitle() {
    return (
      <>
        Logs &nbsp;
        <Badge bg="warning">{getLogsCount()}</Badge>
      </>
    );
  }

  function renderHeaderButton() {
    let horseId = horse ? horse.id : 0;
    return (
      <Stack direction="row" spacing={2}>
        <Button
          variant="info"
          onClick={() => {
            navigate('/horses');
          }}
        >
          Back to Horses
        </Button>
        <Button
          variant="dark"
          onClick={() => {
            // Goes back, but dont want to mislabel and say barns and be horses
            // navigate(-1);
            navigate('/barns');
          }}
        >
          Return to Barns
        </Button>
        {horse && horse.authorized && horse.isSaleHorse ? (
          <Button
            variant="warning"
            onClick={() => {
              // Goes back, but dont want to mislabel and say barns and be horses
              // navigate(-1);
              navigate('/sales/horse/' + horseId);
            }}
          >
            Preview Sale
          </Button>
        ) : (
          ''
        )}
      </Stack>
    );
  }

  return (
    <Container fluid>
      <HelmetTracker title="Horse Management" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant="danger">
            <h3>
              <Easel /> &nbsp; &nbsp;Horse Management Console
            </h3>
            <h4>
              {horse ? horse.name : ''}&nbsp; -&nbsp; (Id:
              {horse ? horse.id : ''})
            </h4>
            {renderHeaderButton()}
          </Alert>
        </Col>
      </Row>
      <Row className="show-grid">
        <Card border="danger">
          <Card.Body>
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="details" title="Details">
                <HorseDetailsPanel horse={horse} horseViews={horseViews} />
              </Tab>
              <Tab eventKey="photos" title={getPhotosTabTitle()}>
                <HorsePhotoManagementPanel horse={horse} />
              </Tab>
              <Tab eventKey="videos" title={getVideosTabTitle()}>
                <HorseVideoManagementPanel horse={horse} />
              </Tab>
              <Tab eventKey="documents" title={getDocumentsTabTitle()}>
                <HorseDocumentManagementPanel horse={horse} />
              </Tab>
              <Tab eventKey="logs" title={getLogsTabTitle()}>
                <HorseLogs horse={horse} />
              </Tab>
              {/*  {feedForm}*/}
            </Tabs>
          </Card.Body>
        </Card>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header className="nav-item-text">
              <Card.Title componentclass="h2">Pedigree</Card.Title>
            </Card.Header>
            <Card.Body>
              <HorsePedigree horse={horse} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HorseManagement;
