/* AUTO GEN */
/* peopleReducer.js */
import { SET_PEOPLE, PERSON_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

const peopleReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PERSON_FETCHED:
      var newArray = state.filter((item) => item.id !== action.person.id);
      newArray.push(action.person);
      return newArray;
    case SET_PEOPLE:
      return action.people;
    default:
      return state;
  }
};

export default peopleReducer;
