import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { connect } from 'react-redux';
import { loginUser } from '../../redux/actions';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const initialValues = {
  email: '',
  password: '',
};

/**
 * NOTE: Swapped to a class Component since it can use async.
 * The Tutorial says the point of formik is to keep things functional,
 * same as the reason to use hooks.  But this seems a future thing for me, as
 * the benefits are not clear when I can use a Component and just be done.
 * Seems adding a TON of complexity and new untested (by me) knowledge and learning
 * when I need to see the project out the door asap.
 */

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required!'),
  password: Yup.string().min(5, 'Required!').required('Required!'),
  // confirmPassword: Yup.string()
  //   .required("PleaseConfirm your password")
  //   .oneOf([Yup.ref("password")], "Passwords do not match"),
});

function LoginFormik({ source, sourceId }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onSubmit(values) {
    dispatch(
      loginUser(
        {
          username: values.email,
          password: values.password,
          source,
          sourceId,
        },
        navigate
      )
    );
  }

  // render() {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        onSubmit(values);
      }}
    >
      <Form>
        <div>
          <label htmlFor="email">E-mail</label>
          <Field
            type="email"
            id="email"
            name="email"
          />
          <ErrorMessage name="email" />
        </div>

        <div >
          <label htmlFor="password">Password</label>
          <Field
            type="password"
            id="password"
            name="password"
          />
          <ErrorMessage name="password" />
        </div>

        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
    </Formik>
  );
  // }
}

export default LoginFormik; // connect(null, { loginUser })(LoginFormik);
