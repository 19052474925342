/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Select } from 'mui-rff';
import { TextField } from 'mui-rff';

import { Box, Stack, OutlinedInput } from '@mui/material';

function convertUTCToLocalDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return date;
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date;
  }
  date = new Date(date);
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  return date;
}

// export default function UTCDatePicker({
//   startDate,
//   endDate,
//   selected,
//   onChange,
//   ...props
// }) {
//   return (
//     <DatePicker
//       startDate={convertUTCToLocalDate(startDate)}
//       endDate={convertUTCToLocalDate(endDate)}
//       selected={convertUTCToLocalDate(selected)}
//       onChange={date => onChange(convertLocalToUTCDate(date))}
//       {...props}
//     />
//   )
// }

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }) => (
  <DatePicker
    selected={convertUTCToLocalDate(value)}
    onChange={(date) => onChange(convertLocalToUTCDate(date))}
    {...rest}
  />
);

export function okCancelButtons(route) {
  return (
    <Stack direction="row" spacing={5} alignItems="center">
      <LinkContainer to={route}>
        <Button variant="warning">Cancel</Button>
      </LinkContainer>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </Stack>
  );
}

export function okCancel(onCancelClick, customSave) {
  return (
    <Stack direction="row" spacing={5} alignItems="center">
      <Button variant="warning" onClick={onCancelClick}>
        Cancel
      </Button>
      <Button type="submit" variant="primary">
        { customSave ? customSave : 'Save' }
      </Button>
    </Stack>
  );
}

export function okButton() {
  return (
    <Stack direction="row" spacing={5} alignItems="center">
      <Button type="submit" variant="primary">
        Save
      </Button>
    </Stack>
  );
}

export function okButtonText(text) {
  return (
    <ButtonToolbar>
      <Button type="submit" variant="primary">
        {text}
      </Button>
    </ButtonToolbar>
  );
}

export function renderFormTitle(object, title) {
  if (object && object.id > 0) {
    return (
      <div>
        <h4>Edit {title}</h4>
      </div>
    );
  } else {
    return (
      <div>
        <h4>Add {title}</h4>
      </div>
    );
  }
}

export const required = (value) => (value ? undefined : 'Required');
export const mustBeNumber = (value) =>
  isNaN(value) ? 'Must be a number' : undefined;

export const minValue = (min) => (value) =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

export const maxValue = (max) => (value) =>
  isNaN(value) || value <= max ? undefined : `Should be greater than ${max}`;

export const maxLength = (max) => (value) =>
  value.length <= max ? undefined : `Should be shorter than ${max} characters`;

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export function finalDateField(name, label, validation) {
  return (
    <Box width="100%">
      <label>{label}</label>
      <Field
        name={name}
        validate={validation}
        dateFormat="yyyy/MM/dd"
        component={DatePickerAdapter}
      ></Field>
    </Box>
  );
}

export function finalTextField(name, label, validation) {
  return (
    <Box width="100%">
      <Field
        name={name}
        validate={validation}
        allowNull
        parse={(value) => (value === null ? '' : value)}
        width="100%"
        display="flex"
      >
        {({ input, meta }) => (
          <div width="100%" display="flex">
            <label>{label}</label>
            <OutlinedInput
              {...input}
              type="text"
              placeholder={label}
              width="100%"
              variant="outlined"
              fullWidth
            />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
    </Box>
  );
}

export function finalPasswordField(name, label, validation) {
  return (
    <Box>
      <Field
        name={name}
        validate={validation}
        allowNull
        parse={(value) => (value === null ? '' : value)}
      >
        {({ input, meta }) => (
          <div>
            <label>{label}</label>
            <input {...input} type="password" placeholder={label} />
            {meta.error && meta.touched && <span>{meta.error}</span>}
          </div>
        )}
      </Field>
    </Box>
  );
}

// export function finalTextArea(name, label, validation) {
//   return (
//     <Box>
//       <label>{label}</label>
//       <TextField 
//         name={name} label={label} />
//       <Field
//         name={name}
//         component="textarea"
//         validate={validation}
//         parse={(value) => (value === null ? '' : value)}
//         style={{ width: '100%', backgroundColor: '#1b2635' }} // Hard coded color to mui dark
//       />
//     </Box>
//   );
// }

export function finalTextArea(name, label, validation) {
  return (
    <Box width="100%">
      <label>{label}</label>
      <Field
        name={name}
        component="textarea"
        validate={validation}
        parse={(value) => (value === null ? '' : value)}
        style={{ width: '100%', backgroundColor: '#1b2635' }} // Hard coded color to mui dark
      />
    </Box>
  );
}

export function finalBoolField(name, label) {
  return (
    <Box>
      <label>
        <Field name={name} component="input" type="checkbox" />
        &nbsp;&nbsp;&nbsp;
        {label}
      </label>
    </Box>
  );
}

const dataStatusOptionsList = [
  { key: 'Ok', value: '0' },
  { key: 'New', value: '1' },
  { key: 'Unknown', value: '2' },
  { key: 'Deleted', value: '3' },
  { key: 'Unknown', value: '4' },
  { key: 'Archived', value: '5' },
];

function createOptionsListFromNameId(idArray) {
  var zeroExists = false;
  if (idArray) {
    let array = _.map(idArray, (idobj) => {
      if (parseInt(idobj.id) === 0) {
        zeroExists = true;
      }
      return {
        label: idobj.name,
        id: idobj.id,
      };
    });
    if (!zeroExists) {
      array.splice(0, 0, { label: 'None', id: 0 });
    }
    return array;
  }
  return null;
}

// export function finalObjSelector(name, label, optionObjects) {
//   if (optionObjects && optionObjects.length > 0) {
//     return (
//       <Box>
//         <label>{label}</label>
//         <Field name={name} component="select">
//           {v}
//         </Field>
//       </Box>
//     );
//   } else {
//     return '';
//   }
// }

export function finalDataStatusSelector() {
  return finalObjSelector('dataStatus', 'Data Status', dataStatusOptionsList);
}

export function finalObjSelector(name, label, optionObjects) {
  if (optionObjects && optionObjects.length > 0) {
    const data = optionObjects.map((option) => {
      return { label: option.key, value: option.value };
    });
    return (
      <Box width="100%">
        <Select
          name={name}
          label={label}
          formControlProps={{ margin: 'normal' }}
          data={data}
        />
      </Box>
    );
  } else {
    return '';
  }
}

export function photoUploader() {
  return (
    <Box>
      <label>Photo</label>
      <input id="photos" type="file" name="photos" multiple />
    </Box>
  );
}

export function fileUploader() {
  return (
    <Box>
      <label>File</label>
      <input id="fileUpload" type="file" name="fileUpload" multiple />
      {/*<Form.Control id="file" type="file" name="file" multiple />*/}
    </Box>
  );
}

export function createOptionsList(idArray) {
  var zeroExists = false;
  if (idArray) {
    let array = _.map(idArray, (idobj) => {
      if (parseInt(idobj.id) === 0) {
        zeroExists = true;
      }
      return {
        value: idobj.id,
        key: idobj.name,
      };
    });
    if (!zeroExists) {
      array.splice(0, 0, { value: 0, key: 'None' });
    }
    return array;
  }
  return null;
}

export function createOptionsListNoZero(idArray) {
  var zeroExists = false;
  if (idArray) {
    let array = _.map(idArray, (idobj) => {
      if (parseInt(idobj.id) === 0) {
        zeroExists = true;
      }
      return {
        value: idobj.id,
        key: idobj.name,
      };
    });
    return array;
  }
  return null;
}
