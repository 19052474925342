import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import HorseStatsDisplay from '../../components/customers/horses/core/HorseStatsDisplay';
import { Shop } from 'react-bootstrap-icons';
import { Alert, InputGroup } from 'react-bootstrap';
import { Button, Checkbox, Grid, Stack } from '@mui/material';
import { horsesToClassArray } from '../../redux/classes/Horse';
import HorsesTable from '../users/core/HorsesTable';
import HelmetTracker from '../core/HelmetTracker';

export default function SalesHorseList({ auth }) {
  const saleshorses = useSelector((state) => state.saleshorses);
  // const auth = useSelector((state) => state.auth);

  const [priceOrder, setPriceOrder] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [sortFavorites, setSortFavorites] = useState(false);
  const [viewFavorites, setViewFavorites] = useState(false);

  const favoriteHorses = useSelector((state) =>
    state.saleshorses.filter((x) => auth && auth.isFavorite(x.id))
  );

  function handleFilterTextChange(e) {
    if (e.target) setFilterText(e.target.value);
  }

  function handleFavoritesCheckClick() {
    setSortFavorites(!sortFavorites);
  }

  function filterHorsesByPedigree() {
    // Gets hard copy and mutable version of list.
    var horseList = horsesToClassArray(JSON.parse(JSON.stringify(saleshorses)));
    if (filterText.length > 0) {
      let filter = filterText;
      let filteredTextHorses = _.filter(horseList, function (horse) {
        if (horse.pedigree) {
          var searchStr = JSON.stringify(horse.pedigree)
            .replace(/([^\w ]|_)/g, '')
            .toLowerCase();
          var filterstr = filter.replace(/([^\w ]|_)/g, '').toLowerCase();
          return searchStr.includes(filterstr);
        }
      });
      return filteredTextHorses;
    } else return horseList;
  }

  function getFavoriteInfoForHorse(horse) {
    return _.find(auth.favorites, function (o) {
      return o.horseId === horse.id;
    });
  }

  function favorited(horse) {
    if (auth) {
      if (auth.favorites) {
        if (getFavoriteInfoForHorse(horse)) {
          return true;
        }
      }
    }
    return false;
  }

  function orderListByFavorites(listToOrder) {
    if (auth) {
      _.forEach(listToOrder, (horse) => {
        if (favorited(horse)) {
          horse.favorited = 1;
        } else {
          horse.favorited = 0;
        }
      });
    }
    var returnList = _.orderBy(listToOrder, ['favorited'], ['desc']);
    return returnList;
  }

  function setFavoritesOnly(listToOrder) {
    if (auth) {
      _.forEach(listToOrder, (horse) => {
        if (favorited(horse)) {
          horse.favorited = 1;
          horse.favoriteInfo = getFavoriteInfoForHorse(horse);
        } else {
          horse.favorited = 0;
        }
      });
    }
    return listToOrder;
  }

  function renderHorses(order) {
    var horseList = filterHorsesByPedigree();
    var sortArray = [];
    var ascArray = [];
    var descArray = [];
    if (sortFavorites) {
      horseList = orderListByFavorites(horseList);
      sortArray = ['favorited', 'price'];
      ascArray = ['desc', 'asc'];
      descArray = ['desc', 'desc'];
    } else {
      horseList = setFavoritesOnly(horseList);
      sortArray = ['price'];
      ascArray = ['asc'];
      descArray = ['desc'];
    }
    if (order === 0) {
      return _.values(horseList).map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
    if (order === 1) {
      return _.orderBy(horseList, sortArray, ascArray).map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
    if (order === 2) {
      var orderedArray = _.orderBy(horseList, sortArray, descArray);
      return orderedArray.map((horse) => {
        return (
          <Grid item xs={12} sm={12} md={6} lg={6} key={horse.id}>
            <HorseStatsDisplay horse={horse} auth={auth} />
          </Grid>
        );
      });
    }
  }

  function changePriceSort() {
    var price = priceOrder;
    price = price + 1;
    if (price > 2) {
      price = 0;
    }
    setPriceOrder(price);
  }

  function priceOrderToText() {
    switch (priceOrder) {
      case 0:
        return 'No sort';
      case 1:
        return 'Ascend';
      case 2:
        return 'Descend';
      default:
        return 'Unk';
    }
  }

  function viewFavoritesGridClick() {
    setViewFavorites(!viewFavorites);
  }

  const userId = auth ? auth.id : null;

  return (
    <>
      <HelmetTracker title={'Sales'} userId={userId} />
      <Alert variant="primary">
        <h2>
          <Shop />
          &nbsp;&nbsp; Sales
        </h2>
      </Alert>
      <InputGroup>
        <Stack spacing={2}>
          {!viewFavorites ? (
            <label>
              Pedigree Includes:&nbsp;&nbsp;&nbsp;
              <input
                type="text"
                value={filterText}
                onChange={handleFilterTextChange}
              />{' '}
            </label>
          ) : null}
          {!viewFavorites ? (
            <label>
              Price Sort:&nbsp;&nbsp;&nbsp;
              <Button variant="contained" onClick={changePriceSort}>
                {priceOrderToText()}
              </Button>
            </label>
          ) : null}
          {auth ? (
            <label>
              View Favorites Grid:&nbsp;&nbsp;&nbsp;
              <Button variant="contained" onClick={viewFavoritesGridClick}>
                {!viewFavorites ? 'View Favorites Grid' : 'View All Horses'}
              </Button>
            </label>
          ) : null}
          {auth && !viewFavorites ? (
            <label>
              Sort Favorites:&nbsp;&nbsp;&nbsp;
              <Checkbox
                checked={sortFavorites}
                onClick={handleFavoritesCheckClick}
              />
            </label>
          ) : null}
        </Stack>
      </InputGroup>
      <Grid container rowSpacing={6}>
        {!viewFavorites ? renderHorses(priceOrder) : null}
        {viewFavorites ? (
          <HorsesTable horses={favoriteHorses} sale={true} auth={auth} key={0} />
        ) : null}
      </Grid>
    </>
  );
}
