import React from 'react';
import DatePicker from 'react-datepicker';
import { Button, ButtonToolbar, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';

export function okCancelChangesButtons(route) {
  return (
    <ButtonToolbar>
      <LinkContainer to={route}>
        <Button variant="warning">Cancel Changes</Button>
      </LinkContainer>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function okCancelButtons() {
  return (
    <ButtonToolbar>
      <LinkContainer>
        <Button variant="warning">Cancel</Button>
      </LinkContainer>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function okCancelLinkButtons(route) {
  return (
    <ButtonToolbar>
      <LinkContainer to={route}>
        <Button variant="warning">Cancel</Button>
      </LinkContainer>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function authorizedOkCancelChangesButtons(auth, route) {
  if (auth) {
    return (
      <ButtonToolbar>
        <LinkContainer to={route}>
          <Button variant="warning">Cancel Changes</Button>
        </LinkContainer>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </ButtonToolbar>
    );
  } else {
    return (
      <ButtonToolbar>
        <LinkContainer to={route}>
          <Button variant="warning">Return</Button>
        </LinkContainer>
      </ButtonToolbar>
    );
  }
}

export function okButton() {
  return (
    <ButtonToolbar>
      <Button type="submit" variant="primary">
        Save
      </Button>
    </ButtonToolbar>
  );
}

export function okButtonText(text) {
  return (
    <ButtonToolbar>
      <Button type="submit" variant="primary">
        {text}
      </Button>
    </ButtonToolbar>
  );
}

export function textField(name, label, context) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        value={context.state[name]}
        onChange={context.handleChange}
      />
    </Form.Group>
  );
}

export function numericField(name, label, context) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="text"
        value={context.state[name]}
        onChange={context.handleChange}
      />
    </Form.Group>
  );
}

export function errorField(errorText) {
  if (errorText) {
    return errorText;
  }
}

export function passwordField(name, label, context) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type="password"
        value={context.state[name]}
        onChange={context.handleChange}
      />
    </Form.Group>
  );
}

export function textArea(name, label, context) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <br />
      <Form.Control
        componentClass="textarea"
        value={context.state[name]}
        onChange={context.handleChange}
        id={name}
        rows={3}
      />
    </Form.Group>
  );
}

export function boolField(name, label, context) {
  return (
    <div>
      {/* <Checkbox
        id={name}
        checked={context.state[name]}
        onChange={context.handleBoolChanged}
      >
        {label}
      </Checkbox> */}
    </div>
  );
}

export function dateField(name, label, changeHandler, context) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <DatePicker selected={context.state[name]} onChange={changeHandler} />
    </Form.Group>
  );
}

export function intSelector(name, label, context, options) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        placeholder="select"
        onChange={context.handleSelectChange}
        value={context.state[name]}
        name={name}
      >
        {_.map(options, (option, index) => {
          return (
            <option key={index} value={index}>
              {option}
            </option>
          );
        })}
      </Form.Select>
    </Form.Group>
  );
}

export function stringSelector(name, label, context, options) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        componentClass="select"
        placeholder="select"
        onChange={context.handleSelectChange}
        value={context.state[name]}
        name={name}
      >
        {_.map(options, (option, index) => {
          return (
            <option key={index} value={option}>
              {option}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
}

export function objSelector(name, label, context, optionObjects) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        componentClass="select"
        placeholder="select"
        onChange={context.handleSelectChange}
        value={context.state[name] || ''}
      >
        <option key={'db'} value="">
          None
        </option>
        {_.map(optionObjects, (optionObject) => {
          return (
            <option key={optionObject.index} value={optionObject.index}>
              {optionObject.label}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
}

export function objSelectorNoNone(name, label, context, optionObjects) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        componentClass="select"
        placeholder="select"
        onChange={context.handleSelectChange}
        value={context.state[name]}
      >
        {_.map(optionObjects, (optionObject) => {
          return (
            <option key={optionObject.index} value={optionObject.index}>
              {optionObject.label}
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
}

export function photoUploader() {
  return (
    <Form.Group>
      <Form.Label>Photos</Form.Label>
      <Form.Control id="photos" type="file" name="photos" multiple />
    </Form.Group>
  );
}

export function fileUploader() {
  return (
    <Form.Group>
      <Form.Label>File</Form.Label>
      <Form.Control id="file" type="file" name="file" multiple />
    </Form.Group>
  );
}
