import {
  ENTRIES_FETCHED,
  ENTRY_CREATED,
  ENTRY_UPDATED,
} from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ENTRIES_FETCHED:
      return [...action.entries];
    case ENTRY_CREATED:
      return [...action.entrys, action.entry];
    case ENTRY_UPDATED:
      var newState = state.map((item) => {
        if (item.id === action.entry.id) {
          return action.entry;
        } else return item;
      });
      return [...newState];
    default:
      return state;
  }
}
