import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Image } from 'react-bootstrap';
import ModalImage from 'react-modal-image';

import { Chip as MuiChip, Stack } from '@mui/material';
import styled from '@emotion/styled';

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

export default function HorseTableRow({ horse }) {
  function clickLink() {
    if (horse) {
      return '/horses/manage/' + horse.id;
    }
    return '/horses';
  }

  function pedigreeEmptyOrPartial(horse) {
    return JSON.stringify(horse.pedigree).toLowerCase().includes('unknown');
  }

  function getPedigreeBadge(horse) {
    if (horse && horse.pedigree && !pedigreeEmptyOrPartial(horse)) {
      return <Chip label="Pedigree" color="info" />;
    }
    return '';
  }

  function getPhotoLabel(horse) {
    if (horse && horse.photos) {
      return horse.photos.length + ' Pictures';
    } else {
      return '';
    }
  }

  function getVideosLabel(horse) {
    if (horse && horse.videos) {
      return horse.videos.length + ' Videos';
    } else {
      return '';
    }
  }

  function getDocumentsLabel(horse) {
    if (horse && horse.documents) {
      return horse.documents.length + ' Documents';
    } else {
      return '';
    }
  }

  function getPhotosVideosBadges(horse) {
    if (horse) {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {horse.photos ? (
            <Chip label={getPhotoLabel(horse)} color="error" />
          ) : (
            ''
          )}
          {horse.videos ? (
            <Chip label={getVideosLabel(horse)} color="warning" />
          ) : (
            ''
          )}
          {horse.documents ? (
            <Chip label={getDocumentsLabel(horse)} color="info" />
          ) : (
            ''
          )}
        </Stack>
      );
    }
    return '';
  }

  function genderToChip(type) {
    if (type === 0) {
      return <Chip label="Unassigned" color="warning" />;
    } else if (type === 1) {
      return <Chip label="Gelding" color="info" />;
    } else if (type === 2) {
      return <Chip label="Mare" color="error" />;
    } else if (type === 3) {
      return <Chip label="Stallion" color="success" />;
    } else {
      return '';
    }
  }

  function getGeneralInfo() {
    return (
      <div>
        <div>{genderToChip(horse.gender)}</div>
        <div>{horse.breed}</div>
        <div>{horse.color}</div>
      </div>
    );
  }

  function getBarnInfo() {
    return (
      <div>
        {horse.barn.name}
        <div>({horse.barn.barnOwnerName})</div>
        <div>
          {horse.photo ? <Chip label="Upgraded" color="success" /> : ''}
        </div>
      </div>
    );
  }

  function getIsSale() {
    if (horse.isSaleHorse) {
      return <Chip label="Yes" color="success" />;
    } else {
      return <Chip label="No" color="error" />;
    }
  }

  function isArchived() {
    // Effectively dataStatus > 0 === ARCHIVED
    return horse.dataStatus;

    // Had issue here.  IF dataStatus == 0, is ACTIVE.
    // 0 ALSO means FALSE.  So Active is NOT Archived,
    // so just return the 0.
  }

  function getId() {
    if (!isArchived()) {
      return <Chip label={horse.id} color="success" />;
    } else {
      return <Chip label={horse.id} color="error" />;
    }
  }

  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return (
        <ModalImage
          small={photoURL}
          medium={photoURL}
          large={photoURL}
          alt="Hello World!"
          key={photoURL}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getHorsePhoto() {
    if (horse && horse.hasPhoto()) {
      return (
        <div>
          <div>{renderImgTag(horse.getMainPhotoURLThumb())}</div>
        </div>
      );
    }
    return (
      <div>
        <div>
          <Image
            src="/images/horses/horse_avatar_thumb.jpg"
            alt="Avatar"
            rounded
          />
        </div>
      </div>
    );
  }

  return (
    <LinkContainer to={clickLink()}>
      <tr>
        <td>
          <h5>{getHorsePhoto()}</h5>
        </td>
        <td>
          <h5>{getId()}</h5>
        </td>
        <td>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              {getIsSale()}
              {getPedigreeBadge(horse)}
            </Stack>
        </td>
        <td>
          <Stack>
            <h5>{horse.name}</h5>
            {getPhotosVideosBadges(horse)}
          </Stack>
        </td>
        <td>
          <h6>{getGeneralInfo()}</h6>
        </td>
        <td>
          <h6>{getBarnInfo()}</h6>
        </td>
      </tr>
    </LinkContainer>
  );
}

HorseTableRow.propTypes = {
  horse: PropTypes.object.isRequired,
};
