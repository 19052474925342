import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { CarFront } from 'react-bootstrap-icons';
import HelmetTracker from '../core/HelmetTracker';

const cardTheme = 'danger';
const buttonTheme = 'info';

export default function Directions() {
  return (
    <>
    <HelmetTracker title="Directions" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant={cardTheme}>
            <ListGroup>
              <ListGroupItem>
                <h3>
                  <CarFront />
                  &nbsp;&nbsp;Directions
                </h3>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Facility Maps</Card.Title>
            </Card.Header>
            <Card.Body>
              <Button glyph="globe" href="/maps" variant={buttonTheme}>
                Click to see facility Maps
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Address</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup className="pb-3">
                <ListGroupItem>3828 Wagener Rd</ListGroupItem>
                <ListGroupItem>Aiken, SC 29805</ListGroupItem>
                <ListGroupItem>
                  The ONLY GPS that has accurate maps near us is GOOGLE MAPS!
                </ListGroupItem>
              </ListGroup>
              <Button
                variant={buttonTheme}
                href="https://www.google.com/maps/place/Full+Gallop+Farm+-+Barn+A,+3828+Wagener+Rd,+Aiken,+SC+29805/@33.5722481,-81.5841106,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8546a0eccd06d:0x40b37c872084e0d8!8m2!3d33.5722573!4d-81.5819417"
              >
                Google GPS
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>From West (through Augusta, GA)</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  Follow Interstate 20 to South Carolina exit 18
                </ListGroupItem>
                <ListGroupItem>
                  At the end of the ramp turn Right on Hwy 19
                </ListGroupItem>
                <ListGroupItem>
                  At the second light turn Left on Hwy 118 (Rutland Dr.)
                </ListGroupItem>
                <ListGroupItem>
                  Follow Hwy 118 to Hwy 302 (Wagener Rd.) and turn Left
                </ListGroupItem>
                <ListGroupItem>
                  Full Gallop Farm is approximately 6 miles on the right
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>From East or North (through Columbia, SC)</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  Follow Interstate 20 to South Carolina exit 22
                </ListGroupItem>
                <ListGroupItem>
                  At the end of the ramp turn Left on Hwy 1 (York St.)
                </ListGroupItem>
                <ListGroupItem>
                  Follow Hwy 1 (York St.) to Hwy 118 (Rutland Dr.) and turn Left
                </ListGroupItem>
                <ListGroupItem>
                  Follow Hwy 118 to Hwy 302 (Wagener Rd.) and turn Left
                </ListGroupItem>
                <ListGroupItem>
                  Full Gallop Farm is approximately 6 miles on the right
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>From South</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  Follow I-95 to US-78 W via EXIT 77 toward BRANCHVILLE/BAMBERG.
                  Go 13.1 miles
                </ListGroupItem>
                <ListGroupItem>
                  Turn LEFT onto US-21/FREEDOM RD. Go 0.2 miles
                </ListGroupItem>
                <ListGroupItem>
                  Turn RIGHT onto US-78/W EDWARDS ST. Continue to follow US-78.
                  Go 51.8 miles
                </ListGroupItem>
                <ListGroupItem>
                  Turn RIGHT onto HATCHAWAY BRIDGE RD. Go 4.2 miles
                </ListGroupItem>
                <ListGroupItem>
                  Turn LEFT onto SC-302/SC-4. Go 0.8 miles
                </ListGroupItem>
                <ListGroupItem>
                  End at 3828 WAGENER RD AIKEN SC. We are on the Left
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
