/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import EventDocumentCard from './EventDocumentCard';
import { Table } from 'react-bootstrap';

export default function EventDocumentGroup({ eventSelected }) {
  function renderEventDocuments() {
    if (eventSelected && eventSelected.docs) {
      return _.values(eventSelected.docs).map((eventDocument) => {
        return (
          <tr key={eventDocument.id}>
            <EventDocumentCard
              eventDocument={eventDocument}
              onDelete={null}
              onMakeMainDocument={null}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Table striped bordered hover>
      <tbody>{renderEventDocuments()}</tbody>
    </Table>
  );
}
