import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createPageview } from '../../redux/actions/oculus/pageviewActions';
import { v4 as uuidv4 } from 'uuid';
import { titleForURL } from '../PageList';
import { Helmet } from 'react-helmet-async';

const HelmetTracker = ({ userId, title, url, objId }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const page = title ? title : titleForURL(url);

  function getSession() {
    var session = window.sessionStorage.getItem('SID');
    if (session) {
      return session;
    }
    let uuid = uuidv4();
    window.sessionStorage.setItem('SID', uuid);
    return uuid;
  }

  useEffect(() => {
    let session = getSession();
    dispatch(
      createPageview({
        userId: userId,
        type: 1,
        session: session ? session : null,
        page: page,
        url: location.pathname,
        objectId: objId && Number.isInteger(objId) ? objId : null, // undefined was getting passed in as a string
      })
    );
  }, [userId, location.pathname, dispatch, page, objId]);

  return <Helmet title={title} />;
};

export default HelmetTracker;
