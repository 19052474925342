/* SalesTotals.js */
export class SalesTotals {
  constructor(
    rollingAllSalesWeek,
    rollingAllSalesDay,
    rollingIndividualSalesWeek,
    rollingIndividualSalesDay
  ) {
    this.rollingAllSalesWeek = rollingAllSalesWeek;
    this.rollingAllSalesDay = rollingAllSalesDay;
    this.rollingIndividualSalesWeek = rollingIndividualSalesWeek;
    this.rollingIndividualSalesDay = rollingIndividualSalesDay;
  }
}

export function salesTotalsToClass(salesTotals) {
  if (salesTotals) {
    return new SalesTotals(
      salesTotals.rollingAllSalesWeek,
      salesTotals.rollingAllSalesDay,
      salesTotals.rollingIndividualSalesWeek,
      salesTotals.rollingIndividualSalesDay
    );
  } else {
    return null;
  }
}

export function blankSalesTotals() {
    return new SalesTotals(0,0,0,0);
}
