import React from 'react';
import EventPeople from '../core/EventPeople';
import HelmetTracker from '../../core/HelmetTracker';

// FAIRLY USELESS FOR NOW
// Adding in so we can add additional functionality at a later time,
// such as viewing records and other sub data.  Or Entries.

export function UserPeoplePage() {
  return (
    <>
      <HelmetTracker title="My People" />
      <EventPeople />
    </>
  );
}

export default UserPeoplePage;
