
// export default function (state = {}, action) {
export default function reduce(state = [], action) {
  switch (action.type) {
    case 'BLOCKS_FETCHED':
      // const newBlocks = _.mapKeys(action.blocks, 'id');
      // console.log(action.blocks);
      return [...action.blocks];
    // const newBlocks = _.mapKeys(action.blocks, 'id');
    // return { ...newBlocks };
    // return { ...action.blocks };
    case 'BLOCK_FETCHED':
      return { ...state, [action.block.id]: action.block };

    default:
      return state;
  }
}

/*
EVENT_TESTS 

name
type (dressage, sj, xc, other)
level (can be null for like an open show)
testData (JSON, literally anything) DEFAULT Null
schedulingParameters (JSON) Minutes duration


*/
