import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancelButtons,
  renderFormTitle,
  finalTextField,
  finalTextArea,
  finalDataStatusSelector,
  finalObjSelector,
  finalBoolField,
  required,
  mustBeNumber,
  maxLength,
  createOptionsListNoZero,
} from '../../../core/FinalCoreForms';
import { Box, Stack } from '@mui/material';
import { Table } from 'react-bootstrap';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function HorseSubForm(props) {
  const initialValues = {
    id: props.horse && props.horse.id ? props.horse.id : null,
    barnId: props.horse && props.horse.barnId ? props.horse.barnId : 0,
    name: props.horse && props.horse.name ? props.horse.name : '',
    price: props.horse && props.horse.price ? props.horse.price : 0,
    owner: props.horse && props.horse.owner ? props.horse.owner : '',
    breed: props.horse && props.horse.breed ? props.horse.breed : '',
    gender: props.horse && props.horse.gender ? props.horse.gender : 0,
    color: props.horse && props.horse.color ? props.horse.color : '',
    height: props.horse && props.horse.height ? props.horse.height : '',
    birthYear:
      props.horse && props.horse.birthYear ? props.horse.birthYear : '',
    description:
      props.horse && props.horse.description ? props.horse.description : '',
    levelCompeting:
      props.horse && props.horse.levelCompeting
        ? props.horse.levelCompeting
        : '',
    useaNumber:
      props.horse && props.horse.useaNumber ? props.horse.useaNumber : '',
    usefNumber:
      props.horse && props.horse.usefNumber ? props.horse.usefNumber : '',
    ushjaNumber:
      props.horse && props.horse.ushjaNumber ? props.horse.ushjaNumber : '',
    specialInformation:
      props.horse && props.horse.specialInformation
        ? props.horse.specialInformation
        : '',
    feed: props.horse && props.horse.feed ? props.horse.feed : '',
    sire:
      props.horse && props.horse.pedigree && props.horse.pedigree.sire
        ? props.horse.pedigree.sire.name
        : 'Unknown',
    dam:
      props.horse && props.horse.pedigree && props.horse.pedigree.dam
        ? props.horse.pedigree.dam.name
        : 'Unknown',
    siresire:
      props.horse && props.horse.pedigree && props.horse.pedigree.siresire
        ? props.horse.pedigree.siresire.name
        : 'Unknown',
    siredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredam
        ? props.horse.pedigree.siredam.name
        : 'Unknown',
    damsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsire
        ? props.horse.pedigree.damsire.name
        : 'Unknown',
    damdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdam
        ? props.horse.pedigree.damdam.name
        : 'Unknown',
    siresiresire:
      props.horse && props.horse.pedigree && props.horse.pedigree.siresiresire
        ? props.horse.pedigree.siresiresire.name
        : 'Unknown',
    siresiredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siresiredam
        ? props.horse.pedigree.siresiredam.name
        : 'Unknown',
    siredamsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredamsire
        ? props.horse.pedigree.siredamsire.name
        : 'Unknown',
    siredamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredamdam
        ? props.horse.pedigree.siredamdam.name
        : 'Unknown',
    damsiresire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsiresire
        ? props.horse.pedigree.damsiresire.name
        : 'Unknown',
    damsiredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsiredam
        ? props.horse.pedigree.damsiredam.name
        : 'Unknown',
    damdamsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamsire
        ? props.horse.pedigree.damdamsire.name
        : 'Unknown',
    damdamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamdam
        ? props.horse.pedigree.damdamdam.name
        : 'Unknown',

    siresiresiresire:
      props.horse &&
      props.horse.pedigree &&
      props.horse.pedigree.siresiresiresire
        ? props.horse.pedigree.siresiresiresire.name
        : 'Unknown',
    siresiresiredam:
      props.horse &&
      props.horse.pedigree &&
      props.horse.pedigree.siresiresiredam
        ? props.horse.pedigree.siresiresiredam.name
        : 'Unknown',
    siresiredamsire:
      props.horse &&
      props.horse.pedigree &&
      props.horse.pedigree.siresiredamsire
        ? props.horse.pedigree.siresiredamsire.name
        : 'Unknown',
    siresiredamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siresiredamdam
        ? props.horse.pedigree.siresiredamdam.name
        : 'Unknown',
    siredamsiresire:
      props.horse &&
      props.horse.pedigree &&
      props.horse.pedigree.siredamsiresire
        ? props.horse.pedigree.siredamsiresire.name
        : 'Unknown',
    siredamsiredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredamsiredam
        ? props.horse.pedigree.siredamsiredam.name
        : 'Unknown',
    siredamdamsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredamdamsire
        ? props.horse.pedigree.siredamdamsire.name
        : 'Unknown',
    siredamdamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.siredamdamdam
        ? props.horse.pedigree.siredamdamdam.name
        : 'Unknown',
    damsiresiresire:
      props.horse &&
      props.horse.pedigree &&
      props.horse.pedigree.damsiresiresire
        ? props.horse.pedigree.damsiresiresire.name
        : 'Unknown',
    damsiresiredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsiresiredam
        ? props.horse.pedigree.damsiresiredam.name
        : 'Unknown',
    damsiredamsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsiredamsire
        ? props.horse.pedigree.damsiredamsire.name
        : 'Unknown',
    damsiredamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damsiredamdam
        ? props.horse.pedigree.damsiredamdam.name
        : 'Unknown',
    damdamsiresire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamsiresire
        ? props.horse.pedigree.damdamsiresire.name
        : 'Unknown',
    damdamsiredam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamsiredam
        ? props.horse.pedigree.damdamsiredam.name
        : 'Unknown',
    damdamdamsire:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamdamsire
        ? props.horse.pedigree.damdamdamsire.name
        : 'Unknown',
    damdamdamdam:
      props.horse && props.horse.pedigree && props.horse.pedigree.damdamdamdam
        ? props.horse.pedigree.damdamdamdam.name
        : 'Unknown',
    isSaleHorse:
      props.horse && props.horse.isSaleHorse
        ? props.horse.isSaleHorse > 0
        : false,
    sold: props.horse && props.horse.sold ? props.horse.sold > 0 : false,
    authorized:
      props.horse && props.horse.authorized
        ? props.horse.authorized > 0
        : false,
    international:
      props.horse && props.horse.international
        ? props.horse.international > 0
        : false,
    raced: props.horse && props.horse.raced ? props.horse.raced : 0,
    dataStatus:
      props.horse && props.horse.dataStatus ? props.horse.dataStatus : 0,
  };

  function createPedigreeObject(pedigree) {
    return {
      sire: { name: pedigree.sire },
      dam: { name: pedigree.dam },
      siresire: { name: pedigree.siresire },
      siredam: { name: pedigree.siredam },
      damsire: { name: pedigree.damsire },
      damdam: { name: pedigree.damdam },
      siresiresire: { name: pedigree.siresiresire },
      siresiredam: { name: pedigree.siresiredam },
      siredamsire: { name: pedigree.siredamsire },
      siredamdam: { name: pedigree.siredamdam },
      damsiresire: { name: pedigree.damsiresire },
      damsiredam: { name: pedigree.damsiredam },
      damdamsire: { name: pedigree.damdamsire },
      damdamdam: { name: pedigree.damdamdam },
      siresiresiresire: { name: pedigree.siresiresiresire },
      siresiresiredam: { name: pedigree.siresiresiredam },
      siresiredamsire: { name: pedigree.siresiredamsire },
      siresiredamdam: { name: pedigree.siresiredamdam },
      siredamsiresire: { name: pedigree.siredamsiresire },
      siredamsiredam: { name: pedigree.siredamsiredam },
      siredamdamsire: { name: pedigree.siredamdamsire },
      siredamdamdam: { name: pedigree.siredamdamdam },
      damsiresiresire: { name: pedigree.damsiresiresire },
      damsiresiredam: { name: pedigree.damsiresiredam },
      damsiredamsire: { name: pedigree.damsiredamsire },
      damsiredamdam: { name: pedigree.damsiredamdam },
      damdamsiresire: { name: pedigree.damdamsiresire },
      damdamsiredam: { name: pedigree.damdamsiredam },
      damdamdamsire: { name: pedigree.damdamdamsire },
      damdamdamdam: { name: pedigree.damdamdamdam },
    };
  }

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.horse && props.submitHorse) {
      Object.assign(props.horse, values);
      props.horse.pedigree = createPedigreeObject(values);
      props.submitHorse(props.horse);
    } else {
      let newData = {};
      Object.assign(newData, values);
      newData.pedigree = createPedigreeObject(values);
      props.submitHorse(newData);
    }
  };

  function navigateBackPath() {
    if (props.horse && props.horse.id)
      return '/horses/manage/' + props.horse.id;
    else return '/horses';
  }

  const genderOptionsList = [
    { key: 'Not Selected', value: '0' },
    { key: 'Gelding', value: '1' },
    { key: 'Mare', value: '2' },
    { key: 'Stallion', value: '3' },
  ];

  const racedOptionsList = [
    { key: 'N/A', value: '0' },
    { key: 'Raced', value: '1' },
    { key: 'Unraced', value: '2' },
  ];

  var barnOptions = createOptionsListNoZero(props.barns);

  return (
    <div>
      {renderFormTitle(props.horse, 'horses')}
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={10}>
              <Box>
                {okCancelButtons(navigateBackPath())}

                {props.barns && props.barns.length > 0
                  ? finalObjSelector('barnId', 'Barn', barnOptions)
                  : ''}
                {finalTextField('name', 'Name', required)}
                {finalTextField('price', 'Price', mustBeNumber)}
                {finalTextField('owner', 'Owner', maxLength(150))}
                {finalTextField('breed', 'Breed')}
                {finalObjSelector('gender', 'Gender', genderOptionsList)}
                {finalTextField('color', 'Color')}
                {finalTextField('height', 'Height')}
                {finalTextField('birthYear', 'Birth Year')}
                {finalTextArea('description', 'Description')}
                {finalTextField('levelCompeting', 'Level Competing')}
                {finalTextField('useaNumber', 'Usea Number')}
                {finalTextField('usefNumber', 'Usef Number')}
                {finalTextField('ushjaNumber', 'Ushja Number')}
                {finalTextArea('specialInformation', 'Special Information')}
                {finalTextArea('feed', 'Feed')}
                {finalBoolField('isSaleHorse', 'Is Sale Horse')}
                {finalBoolField('sold', 'Sold')}
                {finalBoolField('authorized', 'Authorized')}
                {finalBoolField('international', 'International')}
                {finalObjSelector('raced', 'Raced', racedOptionsList)}
                {finalDataStatusSelector()}
              </Box>
              <Box>
                <Table  striped bordered hover variant="dark">
                  <tbody>
                    <tr>
                      <td rowSpan="8" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('sire', 'sire')}
                      </td>
                      <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresire', 'siresire')}
                      </td>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiresire', 'siresiresire')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiresiresire', 'siresiresiresire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiresiredam', 'siresiresiredam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiredam', 'siresiredam')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiredamsire', 'siresiredamsire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siresiredamdam', 'siresiredamdam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredam', 'siredam')}
                      </td>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamsire', 'siredamsire')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamsiresire', 'siredamsiresire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamsiredam', 'siredamsiredam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamdam', 'siredamdam')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamdamsire', 'siredamdamsire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('siredamdamdam', 'siredamdamdam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="8" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('dam', 'dam')}
                      </td>
                      <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsire', 'damsire')}
                      </td>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiresire', 'damsiresire')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiresiresire', 'damsiresiresire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiresiredam', 'damsiresiredam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiredam', 'damsiredam')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiredamsire', 'damsiredamsire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damsiredamdam', 'damsiredamdam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdam', 'damdam')}
                      </td>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamsire', 'damdamsire')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamsiresire', 'damdamsiresire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamsiredam', 'damdamsiredam')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamdam', 'damdamdam')}
                      </td>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamdamsire', 'damdamdamsire')}
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                        {finalTextField('damdamdamdam', 'damdamdamdam')}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Box>
              {okCancelButtons('/horses')}
            </Stack>
            {/* <Row>{okCancelButtons('/horses')}</Row>
            <Row>
              <pre>{JSON.stringify(values, 0, 2)}</pre>
            </Row> */}
          </form>
        )}
      />
    </div>
  );
}
