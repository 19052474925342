import React from 'react';
import styled from '@emotion/styled';
import { User } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import CreateAccountButton from './CreateAccountButton';

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Stack,
} from '@mui/material';

// import useAuth from "../../hooks/useAuth";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 30px;
    height: 30px;
  }
`;

function NavbarUserDropdown({ auth }) {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  // const { signOut } = useAuth();

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleProfile = async () => {
    setAnchorMenu(null);
    navigate('/user/home');
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <User />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem>
          <h5>
            {auth ? 'Welcome, ' + auth.display() : 'Login or Create Account'}
          </h5>
        </MenuItem>
        {auth ? (
          <Stack width="100%">
            <hr />
            <MenuItem onClick={handleProfile}>
              <h5>Profile</h5>
            </MenuItem>
          </Stack>
        ) : (
          ''
        )}
        <MenuItem onClick={closeMenu}>
          {auth ? (
            <Stack width="100%">
              <hr />
              <LogoutButton />
            </Stack>
          ) : (
            <Stack width="100%">
              <hr />
              <CreateAccountButton />
              <hr />
              <LoginButton />
            </Stack>
          )}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
