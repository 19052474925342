import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit, PlusSquare } from 'react-feather';

import { useSelector, useDispatch } from 'react-redux';
import PermissionsForm from '../forms/PermissionsForm';
import { useNavigate } from 'react-router-dom';
import { updatePermission } from '../../../../redux/actions/admin/permissionActions';
import {
  Box,
  Button,
  Card as MuiCard,
  CardHeader,
  Collapse,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import PermissionPropertiesForm from '../forms/PermissionPropertiesForm';
import { PropertyItem } from '../core/PropertyItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function PermissionsTable({ permissions }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openPedigree, setOpenPedigree] = React.useState(false);
  const [addingProperty, setAddingProperty] = React.useState(false);

  function propertiesToUI(properties) {
    var i = 0;
    if (properties) {
      return (
        <>
          {properties.map(function (property) {
            return <PropertyItem property={property} key={i++} />;
          })}
        </>
      );
    }
  }

  //   const permissions = useSelector((state) => state.permissions);
  const selectedPermission = useSelector((state) =>
    state.permissions.find((x) => x.id === selected)
  );

  function getPhotoLabel(permission) {
    if (permission && permission.photos) {
      return permission.photos.length + ' Pictures';
    } else {
      return '';
    }
  }

  function getVideosLabel(permission) {
    if (permission && permission.videos) {
      return permission.videos.length + ' Videos';
    } else {
      return '';
    }
  }

  function getDocumentsLabel(permission) {
    if (permission && permission.documents) {
      return permission.documents.length + ' Documents';
    } else {
      return '';
    }
  }

  const handleAddPropertyClick = (event, id) => {
    setSelected(id);
    setAddingProperty(!addingProperty);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  function getPhotosVideosBadges(permission) {
    if (permission) {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {permission.photos ? (
            <Chip label={getPhotoLabel(permission)} color="error" />
          ) : (
            ''
          )}
          {permission.videos ? (
            <Chip label={getVideosLabel(permission)} color="warning" />
          ) : (
            ''
          )}
          {permission.documents ? (
            <Chip label={getDocumentsLabel(permission)} color="info" />
          ) : (
            ''
          )}
        </Stack>
      );
    }
    return '';
  }

  function renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return <Box component="img" src={photoURL} alt="Avatar" />;
    } else {
      return (
        <Box component="img" src="/images/photos/blank.png" alt="Avatar" />
      );
    }
  }

  function getPermissionPhoto(permission) {
    if (permission && permission.hasPhoto()) {
      return renderImgTag(permission.getMainPhotoURLThumb());
    }
    return (
      <div>
        <div>
          <Box
            component="img"
            src="/images/permissions/permission_avatar_thumb.jpg"
            alt="Avatar"
          />
        </div>
      </div>
    );
  }

  function getIsSale(permission) {
    if (permission.isSalePermission) {
      return <Chip label="Yes" color="success" />;
    } else {
      return <Chip label="No" color="error" />;
    }
  }

  function getIdChip(permission) {
    if (permission.isSalePermission) {
      return <Chip label={permission.id} color="success" />;
    } else {
      return <Chip label={permission.id} color="error" />;
    }
  }

  function getPermissionTypeChip(permission) {
    if (permission && permission.type === 0) {
      return <Chip label="Client" color="info" />;
    } else if (permission.type === 1) {
      return <Chip label="Server" color="warning" />;
    } else {
      return '';
    }
  }

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);

    // // Stops it from selecting Row and showing full edit form.
    // event.stopPropagation();

    // if (id) navigate('/permissions/manage/' + id);
  };

  const handleClick = (event, id) => {
    if (!addingProperty) {
      setSelected(id);
    }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setAddingProperty(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const handleShowPedigreeClick = (event, id) => {
    setOpenPedigree(!openPedigree);
    // event.stopPropagation();  /// commenting this sets id
  };

  function handleBarnUpdated(value, permission) {
    if (value) {
      permission.barnId = value.id;
      dispatch(updatePermission(permission));
    }
  }

  const isSelected = (id) => selected === id;

  function showPermissionForm() {
    if (selected && selected > 0) {
      if (selectedPermission && editing) {
        return (
          <PermissionsForm
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            permission={selectedPermission}
          />
        );
      }
    } else if (creating) {
      return (
        <PermissionsForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
        />
      );
    }
  }

  function addPropertyForm(isSelected) {
    if (isSelected) {
      if (addingProperty) {
        return (
          <PermissionPropertiesForm
            onOkClick={handleFormOkClick}
            permission={selectedPermission}
          />
        );
      }
    }
  }

  function mapBody(permissions) {
    if (permissions && permissions.length > 0) {
      const tableBody = permissions.map((permission) => {
        const isItemSelected = isSelected(permission.id);
        const properties = permission.properties
          ? propertiesToUI(permission.properties)
          : '';
        return (
          <Fragment key={permission.id}>
            <TableRow
              key={permission.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, permission.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {getIdChip(permission)}
              </TableCell>
              <TableCell>{permission.permission}</TableCell>
              <TableCell>{getPermissionTypeChip(permission)}</TableCell>
              <TableCell>
                {' '}
                <Stack>
                  <Button
                    aria-label="addTest"
                    onClick={(event) =>
                      handleAddPropertyClick(event, permission.id)
                    }
                    endIcon={<PlusSquare />}
                    variant="outlined"
                    color="success"
                  >
                    {!addingProperty ? 'Add Property' : 'Cancel'}
                  </Button>
                  {addPropertyForm(isItemSelected)}
                  {properties}
                </Stack>
              </TableCell>
              <TableCell>{permission.notes}</TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddClick(event)}
          >
            <PlusCircle />
          </IconButton>
        }
        title="Permissions"
      />
      {showPermissionForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Properties</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(permissions)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}
