import React from 'react';
import { Form } from 'react-final-form';
import { finalTextField, okButton, required } from '../../core/FinalCoreForms';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../redux/actions/users/userActions';

import { Stack } from '@mui/material';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const UserInfoForm = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);

  function submitUser(submittedUser) {
    if (submittedUser && submittedUser.id) {
      return dispatch(updateUser(submittedUser)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: user && user.id ? user.id : null,
    firstName: user && user.firstName ? user.firstName : '',
    lastName: user && user.lastName ? user.lastName : '',
    cell: user && user.cell ? user.cell : '',
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (user && values) {
      submitUser(values);
    }
  };

  return (
    <div>
      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={5} alignItems="center">
              {finalTextField('firstName', 'First Name', required)}
              {finalTextField('lastName', 'Last Name', required)}
              {finalTextField('cell', 'Cell Phone', required)}

              {okButton()}
            </Stack>
          </form>
        )}
      />
    </div>
  );
};

export default UserInfoForm;
