import React from 'react';
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  ButtonToolbar,
} from 'react-bootstrap';
import { ListCheck, PlusCircle } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import EventsTable from './core/EventsTable';
import { fetchEvents } from '../../../redux/actions/Events/eventActions';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';

export default function EventsPage() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);

  const [showAll, setShowAll] = React.useState(false);
  // const [newForm, setNewForm] = React.useState(false);
  // const [filterText, setFilterText] = React.useState('');
  const events = useSelector((state) => state.events);

  const handleDisplayChange = (e) => {
    setShowAll(!showAll);
  };

  function getDisplayText() {
    if (showAll) {
      return 'Hide Some Events';
    }
    return 'Show All Events';
  }

  function renderDisplayAllButton() {
    return (
      <Button variant="info" onClick={handleDisplayChange}>
        <ListCheck />
        &nbsp;&nbsp;&nbsp;
        {getDisplayText()}
      </Button>
    );
  }

  function filterEvents() {
    if (!showAll) {
      let filteredEvents = _.filter(events, function (o) {
        return !o.dataStatus;
      });
      return filteredEvents;
    } else return events;
  }

  function getEvents() {
    var displayedEvents = filterEvents();

    return displayedEvents;
  }

  return (
    <Container fluid>
      <Helmet title="Events" />
      <Row className="show - grid">
        <Col xs={12} sm={12} md={12}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title>Event Controls</Card.Title>
            </Card.Header>
            <Card.Body>
              <ButtonToolbar>
                <LinkContainer to={'/events/new'}>
                  <Button variant="info">
                    <PlusCircle />
                    &nbsp;&nbsp;&nbsp;New Event
                  </Button>
                </LinkContainer>
                {renderDisplayAllButton()}
              </ButtonToolbar>
            </Card.Body>
          </Card>
          <Card variant="danger">
            <Card.Header>
              <Card.Title>Events</Card.Title>
            </Card.Header>
            <Card.Body>
              <EventsTable events={getEvents()} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
