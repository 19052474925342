/* AUTO GEN */
/* community_invitesReducer.js */
import {
  SET_COMMUNITY_INVITES_TO_ME,
  COMMUNITY_INVITE_TO_ME_FETCHED,
} from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMUNITY_INVITE_TO_ME_FETCHED:
      return { ...state, [action.communityInvite.id]: action.communityInvite };
    case SET_COMMUNITY_INVITES_TO_ME:
      const newPosts = _.mapKeys(action.communityInvites, 'id');
      return { ...newPosts };
    /*
     Below ADDS newPosts to current array, this is
     not ok since we are always pulling
    ALL of the data at once, so if there is an old
    record that is deleted, it will not clear
    from the list until a page refresh occurs.
    A page refresh forces the state to zero out,
    hence the INITIAL_STATE = {}, then adds the
    array to a blank data set.
    return { .this..state, ...newPosts };
    */
    default:
      return state;
  }
}
