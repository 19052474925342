/* AUTO GEN */
/* productCategoriesReducer.js */
// import { SET_PRODUCTS, PRODUCT_FETCHED } from '../../actions/types';
import { blankSalesTotals } from '../../classes/oculus/reports/SalesTotals';

const INITIAL_STATE = {
  weeklySales: [],
  dailySales: [],
  salesTotals: blankSalesTotals(),
  countsReportByDayForWeek: null, // type {}
  salesViewsByDayForWeek: null, // type {}
  allSalesViewsByDayForWeek: null, // type {}
  singleHorseSalesViews: null, // type {} - ByDayForWeek
};

const reportingReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'WEEKLY_SALES_FETCHED':
      return {
        ...state,
        weeklySales: action.payload,
      };
    case 'DAILY_SALES_FETCHED':
      return {
        ...state,
        dailySales: action.payload,
      };
    case 'SALES_TOTALS_FETCHED':
      return {
        ...state,
        salesTotals: action.payload,
      };
    case 'COUNTS_REPORT_BY_DAY_FOR_WEEK':
      return {
        ...state,
        countsReportByDayForWeek: action.payload,
      };
    case 'SALES_VIEWS_BY_DAY_FOR_WEEK':
      return {
        ...state,
        salesViewsByDayForWeek: action.payload,
      };
    case 'ALL_SALES_VIEWS_BY_DAY_FOR_WEEK':
      return {
        ...state,
        allSalesViewsByDayForWeek: action.payload,
      };
    case 'HORSE_SALES_VIEWS_BY_DAY_FOR_WEEK':
      return {
        ...state,
        singleHorseSalesViews: action.payload,
      };
    // case SET_PRODUCTS:
    //   return action.products;
    default:
      return state;
  }
};

export default reportingReducer;
