import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import {
  finalObjSelector,
  renderFormTitle,
  okCancel,
  createOptionsList,
} from '../../core/FinalCoreForms';
import { useDispatch, useSelector } from 'react-redux';
import {
  addTestToEventDivision,
  fetchDivisions,
} from '../../../redux/actions/Events/entryActions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function SelectTestForm({
  eventDivisionId,
  eventDivision,
  onOkClick,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDivisions());
  }, [dispatch]);

  const eventTests = useSelector((state) => state.eventTests);
  // const events = useSelector((state) => state.events);
  // const selectedEventId = 115;

  function submitEventTestAdd(eventTest) {
    if (eventTest && eventTest.eventDivisionId && eventTest.eventTestId > 0) {
      return dispatch(addTestToEventDivision(eventTest)).then(() => {});
    }
  }

  const initialValues = {
    eventDivisionId: eventDivisionId ? eventDivisionId : null,
    eventTestId: 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    submitEventTestAdd(values);
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      {renderFormTitle(eventDivision, 'Event Division')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalObjSelector(
              'eventTestId',
              'Event Test',
              createOptionsList(eventTests)
            )}

            {okCancel(onOkClick, 'Add')}
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
}
