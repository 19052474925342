/* AUTO GEN */
/* Video.js */

import _ from 'lodash';

export class Video {
  constructor(
    id,
    userId,
    description,
    title,
    horseVideoId,
    amazons3URL,
    type,
    dataStatus,
    createdAt
  ) {
    this.id = id;
    this.userId = userId;
    this.description = description;
    this.title = title;
    this.horseVideoId = horseVideoId;
    this.amazons3URL = amazons3URL;
    this.type = type;
    this.dataStatus = dataStatus;
    this.createdAt = createdAt;
  }
}

export function videoToClass(video) {
  if (video) {
    return new Video(
      video.id,
      video.userId,
      video.description,
      video.title,
      video.horseVideoId,
      video.amazons3URL,
      video.type,
      video.dataStatus,
      video.createdAt
    );
  } else {
    return null;
  }
}

export function videosToClassArray(videos) {
  if (videos) {
    const videosArray = videos.map((item) => {
      return videoToClass(item);
    });
    return videosArray;
  }
}

export function videosToOrderedArray(videos, order) {
  if (videos) {
    const videosArray = videosToClassArray(videos);
    if (videosArray) {
      if (order && videosArray.length === order.length) {
        var orderedArray = [];
        order.forEach((videoId) => {
          var video = _.find(videosArray, { id: videoId });
          if (video) {
            orderedArray.push(video);
          }
        });
        return orderedArray;
      } else {
        return videosArray;
      }
    }
  }
}
