/* (( *** AUTO GEN *** )) */
/* peopleActions.js */
import axios from 'axios';
import { SET_PEOPLE, PERSON_FETCHED } from '../types';

export function personFetched(person) {
  return {
    type: PERSON_FETCHED,
    person,
  };
}

export function setPeople(people) {
  return {
    type: SET_PEOPLE,
    people,
  };
}

export const fetchPerson = (id) => async (dispatch) => {
  const res = await axios.get('/api/people/read/' + id);
  dispatch(personFetched(res.data));
};

// pulls by user id for logged in user
export const fetchPeople = () => async (dispatch) => {
  const res = await axios.get('/api/people/read');
  dispatch(setPeople(res.data));
};

export const fetchPeopleByUserId = (userId) => async (dispatch) => {
  const res = await axios.get('/api/people/read/userId/' + userId);
  dispatch(setPeople(res.data));
};

export const fetchPeopleByPersonType = (personType) => async (dispatch) => {
  const res = await axios.get('/api/people/read/personType/' + personType);
  dispatch(setPeople(res.data));
};

export const createPerson = (values) => async (dispatch) => {
  const res = await axios.post('/api/people/create', values);
  dispatch(personFetched(res.data));
};

export const updatePerson = (values) => async (dispatch) => {
  const res = await axios.post('/api/people/update', values);
  dispatch(personFetched(res.data));
};

export const deletePerson = (id) => async (dispatch) => {
  const res = await axios.post('/api/people/delete/' + id);
  dispatch(setPeople(res.data));
};
