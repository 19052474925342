import _ from 'lodash';
import RingData from '../../../components/scheduler/scheduleData/RingData';

var rings = {};

let ring1 = new RingData(0, 'Ring 1', 'Dressage', "October 13, 2023 08:00:00 AM", 5);
let ring2 = new RingData(1, 'Ring 2', 'Dressage', "October 13, 2023 08:00:00 AM", 1);
let ring3 = new RingData(2, 'Ring 3', 'SJ', "October 13, 2023 09:30:00 AM", 4);
let ring4 = new RingData(3, 'Ring 4', 'XC', "October 13, 2023 09:30:00 AM", 4);

rings[ring1.id] = ring1;
rings[ring2.id] = ring2;
rings[ring3.id] = ring3;
rings[ring4.id] = ring4;

export default function reduce(state = {}, action) {
  switch (action.type) {
    case 'create_rings':
      const newRings = _.mapKeys(rings, 'id');
      return { ...newRings };
      case 'RINGS_FETCHED':
        const newBlocks = _.mapKeys(action.rings, 'id');
        return { ...newBlocks };
        case 'RING_FETCHED':
          return { ...state, [action.ring.id]: action.ring };
    case 'update_ring':
      return { ...state, [action.ring.id]: action.ring };
    // case 'ADD_BLOCK':
    //   const newMinutes = _.mapKeys(action.medias, 'id');
    //   return { ...newPosts };
    default:
      return state;
  }
}
