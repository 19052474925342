/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import MediaItem from './MediaItem';
import { Col } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

export default function MediaItemGroup({ mediaFeed, auth }) {
  function renderMediaDocuments() {
    let i = 0;
    if (mediaFeed) {
      return _.values(mediaFeed).map((media) => {
        return <MediaItem key={i++} media={media} auth={auth} />;
      });
    }
    return null;
  }

  return (
    <Col xs={12} md={12}>
      {renderMediaDocuments()}
    </Col>
  );
}
