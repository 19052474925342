import React from 'react';
import SelectedRidesRow from './SelectedRidesRow';

import styled from '@emotion/styled';
import {
  Card as MuiCard,
  CardHeader,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function SelectedTimesTable(props) {
  function tableRows() {
    var i = 0;
    if (props.rideTimes) {
      return props.rideTimes.map((rideTime) => {
        i++;
        return (
          <SelectedRidesRow
            rideTime={rideTime}
            key={i}
            selectedEvent={props.selectedEvent}
          />
        );
      });
    }
  }
  return (
    <Card mb={6}>
      <CardHeader title="Selected Ride Times" />
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Rider</TableCell>
                <TableCell>Horse</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Dressage</TableCell>
                <TableCell>Stadium</TableCell>
                <TableCell>Cross Country</TableCell>
                <TableCell>Map</TableCell>
                <TableCell>XC Opt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows()}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}
