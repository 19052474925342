import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalObjSelector,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createEventHorse,
  updateEventHorse,
} from '../../../redux/actions/Events/eventHorseActions';

import { Stack } from '@mui/material';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function EventHorseForm(props) {
  const dispatch = useDispatch();

  const initialValues = {
    id: props.eventHorse && props.eventHorse.id ? props.eventHorse.id : null,
    name:
      props.eventHorse && props.eventHorse.name ? props.eventHorse.name : '',
    breed:
      props.eventHorse && props.eventHorse.breed ? props.eventHorse.breed : '',
    color:
      props.eventHorse && props.eventHorse.color ? props.eventHorse.color : '',
    gender:
      props.eventHorse && props.eventHorse.gender ? props.eventHorse.gender : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.eventHorse) {
      Object.assign(props.eventHorse, values);
      dispatch(updateEventHorse(props.eventHorse));
    } else {
      let newData = {};
      Object.assign(newData, values);
      dispatch(createEventHorse(newData));
    }
    props.onOkClick();
  };

  const genderOptionsList = [
    { key: 'None', value: '0' },
    { key: 'Gelding', value: '1' },
    { key: 'Mare', value: '2' },
    { key: 'Stallion', value: '3' },
  ];

  return (
    <div>
      {renderFormTitle(props.eventHorse, 'Horse')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={5} alignItems="center">
              {finalTextField('name', 'Name')}
              {finalTextField('breed', 'Breed')}
              {finalTextField('color', 'Color')}
              {finalObjSelector('gender', 'Gender', genderOptionsList)}
              {okCancel(props.onCancelClick)}
            </Stack>
          </form>
        )}
      />
    </div>
  );
}
