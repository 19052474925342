/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ModalImage from 'react-modal-image';
/* eslint-enable no-unused-vars */

class PhotoCard extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleDelete = this.handleDelete.bind(this);
  }

  dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  renderImgTag() {
    if (
      this.props.photo &&
      this.props.photo.amazons3URLHalf &&
      this.props.photo.amazons3URLHalf.length > 0
    ) {
      return (
        <ModalImage
          small={this.props.photo.amazons3URLHalf}
          medium={this.props.photo.amazons3URLHalf}
          large={this.props.photo.amazons3URL}
          alt="Hello World!"
          key={this.props.photo.id}
        />
      );
    }
    if (
      this.props.photo &&
      this.props.photo.amazons3URL &&
      this.props.photo.amazons3URL.length > 0
    ) {
      return (
        <ModalImage
          small={this.props.photo.amazons3URL}
          medium={this.props.photo.amazons3URL}
          alt="Hello World!"
          key={this.props.photo.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  getDisplayName() {
    if (
      this.props.photo &&
      this.props.photo._user &&
      this.props.photo._user.displayName
    ) {
      return this.props.photo._user.displayName;
    } else {
      return 'Unknown Owner';
    }
  }

  getUploadDate() {
    if (this.props.photo && this.props.photo.createdAt) {
      return this.dateToText(this.props.photo.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  handleDelete = (e) => {
    if (e) {
      e.preventDefault();
    }
    const photoId = this.props.photo.id;
    this.props.onDelete({ photoId });
  };

  getDeleteButton() {
    if (this.props.onDelete) {
      return (
        <Button onClick={this.handleDelete} variant="danger">
          Delete
        </Button>
      );
    }
  }

  handleMakeMainPhoto = (e) => {
    if (e) {
      e.preventDefault();
    }
    const photoURL = this.props.photo.amazons3URLThumb;
    this.props.onMakeMainPhoto({ photoURL });
  };

  getMakeMainPhotoButton() {
    if (this.props.onMakeMainPhoto) {
      return (
        <Button onClick={this.handleMakeMainPhoto} variant="info">
          Make Main Photo
        </Button>
      );
    }
  }

  render() {
    return (
      <Col xs={12} md={6}>
        <div className="card">
          {this.renderImgTag()}
          <div className="container">
            <h4>{this.getDisplayName()}</h4>
            <h5>{this.getUploadDate()}</h5>
            <p>{this.getDeleteButton()}</p>
            <p>{this.getMakeMainPhotoButton()}</p>
          </div>
        </div>
      </Col>
    );
  }
}

export default PhotoCard;
