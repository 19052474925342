import React, { useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Calendar } from 'react-bootstrap-icons';
import CalendarTable from '../core/CalendarTable';
import HelmetTracker from '../../core/HelmetTracker';

export default function CalendarPage() {
  const [showPriorEvents, setShowPriorEvents] = useState(false);

  function getButtonTitle() {
    if (showPriorEvents) return 'Show Upcoming Events';
    return 'Show Prior Events';
  }

  function changeCalendarDisplay() {
    var current = showPriorEvents;
    setShowPriorEvents(!current);
  }

  return (
    <div>
      <HelmetTracker title="Calendar" />
      <Alert>
        <h2>
          <Calendar />
          &nbsp;&nbsp; Calendar
        </h2>
        <h4> Have a look and see what we have going on!</h4>
        <Button variant="warning" onClick={() => changeCalendarDisplay()}>
          {getButtonTitle()}
        </Button>
      </Alert>
      <CalendarTable showPrior={showPriorEvents}></CalendarTable>
    </div>
  );
}
