import React from 'react';
import HorseDocumentCard from './HorseDocumentCard';
import { Table } from 'react-bootstrap';

export default function HorseDocumentGroup({ horse }) {
  function renderHorseDocuments() {
    if (horse && horse.documents) {
      return horse.documents.map((horseDocument) => {
        return (
          <tr key={horseDocument.id}>
            <HorseDocumentCard
              horseDocument={horseDocument}
              onDelete={null}
              onMakeMainDocument={null}
            />
          </tr>
        );
      });
    }
    return null;
  }

  return (
    <Table striped bordered hover>
      <tbody>{renderHorseDocuments()}</tbody>
    </Table>
  );
}
