/* eslint-disable no-unused-vars */
import React from 'react';
import { ListGroupItem, Card, Button, ListGroup, Image } from 'react-bootstrap';
import { getGenderText } from '../../../../core/HorseTools';
import { LinkContainer } from 'react-router-bootstrap';

import {
  Grid,
  // Divider as MuiDivider,
  // Typography as MuiTypography,
  Stack,
} from '@mui/material';

import DailyLineChart from '../../../admin/reporting/core/DailyLineChart';
import SalesStats from '../../../admin/reporting/core/SalesStats';

export default function HorseDetailsPanel({ horse, horseViews }) {
  function getWeeklyViews() {
    if (horseViews) {
      var count = 0;
      count += horseViews.dayMinusSixRolling;
      count += horseViews.dayMinusFiveRolling;
      count += horseViews.dayMinusFourRolling;
      count += horseViews.dayMinusThreeRolling;
      count += horseViews.dayMinusTwoRolling;
      count += horseViews.dayMinusOneRolling;
      count += horseViews.dayRolling;
      return count;
    } else {
      return 0;
    }
  }

  function getDescription() {
    if (horse) {
      return horse.description;
    }
    return '';
  }

  function formatAsDollars(dollarValue) {
    if (dollarValue) {
      let formatting_options = {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      };
      let dollarString = new Intl.NumberFormat('en-US', formatting_options);
      return dollarString.format(dollarValue);
    }
  }

  function getGeneralInformation() {
    if (horse) {
      return (
        <ListGroup>
          <ListGroupItem>Description: {getDescription()}</ListGroupItem>
          <ListGroupItem>Price: {formatAsDollars(horse.price)}</ListGroupItem>
          <ListGroupItem>Breed: {horse.breed}</ListGroupItem>
          <ListGroupItem>Gender: {getGenderText(horse.gender)}</ListGroupItem>
          <ListGroupItem>Color: {horse.color}</ListGroupItem>
          <ListGroupItem>Height: {horse.height}</ListGroupItem>
          <ListGroupItem>Birth Year: {horse.birthYear}</ListGroupItem>
          <ListGroupItem>
            <Image src={horse.getMainPhotoURLHalf()} width="200px" />
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getEditButton() {
    var route = '';
    if (horse && horse.id) route = '/horses/edit/' + horse.id;
    return (
      <LinkContainer to={route}>
        <Button variant="danger">Edit</Button>
      </LinkContainer>
    );
  }

  function getDailyChart() {
    if (horseViews) {
      var dataArray = [horseViews];
      var labels = ['Views'];

      return (
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={3} xl>
            <Stack>
              <DailyLineChart
                title="Page Views"
                dailyData={dataArray}
                dataLabels={labels}
              />
              <SalesStats
                title="Weekly Views"
                amount={getWeeklyViews()}
                chip="Weekly"
              />
              <SalesStats
                title="24hr Views"
                amount={horseViews.dayRolling}
                chip="24HRs"
              />
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Card bg="primary">
      <Card.Header>
        <h2>Details &nbsp;&nbsp;{getEditButton()}</h2>
      </Card.Header>
      <Card.Body>{getGeneralInformation()}</Card.Body>
      <Card.Footer>{horseViews ? getDailyChart() : ''}</Card.Footer>
    </Card>
  );
}
