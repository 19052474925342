import React from 'react';
import { Table } from 'react-bootstrap';

export default function HorsePedigree(horse) {
  return (
    <div>
    {/* {console.log(horse)} */}
      {horse != null && horse.horse != null && horse.horse.pedigree != null ? (
        <Table responsive striped bordered hover variant="dark">
          <tbody>
            <tr>
              <td rowSpan="16" style={{ verticalAlign: 'middle' }}>
                {horse.horse.name}
              </td>
              <td rowSpan="8" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.sire
                  ? horse.horse.pedigree.sire.name
                  : ''}
              </td>
              <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresire
                  ? horse.horse.pedigree.siresire.name
                  : ''}
              </td>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiresire
                  ? horse.horse.pedigree.siresiresire.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiresiresire
                  ? horse.horse.pedigree.siresiresiresire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiresiredam
                  ? horse.horse.pedigree.siresiresiredam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiredam
                  ? horse.horse.pedigree.siresiredam.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiredamsire
                  ? horse.horse.pedigree.siresiredamsire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siresiredamdam
                  ? horse.horse.pedigree.siresiredamdam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredam
                  ? horse.horse.pedigree.siredam.name
                  : ''}
              </td>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamsire
                  ? horse.horse.pedigree.siredamsire.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamsiresire
                  ? horse.horse.pedigree.siredamsiresire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamsiredam
                  ? horse.horse.pedigree.siredamsiredam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamdam
                  ? horse.horse.pedigree.siredamdam.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamdamsire
                  ? horse.horse.pedigree.siredamdamsire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.siredamdamdam
                  ? horse.horse.pedigree.siredamdamdam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="8" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.dam ? horse.horse.pedigree.dam.name : ''}
              </td>
              <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsire
                  ? horse.horse.pedigree.damsire.name
                  : ''}
              </td>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiresire
                  ? horse.horse.pedigree.damsiresire.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiresiresire
                  ? horse.horse.pedigree.damsiresiresire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiresiredam
                  ? horse.horse.pedigree.damsiresiredam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiredam
                  ? horse.horse.pedigree.damsiredam.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiredamsire
                  ? horse.horse.pedigree.damsiredamsire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damsiredamdam
                  ? horse.horse.pedigree.damsiredamdam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="4" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdam
                  ? horse.horse.pedigree.damdam.name
                  : ''}
              </td>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamsire
                  ? horse.horse.pedigree.damdamsire.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamsiresire
                  ? horse.horse.pedigree.damdamsiresire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamsiredam
                  ? horse.horse.pedigree.damdamsiredam.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="2" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamdam
                  ? horse.horse.pedigree.damdamdam.name
                  : ''}
              </td>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamdamsire
                  ? horse.horse.pedigree.damdamdamsire.name
                  : ''}
              </td>
            </tr>
            <tr>
              <td rowSpan="1" style={{ verticalAlign: 'middle' }}>
                {horse.horse.pedigree.damdamdamdam
                  ? horse.horse.pedigree.damdamdamdam.name
                  : ''}
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        'Pedigree not entered.'
      )}
    </div>
  );
}
