import React from 'react';
import { Chip as MuiChip, TableCell, TableRow } from '@mui/material';
import styled from '@emotion/styled';

const Chip = styled(MuiChip)`
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

export default function OrderOfRidesRow(props) {
  function getDressageChip() {
    return <Chip label="Dressage" color="error" />;
  }

  function getStadiumChip() {
    return <Chip label="Stadium" color="warning" />;
  }

  function getXCChip() {
    return <Chip label="XC" color="success" />;
  }

  function getDiffChip() {
    if (props.rideTime.diffValue) {
      if (props.rideTime.diffValue < 30) {
        if (props.rideTime.phase === 3) {
          return <Chip label={props.rideTime.diff} color="success" />;
        } else {
          return <Chip label={props.rideTime.diff} color="error" />;
        }
      }
      if (props.rideTime.diffValue < 60) {
        return <Chip label={props.rideTime.diff} color="warning" />;
      }
      return <Chip label={props.rideTime.diff} color="success" />;
    }
  }

  function phaseToChip() {
    if (props.rideTime.phase) {
      switch (props.rideTime.phase) {
        case 1:
          return getDressageChip();
        case 2:
          return getStadiumChip();
        case 3:
          return getXCChip();
        default:
          return '';
      }
    }
  }

  return (
    <TableRow key={props.rideTime.id}>
      <TableCell component="th" scope="row">
        {props.rideTime.rider}
      </TableCell>
      <TableCell>{props.rideTime.horse}</TableCell>
      <TableCell>{props.rideTime.division}</TableCell>
      <TableCell>{phaseToChip()}</TableCell>
      <TableCell>{props.rideTime.time}</TableCell>
      <TableCell>{getDiffChip()}</TableCell>
    </TableRow>
  );
}
