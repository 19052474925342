import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { MoreVertical } from 'react-feather';

import { fetchEntries } from '../../../redux/actions/Events/entryActions';
import { useDispatch, useSelector } from 'react-redux';

import {
  Card as MuiCard,
  CardHeader,
  Checkbox,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function EntriesPage() {
  const [selected, setSelected] = React.useState([]);
  // const [selected, setSelected] = React.useState(-1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEntries());
  }, [dispatch]);

  const entries = useSelector((state) => state.entries);
  // const entry = useSelector((state) =>
  //   state.entries.find((x) => x.id === selected)
  // );

  function typeToChip(type) {
    if (type === 0) {
      return <Chip label="Unassigned" color="danger" />;
    } else if (type === 1) {
      return <Chip label="Horse Trials" color="success" />;
    } else if (type === 2) {
      return <Chip label="Combined Test" color="info" />;
    } else if (type === 3) {
      return <Chip label="Dressage Only" color="warning" />;
    } else {
      return '';
    }
  }

  const handleClick = (event, id) => {
    // setSelected(id);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // For multi row select
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Single select
  // const isSelected = (id) => selected === id;

  function mapBody(entries) {
    if (entries && entries.length > 0) {
      
        const tableBody = entries.map((entry) => {
          const isItemSelected = isSelected(entry.id);
          const labelId = `enhanced-table-checkbox-${entry.id}`;
          return (
            <TableRow
              key={entry.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, entry.id)}
              selected={isItemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {entry.id}
              </TableCell>
              <TableCell>{entry.riderName}</TableCell>
              <TableCell>{entry.horseName}</TableCell>
              <TableCell>{entry.eventDivisionName}</TableCell>
              <TableCell>{typeToChip(entry.divisionType)}</TableCell>
              {/* <TableCell>{entry.eventLevelName}</TableCell> */}
            </TableRow>
          );
        });
        return tableBody;
      
    }
  }

  function showEntryForm() {
    // if(selected && selected > 0) {
    //   if (entry) {
    //     return ( <EntryForm entry={entry} /> )
    //   }
    // }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = entries.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Entries"
      />
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < entries.length
                    }
                    checked={
                      entries.length > 0 && selected.length === entries.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell>Entry Id</TableCell>
                <TableCell>Rider</TableCell>
                <TableCell>Horse</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Type</TableCell>
                {/* <TableCell>Level</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(entries)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      {showEntryForm()}
    </Card>
  );
}

export default EntriesPage;
