import React from 'react';
import { Alert } from 'react-bootstrap';
import { Clock } from 'react-bootstrap-icons';
import SJCourseInfoTable from '../core/SJCourseInfoTable';

export default function SJCourseInfoList() {
  return (
    <div>
      <Alert>
        <h2>
          <Clock />
          &nbsp;&nbsp; SJ Course Info
        </h2>
      </Alert>
      <SJCourseInfoTable />
    </div>
  );
}
