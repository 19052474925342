import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { resetPassword } from '../../redux/actions';
import { withRouter } from '../withRouter';
import { Button } from 'react-bootstrap';

const initialValues = {
  password: '',
  confirmPassword: '',
};

/**
 * NOTE: Swapped to a class Component since it can use async.
 * The Tutorial says the point of formik is to keep things functional,
 * same as the reason to use hooks.  But this seems a future thing for me, as
 * the benefits are not clear when I can use a Component and just be done.
 * Seems adding a TON of complexity and new untested (by me) knowledge and learning
 * when I need to see the project out the door asap.
 */

const validationSchema = Yup.object({
  password: Yup.string().min(5, 'Required!').required('Required!'),
  confirmPassword: Yup.string()
    .required('PleaseConfirm your password')
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

class ResetPassFormik extends Component {
  onSubmit = (values) => {
    this.props.resetPassword(
      {
        password: values.password,
        token: this.props.token,
      },
      this.props.history
    );
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        <Form>
          <div className="form-control">
            <label htmlFor="password">Password</label>
            <Field type="password" id="password" name="password" />
            <ErrorMessage name="password" />
          </div>

          <div className="form-control">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Field
              type="password"
              id="confirmPassword"
              name="confirmPassword"
            />
            <ErrorMessage name="confirmPassword" />
          </div>

          <Button variant="danger" type="submit">
            Reset Password
          </Button>
        </Form>
      </Formik>
    );
  }
}

export default connect(null, { resetPassword })(withRouter(ResetPassFormik));
