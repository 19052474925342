/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  addPhotoToTack,
  setMainPhotoForTack,
} from '../../../redux/actions/tackActions';
import {
  Col,
  Form,
  Card,
  Button,
  Container,
  Alert,
  Row,
  ButtonToolbar,
  Image,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import {
  textField,
  photoUploader,
} from '../../core/CoreForms';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
import TackPhotoGroup from './TackPhotoGroup';
import ModalImage from 'react-modal-image';
import { v4 as uuidv4 } from 'uuid';
/* eslint-enable no-unused-vars */

class TackManage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      id: this.props.tack ? this.props.tack.id : null,
      userId: this.props.tack ? this.props.tack.userId : null,
      owner: this.props.tack ? this.props.tack.owner : '',
      name: this.props.tack ? this.props.tack.name : '',
      photoDescription: '',
      photoURL: this.props.tack
        ? this.props.tack.photoURL
        : '/images/tacks/tack_avatar.jpg',
      errors: '',
      loading: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tack) {
      this.setState({
        id: nextProps.tack.id ? nextProps.tack.id : null,
        userId: nextProps.tack ? nextProps.tack.userId : null,
        owner: nextProps.tack ? nextProps.tack.owner : '',
        name: nextProps.tack.name,
        photoURL: nextProps.tack.photoURL,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createOptionObjects() {
    return _.map(this.props.barns, (barn) => {
      return { index: barn.id, label: barn.name };
    });
  }

  getTackEditPath() {
    if (this.props.tack) {
      return '/tack/edit/' + this.props.tack.id;
    }
    return '/tack/';
  }

  renderHeaderButtons() {
    return (
      <Card.Body>
        <ButtonToolbar>
          <LinkContainer to={'/tack'}>
            <Button variant="warning">Return to Tack</Button>
          </LinkContainer>
          <LinkContainer to={this.getTackEditPath()}>
            <Button variant="info">Edit Details</Button>
          </LinkContainer>
        </ButtonToolbar>
      </Card.Body>
    );
  }

  handleSubmitPhoto = (e) => {
    if (e.target.photos.files.length > 0) {
      if (e) {
        e.preventDefault();
      }

      const { id, photoDescription } = this.state;
      const data = new FormData();
      data.append('description', photoDescription);
      data.append('title', '');
      data.append('userId', this.props.auth.id);
      data.append('tackId', id);
      data.append('uuid', uuidv4());

      for (
        let i = 0, numFiles = e.target.photos.files.length;
        i < numFiles;
        i++
      ) {
        let file = e.target.photos.files[i];
        data.append('photos', file, file.name);
      }
      this.setState({ photoDescription: '', loading: true });
      e.target.photos.value = null; // blanks the file
      this.props
        .addPhotoToTack(data)
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleMakeMainPhoto = ({ photoURL }) => {
    // id = tackId
    const { id } = this.state;
    this.setState({ loading: true });
    this.props
      .setMainPhotoForTack({
        tackId: id,
        photoURL,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return (
        <ModalImage
          small={photoURL}
          medium={photoURL}
          large={photoURL}
          alt="Hello World!"
          key={photoURL}
        />
      );
    } else {
      return (
        <Image
          src="/images/FGFarmLogo150h.PNG"
          alt="Avatar"
          rounded
          responsive
        />
      );
    }
  }

  /* eslint-disable max-len */
  render() {
    const photosPanel = (
      <Card variant="danger">
        <Card.Header>
          <h2>Photos</h2>
        </Card.Header>
        <Card.Body>
          {this.renderImgTag(this.state.photoURL)}
          <h3>Add Photo</h3>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleSubmitPhoto}
          >
            {textField('photoDescription', 'Description', this)}
            {photoUploader()}
            <Button type="submit" variant="info">
              Submit
            </Button>
          </Form>
        </Card.Body>
        <Card.Footer>
          <TackPhotoGroup
            tackId={this.props.match.params.id}
            onMakeMainPhoto={this.handleMakeMainPhoto}
          />
        </Card.Footer>
      </Card>
    );
    return (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Alert>
              <Easel />
              {this.props.tack ? this.props.tack.name : ''}
              <small>(id:{this.props.tack ? this.props.tack.id : ''})</small>
              <small> Management Console</small>
            </Alert>
          </Col>
        </Row>
        <Row className="show-grid">
          <Card variant="danger">
            {this.renderHeaderButtons()}
            <Card.Body>
              <Col xs={12} sm={12} md={12}>
                {photosPanel}
              </Col>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  tack: state.tacks.find((item) => item.id === props.match.params.id),
      tack: state.tacks[props.match.params.id],
      barns: state.barns,
      auth: state.auth,
    };
  }

  return {
    tack: null,
    barns: state.barns,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  addPhotoToTack,
  setMainPhotoForTack,
})(TackManage);
