import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button } from 'react-bootstrap';

const AccountCreated = () => {
  return (
    <div>
      <h1>Account Created!!</h1>
      <h3>
        Please proceed to{' '}
        <LinkContainer to={'/login'}>
          <Button>Login</Button>
        </LinkContainer>
        &nbsp; to log into the new account!
      </h3>
    </div>
  );
};

export default AccountCreated;
