import { combineReducers } from 'redux';
import authReducer from './authReducer';
import barnsReducer from './barns/barnsReducer';
import barnUsersReducer from './barns/barnUsersReducer';
import calendarReducer from './calendarReducer';
import communityInvitesReducer from './community/communityInvitesReducer';
import coInvitesToMeReducer from './community/coInvitesToMeReducer';
import communityUsersReducer from './community/communityUsersReducer';
import eventsReducer from './events/eventsReducer';
import eventLevelsReducer from './events/eventLevelsReducer';
import horsesReducer from './horses/horsesReducer';
import jumpsReducer from './jumpsReducer';
import mediasReducer from './mediasReducer';
import mediaFeedReducer from './mediaFeedReducer';
import saleHorsesReducer from './saleHorsesReducer';
import usersSearchReducer from './usersSearchReducer';

import scheduleBlocksReducer from './scheduler/scheduleBlocksReducer';
import ringsReducer from './scheduler/ringsReducer';
import divisionsReducer from './events/divisionsReducer';
import entriesReducer from './events/entriesReducer';
import eventDivisionsReducer from './events/eventDivisionsReducer';
import eventHorsesReducer from './events/eventHorsesReducer';
import eventTestsReducer from './events/eventTestsReducer';
import eventSelectablesReducer from './events/eventSelectablesReducer';
import peopleReducer from './events/peopleReducer';
import userEntriesReducer from './events/userEntriesReducer';

import taskReducer from './tasks/taskReducer';

// STORE
import productsReducer from './store/productsReducer';
import productCategoriesReducer from './store/productCategoriesReducer';
import productItemsReducer from './store/productItemsReducer';

// ADMIN
import permissionsReducer from './admin/permissionsReducer';

// OCULUS
import reportingReducer from './oculus/reportingReducer';

// SHOPPING
import cartReducer from '../slices/cartSlice';

export default combineReducers({
  auth: authReducer,
  horses: horsesReducer,
  reporting: reportingReducer,
  cart: cartReducer,
  barns: barnsReducer,
  barnUsers: barnUsersReducer,
  calendar: calendarReducer,
  communityInvites: communityInvitesReducer,
  communityInvitesToMe: coInvitesToMeReducer,
  communityUsers: communityUsersReducer,
  divisions: divisionsReducer,
  entries: entriesReducer,
  eventDivisions: eventDivisionsReducer,
  eventHorses: eventHorsesReducer,
  events: eventsReducer,
  eventSelectables: eventSelectablesReducer,
  eventLevels: eventLevelsReducer,
  eventTests: eventTestsReducer,
  jumps: jumpsReducer,
  medias: mediasReducer,
  mediaFeed: mediaFeedReducer,
  people: peopleReducer,
  permissions: permissionsReducer,
  productCategories: productCategoriesReducer,
  productItems: productItemsReducer,
  products: productsReducer,
  rings: ringsReducer,
  saleshorses: saleHorsesReducer,
  scheduleBlocks: scheduleBlocksReducer,
  searchedUsers: usersSearchReducer,
  tasks: taskReducer,
  userEntries: userEntriesReducer,
});
