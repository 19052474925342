import React from 'react';
import EventTableRow from './EventTableRow';
import { Table, Button } from 'react-bootstrap';
import _ from 'lodash';

export default function EventsTable({ events }) {
  const [dateOrder, setDateOrder] = React.useState(false);

  function changeDateSort() {
    setDateOrder(!dateOrder);
  }

  function dateOrderToText() {
    if (dateOrder) {
      return 'List Newer First';
    }
    return 'List Older First';
  }

  function renderEvents() {
    if (dateOrder) {
      return _.orderBy(events, ['startDate']).map((event) => {
        return <EventTableRow event={event} key={event.id} />;
      });
    }
    return _.orderBy(events, ['startDate'], ['desc']).map((event) => {
      return <EventTableRow event={event} key={event.id} />;
    });
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
            <h3>Id</h3>
            <br></br>
          </th>
          <th>
            <h3>Type</h3>
            <br></br>
          </th>
          <th>
            <h3>Name</h3>
            <br></br>
          </th>
          <th>
            <h3>Docs</h3>
            <br></br>
          </th>
          <th>
            <h3>Date(s)</h3>
            <Button variant="danger" onClick={() => changeDateSort()}>
              {dateOrderToText()}
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>{renderEvents()}</tbody>
    </Table>
  );
}
