import { SET_CALENDAR, CALENDAR_EVENT_FETCHED } from '../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CALENDAR_EVENT_FETCHED:
      return { ...state, [action.event.id]: action.event };
    case SET_CALENDAR:
      return { ...action.events };
    default:
      return state;
  }
}
