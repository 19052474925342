import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { fetchMediaFeed } from '../../redux/actions/mediaActions';
import MediaItemGroup from '../customers/media/core/MediaItemGroup';

export default function MediaFeed() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMediaFeed());
  }, [dispatch]);

  function sortedMediaFeed(mediaFeed) {
    if (mediaFeed) {
      let sortedFeed = _.orderBy(mediaFeed, ['createdAt'], ['desc']);
      return sortedFeed;
    }
    return null;
  }

  const auth = useSelector((state) => state.auth);
  const mediaFeed = useSelector((state) => sortedMediaFeed(state.mediaFeed));

  function displayPosts() {
    if (auth && auth.checkHasPermission('CREATE_MEDIAS')) {
      return <MediaItemGroup mediaFeed={mediaFeed} auth={auth} />;
    } else {
      return <MediaItemGroup mediaFeed={mediaFeed} />;
    }
  }

  return <>{displayPosts()}</>;
}
