import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IoMdLogIn } from 'react-icons/io';

function LoginButton() {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/login');
  }

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      startIcon={<IoMdLogIn />}
      color="success"
    >
      Login
    </Button>
  );
}

export default LoginButton;
