const _ = require('lodash');

const pages = [
  {
    href: '/',
    title: 'FGF Home',
  },
  {
    href: '/calendar',
    title: 'Calendar',
  },
  {
    href: '/sales',
    title: 'Sales',
  },
  {
    href: '/shop',
    title: 'Shop',
  },
  {
    href: '/boarding',
    title: 'Boarding',
  },
  {
    href: '/schooling',
    title: 'Schooling',
  },
  {
    href: '/directions',
    title: 'Directions',
  },
  {
    href: '/maps',
    title: 'Maps',
  },
  {
    href: '/contactus',
    title: 'Contact Us',
  },
  {
    href: '/horses',
    title: 'Horses',
  },
  {
    href: '/barns',
    title: 'Barns',
  },
  {
    href: '/events',
    title: 'Events',
  },
  {
    href: '/release',
    title: 'Release',
  },
  {
    href: '/admin/permissions',
    title: 'Permissions',
  },
  {
    href: '/scheduling/home',
    title: 'Scheduling Home',
  },
  {
    href: '/scheduling/divisions',
    title: 'Divisions',
  },
  {
    href: '/scheduling/entries',
    title: 'Entries',
  },
  {
    href: '/scheduling/event/divisions',
    title: 'Event Divisions',
  },
  {
    href: '/scheduling/times',
    title: 'Times',
  },
  {
    href: '/store/home',
    title: 'Store Home',
  },
  {
    href: '/store/products',
    title: 'Products',
  },
  {
    href: '/store/productcategories',
    title: 'Product Categories',
  },
  {
    href: '/store/productitems',
    title: 'Product Items',
  },
  {
    href: '/user/home',
    title: 'My Home',
  },
  {
    href: '/user/horses',
    title: 'My Horses',
  },
  {
    href: '/user/people',
    title: 'My People',
  },
  {
    href: '/user/entries',
    title: 'My Entries',
  },
  {
    href: '/orders',
    title: 'Orders',
  },
  {
    href: '/invoices',
    title: 'Invoices',
  },
  {
    href: '/invoices/detail',
    title: 'Invoice Detail',
  },
];

export function titleForURL(url) {
  let found = _.find(pages, { href: url });
  if (found) {
    return found.title;
  }
  return 'unk';
}
