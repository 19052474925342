import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalObjSelector,
  finalBoolField,
  okCancelButtons,
  renderFormTitle,
  maxLength,
  finalDateField,
} from '../../../core/FinalCoreForms';
import moment from 'moment';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const EventSubForm = ({ event, submitEvent }) => {
  const initialValues = {
    id: event && event.id ? event.id : null,
    title: event ? event.title : '',
    description: event ? event.description : '',
    startDate: event
      ? moment(event.startDate).toDate()
      : moment().toDate(),
    endDate: event ? moment(event.endDate).toDate() : moment().toDate(),
    type: event ? event.type : 0,
    visibleOnSite: event ? event.visibleOnSite : false,
    acceptingEntries: event
      ? event.acceptingEntries
      : false,
    notes: event ? event.notes : '',
    oneDay: event ? event.oneDay : false,
    useaNumber: event ? event.useaNumber : '',
    eeNumber: event ? event.eeNumber : '',
    dataStatus:
      event && event.dataStatus ? event.dataStatus : 0,
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (event && submitEvent) {
      Object.assign(event, values);
      submitEvent(event);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitEvent(newData);
    }
  };

  const typeOptionsList = [
    { key: 'None', value: '0' },
    { key: 'Recognized HT', value: '1' },
    { key: 'CT', value: '2' },
    { key: 'Schooling HT', value: '3' },
    { key: 'Dressage Tests', value: '4' },
    { key: 'Clinic', value: '5' },
    { key: 'XC Tests', value: '6' },
    { key: 'Young Event Horse', value: '7' },
  ];

  const dataStatusOptionsList = [
    { key: 'Ok', value: '0' },
    { key: 'New', value: '1' },
    { key: 'Unknown', value: '2' },
    { key: 'Deleted', value: '3' },
    { key: 'Unknown', value: '4' },
    { key: 'Archived', value: '5' },
  ];

  function navigateBackPath() {
    if (event && event.id)
      return '/events/manage/' + event.id;
    else
      return '/events';
  }

  return (
    <div>
      {renderFormTitle(event, 'Events')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {okCancelButtons(navigateBackPath())}
            {finalTextField('title', 'Title', maxLength(100))}
            {finalTextField('description', 'Description')}
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalDateField('startDate', 'Start Date')}
            {finalDateField('endDate', 'End Date')}
            {finalBoolField('visibleOnSite', 'Visible On Site')}
            {finalBoolField('acceptingEntries', 'Accepting Entries')}
            {finalBoolField('oneDay', 'One Day')}
            {finalTextField('notes', 'Notes')}
            {finalTextField('useaNumber', 'Usea Number')}
            {finalTextField('eeNumber', 'Ee Number')}
            {finalObjSelector('dataStatus', 'DataStatus', dataStatusOptionsList)}
            {okCancelButtons(navigateBackPath())}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default EventSubForm;
