/* (( *** AUTO GEN *** )) */
/* pageviewActions.js */
import axios from 'axios';
// import { pageCountsToClassArray } from '../../../classes/oculus/reports/PageCount';
// import { salesTotalsToClass } from '../../../classes/oculus/reports/SalesTotals';
// import { SET_PAGEVIEWS, PAGEVIEW_FETCHED } from '../types';

export function weeklySalesFetched(pageCounts) {
  return {
    type: 'WEEKLY_SALES_FETCHED',
    payload: pageCounts,
  };
}

export function dailySalesFetched(pageCounts) {
  return {
    type: 'DAILY_SALES_FETCHED',
    payload: pageCounts,
  };
}

export function weeklySalesTotalsFetched(data) {
  return {
    type: 'SALES_TOTALS_FETCHED',
    payload: data,
  };
}

export function countsReportByDayForWeek(data) {
  return {
    type: 'COUNTS_REPORT_BY_DAY_FOR_WEEK',
    payload: data,
  };
}

export function salesViewsByDayForWeek(data) {
  return {
    type: 'SALES_VIEWS_BY_DAY_FOR_WEEK',
    payload: data,
  };
}

export function allSalesViewsByDayForWeek(data) {
  return {
    type: 'ALL_SALES_VIEWS_BY_DAY_FOR_WEEK',
    payload: data,
  };
}

export function horseSalesViewsByDayForWeek(data) {
  return {
    type: 'HORSE_SALES_VIEWS_BY_DAY_FOR_WEEK',
    payload: data,
  };
}

/* ---------------------------- */

export const fetchWeeklySalesReport = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/sales/pagesReport/weekly');
  dispatch(weeklySalesFetched(res.data));
};

export const fetchDailySalesReport = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/sales/pagesReport/daily');
  dispatch(dailySalesFetched(res.data));
};

export const fetchWeeklySalesTotals = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/sales/report');
  dispatch(weeklySalesTotalsFetched(res.data));
  dispatch(fetchPageCountsByDayForWeek());
  dispatch(fetchSalesViewsByDayForWeek());
  dispatch(fetchAllSalesViewsByDayForWeek());
};

export const fetchPageCountsByDayForWeek = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/pages/countsReportByDayForWeek');
  dispatch(countsReportByDayForWeek(res.data));
};

export const fetchSalesViewsByDayForWeek = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/pages/salesViewsByDayForWeek');
  dispatch(salesViewsByDayForWeek(res.data));
};

export const fetchAllSalesViewsByDayForWeek = () => async (dispatch) => {
  const res = await axios.get('/api/reporting/pages/allSalesViewsByDayForWeek');
  dispatch(allSalesViewsByDayForWeek(res.data));
};

export const fetchHorseSalesViewsByDayForWeek = (horseId) => async (dispatch) => {
  const res = await axios.get('/api/reporting/pages/horseSalesViewsByDayForWeek/' + horseId);
  dispatch(horseSalesViewsByDayForWeek(res.data));
};


