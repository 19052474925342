/* AUTO GEN */
/* Document.js */
export class Document {
    constructor(
      id, 
      userId, 
      description, 
      title, 
      category, 
      type, 
      amazons3URL, 
      originalName, 
      dataStatus, 
      createdAt
    ) {
      this.id = id;
      this.userId = userId;
      this.description = description;
      this.title = title;
      this.category = category;
      this.type = type;
      this.amazons3URL = amazons3URL;
      this.originalName = originalName;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
    }
  }
  
  export function documentToClass(document) {
    if (document) {
      return new Document(
        document.id, 
        document.userId, 
        document.description, 
        document.title, 
        document.category, 
        document.type, 
        document.amazons3URL, 
        document.originalName, 
        document.dataStatus, 
        document.createdAt
      );
    } else {
      return null;
    }
  }
  
  export function documentsToClassArray(documents) {
    if (documents) {
      const documentsArray = documents.map((item) => {
        return documentToClass(item);
      });
      return documentsArray;
    }
  }
  
  