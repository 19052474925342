/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveTack, fetchTack, updateTack } from '../../../redux/actions/tackActions';
import { redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import TackForm from './TackForm';
import { Container } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TackFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    const { id } = 0;
    if (id) {
      this.props.fetchTack(id);
    }
  };

  saveTack = ({
    id,
    name,
    description,
    brandName,
    color,
    price,
    pricePaid,
    cost,
    _tackCategory,
    visible,
    dataStatus,
    userId,
  }) => {
    if (id) {
      return this.props
        .updateTack({
          id,
          name,
          description,
          brandName,
          color,
          price,
          pricePaid,
          cost,
          tackCategoryId: _tackCategory,
          visible,
          dataStatus,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    } else {
      return this.props
        .saveTack({
          name,
          description,
          brandName,
          color,
          price,
          pricePaid,
          cost,
          tackCategoryId: _tackCategory,
          visible,
          dataStatus,
          userId,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    return (
      <Container fluid>
        {this.state.redirect ? (
          redirect('/tack')
        ) : (
          <TackForm
            tack={this.props.tack}
            saveTack={this.saveTack}
            tackCategories={this.props.tackCategories}
            auth={this.props.auth}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  const { id } = 0;
  if (id) {
    return {
      tack: state.tacks[id],
      tackCategories: state.tackCategories,
      auth: state.auth,
    };
  }

  return {
    tack: null,
    tackCategories: state.tackCategories,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { saveTack, fetchTack, updateTack })(
  TackFormPage
);
