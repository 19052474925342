import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Container,
  Row,
  Col,
  ButtonToolbar,
  Collapse,
  Button,
} from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import JumpList from './JumpList';
import { LinkContainer } from 'react-router-bootstrap';
import { textField, boolField, stringSelector } from '../../core/CoreForms';
import { fetchJumps } from '../../../redux/actions/jumpActions';
import _ from 'lodash';

const categories = [
  'N/A',
  'Rolltop',
  'Table',
  'Coop',
  'Log',
  'Feeder',
  'Barn/Cabin',
  'Corner',
  'Scoop',
  'Ramp',
  'Ditch',
  'Ditch & Wall',
  'Trakhener',
  'Other',
];
const levels = [
  'N/A',
  'Intermediate',
  'Preliminary',
  'Training',
  'Novice',
  'Beginner Novice',
  'Starter',
  'Other',
];
let intermediateStyle = {
  color: 'white',
  backgroundColor: 'red',
  padding: '5px',
  borderRadius: '5px',
};
let preliminaryStyle = {
  color: 'white',
  backgroundColor: 'green',
  padding: '5px',
  borderRadius: '5px',
};
let trainingStyle = {
  color: 'white',
  backgroundColor: 'black',
  padding: '5px',
  borderRadius: '5px',
};
let noviceStyle = {
  color: 'black',
  backgroundColor: 'white',
  padding: '5px',
  borderRadius: '5px',
};
let beginnerNoviceStyle = {
  color: 'black',
  backgroundColor: 'yellow',
  padding: '5px',
  borderRadius: '5px',
};
let starterStyle = {
  color: 'white',
  backgroundColor: 'dodgerBlue',
  padding: '5px',
  borderRadius: '5px',
};
let defaultStyle = {
  color: 'black',
  backgroundColor: 'grey',
  padding: '5px',
  borderRadius: '5px',
};
let allStyle = {
  color: 'black',
  backgroundColor: 'grey',
  padding: '5px',
  borderRadius: '5px',
};

class JumpPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleBoolChanged = this.handleBoolChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.setLevel = this.setLevel.bind(this);
    this.getJumpList = this.getJumpList.bind(this);
    this.state = {
      portable: false,
      skinny: false,
      needsRepair: false,
      category: 'N/A',
      level: 'N/A',
      descriptionSearch: '',
      jumpNumberSearch: '',
      loading: false,
      showSearchOptions: false,
    };
  }

  handleChange = (e) => {
    if (e.target) {
      this.setState({ [e.target.id]: e.target.value });
    }
  };

  componentDidMount = () => {
    this.props.fetchJumps();
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  getJumpList() {
    let jumpArray = _.values(this.props.jumps);
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.jumpNumberSearch !== '') {
        return jump.jumpNumber.toString() === this.state.jumpNumberSearch;
      } else return true;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.descriptionSearch !== '') {
        return jump.description
          .toUpperCase()
          .includes(this.state.descriptionSearch.toUpperCase());
      } else return true;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.skinny) {
        return jump.skinny;
      } else return true;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.category === 'N/A') {
        return true;
      } else return jump.category === this.state.category;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.level === 'N/A') {
        return true;
      } else return jump.level === this.state.level;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.portable) {
        return jump.portable;
      } else return true;
    });
    jumpArray = _.filter(jumpArray, (jump) => {
      if (this.state.needsRepair) {
        return jump.needsRepair;
      } else return true;
    });
    return _.mapKeys(jumpArray, '_id');
  }

  renderAddButton() {
    if (this.props.auth) {
      return (
        <LinkContainer to={'/jumps/new'}>
          <Button variant="primary">
            <PlusCircle />
          </Button>
        </LinkContainer>
      );
    }
  }

  renderNeedsRepair() {
    if (this.props.auth) {
      return boolField('needsRepair', 'Needs Repair', this);
    }
  }

  setLevel(level) {
    this.setState({ level });
  }

  renderLevelButton(style, level) {
    return (
      <button style={style} onClick={() => this.setLevel(level)}>
        {level}
      </button>
    );
  }

  renderLevelButtons() {
    return (
      <div>
        {this.renderLevelButton(intermediateStyle, 'Intermediate')}{' '}
        {this.renderLevelButton(preliminaryStyle, 'Preliminary')}{' '}
        {this.renderLevelButton(trainingStyle, 'Training')}{' '}
        {this.renderLevelButton(noviceStyle, 'Novice')}{' '}
        {this.renderLevelButton(beginnerNoviceStyle, 'Beginner Novice')}{' '}
        {this.renderLevelButton(starterStyle, 'Starter')}{' '}
        {this.renderLevelButton(defaultStyle, 'Other')}{' '}
        {this.renderLevelButton(allStyle, 'All')}
      </div>
    );
  }

  render() {
    return (
      <Container fluid>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >Jumps</Card.Title>
              </Card.Header>
              <Card.Body>
                <ButtonToolbar>
                  {this.renderAddButton()}
                  {'    '}
                  <Button
                    variant="fab"
                    color="primary"
                    onClick={() =>
                      this.setState({
                        showSearchOptions: !this.state.showSearchOptions,
                      })
                    }
                  >
                    Search Options
                  </Button>
                </ButtonToolbar>
                <Collapse in={this.state.showSearchOptions}>
                  <div>
                    {textField(
                      'jumpNumberSearch',
                      'Search By Jump Number',
                      this
                    )}
                    {textField(
                      'descriptionSearch',
                      'Search by Description',
                      this
                    )}
                    {boolField('portable', 'Portable', this)}
                    {boolField('skinny', 'Skinny', this)}
                    {stringSelector('category', 'Category', this, categories)}
                    {stringSelector('level', 'Level', this, levels)}
                    {this.renderNeedsRepair()}
                  </div>
                </Collapse>
              </Card.Body>
            </Card>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >My Jumps</Card.Title>
                {this.renderLevelButtons()}
              </Card.Header>
              <Card.Body>
                <JumpList jumps={this.getJumpList()} auth={this.props.auth} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps({ jumps, auth }) {
  return { jumps, auth };
}

export default connect(mapStateToProps, { fetchJumps })(JumpPage);
