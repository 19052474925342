/* AUTO GEN */
/* Media.js */
import { photosToClassArray } from './Photo';

export class Media {
    constructor(
      id, 
      userId, 
      content, 
      type, 
      dataStatus, 
      createdAt, 
      updatedAt,
      photos
    ) {
      this.id = id;
      this.userId = userId;
      this.content = content;
      this.type = type;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      if(photos) {
        this.photos = photosToClassArray(photos);
      }
    }
  }
  
  export function mediaToClass(media) {
    if (media) {
      return new Media(
        media.id, 
        media.userId, 
        media.content, 
        media.type, 
        media.dataStatus, 
        media.createdAt, 
        media.updatedAt,
        media.photos
      );
    } else {
      return null;
    }
  }
  
  export function mediasToClassArray(medias) {
    if (medias) {
      const mediasArray = medias.map((item) => {
        return mediaToClass(item);
      });
      return mediasArray;
    }
  }
  
  