import React from 'react';
import OrderOfRidesRow from './OrderOfRidesRow';

import styled from '@emotion/styled';
import {
  Card as MuiCard,
  CardHeader,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function OrderedTimesTable(props) {
  function tableRows() {
    var i = 0;
    if (props.rideTimes) {
      return props.rideTimes.map((rideTime) => {
        i++;
        return (
          <OrderOfRidesRow
            rideTime={rideTime}
            key={i}
          />
        );
      });
    }
  }
  return (
    <Card mb={6}>
      <CardHeader title="Ordered Ride Times" />
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Rider</TableCell>
                <TableCell>Horse</TableCell>
                <TableCell>Division</TableCell>
                <TableCell>Phase</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Diff</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows()}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}
