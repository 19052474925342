/* AUTO GEN */
/* ProductCategory.js */
export class ProductCategory {
  constructor(
    id, 
    parentCategoryId, 
    categoryName, 
    parentCategoryName,
    dataStatus
  ) {
    this.id = id;
    this.parentCategoryId = parentCategoryId;
    this.categoryName = categoryName;
    this.parentCategoryName = parentCategoryName;
    this.dataStatus = dataStatus;
  }

  sku() {
    return this.id.toString().padStart(3, '0');
  }
}

export function productCategoryToClass(productCategory) {
  if (productCategory) {
    return new ProductCategory(
      productCategory.id, 
      productCategory.parentCategoryId, 
      productCategory.categoryName, 
      productCategory.parentCategoryName, 
      productCategory.dataStatus
    );
  } else {
    return null;
  }
}

export function productCategoriesToClassArray(productCategories) {
  if (productCategories) {
    const productCategoriesArray = productCategories.map((item) => {
      return productCategoryToClass(item);
    });
    return productCategoriesArray
  }
}

