/* AUTO GEN */
/* community_invitesReducer.js */
import {
  SET_COMMUNITY_INVITES,
  COMMUNITY_INVITE_FETCHED,
} from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMUNITY_INVITE_FETCHED:
      return { ...state, [action.communityInvite.id]: action.communityInvite };
    case SET_COMMUNITY_INVITES:
      const newPosts = _.mapKeys(action.communityInvites, 'id');
      return { ...state, ...newPosts };
    default:
      return state;
  }
}
