import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { rgba } from 'polished';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {
  Box,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Chip as MuiChip,
  Divider,
  List,
  ListItem,
  Typography as MuiTypography,
  Stack,
} from '@mui/material';
import { spacing } from '@mui/system';

import DressageListChip from './DressageListChip';
import DressageEntryChip from './DressageEntryChip';

const illustrationCardStyle = (props) => css`
  ${props.illustration &&
  props.theme.palette.mode !== 'dark' &&
  `
    background: ${rgba(props.theme.palette.primary.main, 0.125)};
    color: ${props.theme.palette.primary.main};
  `}
`;

const Card = styled(MuiCard)`
  position: relative;
  margin-bottom: ${(props) => props.theme.spacing(6)};

  ${illustrationCardStyle}
`;

const Typography = styled(MuiTypography)(spacing);

const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(1)};
  }
`;

const Chip = styled(MuiChip)`
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

function getStadiumLabel(test, divisionEntries) {
  if (test) {
    var scheduledCount = 0;
    divisionEntries.forEach((element) => {
      if (element.rideTimes && element.rideTimes['Stadium']) {
        scheduledCount++;
      }
    });
    return 'Stadium (' + divisionEntries.length + ' : ' + scheduledCount + ')';
  } else {
    return '';
  }
}

function getStadiumChip(test, divisionEntries) {
  if (test) {
    return (
      <Chip label={getStadiumLabel(test, divisionEntries)} color="warning" />
    );
  } else {
    return '';
  }
}

function getXCLabel(test, divisionEntries) {
  if (test) {
    var scheduledCount = 0;
    divisionEntries.forEach((element) => {
      if (element.rideTimes && element.rideTimes['XC']) {
        scheduledCount++;
      }
    });
    return 'XC (' + divisionEntries.length + ' : ' + scheduledCount + ')';
  } else {
    return '';
  }
}

function getXCChip(test, divisionEntries) {
  if (test) {
    return <Chip label={getXCLabel(test, divisionEntries)} color="success" />;
  } else {
    return '';
  }
}

const EventDivisionStats = ({ eventDivision, divisionEntries }) => {

    function getDressageAccordionItems() {
      if (divisionEntries) {
        return divisionEntries.map((element) => (
          <React.Fragment key={element.id} >
            <ListItem>
              <DressageEntryChip entry={element} eventDivision={eventDivision} />
            </ListItem>
            <Divider
              sx={{
                backgroundColor: 'white',
              }}
            />
          </React.Fragment>
        ));
      }
    }

    function getDressageAccordion(test, divisionEntries) {
      if (test && divisionEntries) {
        return (
          <Accordion
            sx={{
              backgroundColor: '#424949',
            }}
          >
            <AccordionSummary
              aria-label="dressage entries"
              expandIcon={<ExpandMoreIcon />}
              id="dressage-header"
            >
            <DressageListChip eventDivision={eventDivision} divisionEntries={divisionEntries} color="error" />
           </AccordionSummary>
            <AccordionDetails>
              <List>
                <Divider
                  sx={{
                    backgroundColor: 'white',
                  }}
                />
                {getDressageAccordionItems()}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        return '';
      }
    }

  function getScheduledBadges() {
    if (eventDivision.tests && divisionEntries) {
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={2}
        >
          {/* {getDressageTree(eventDivision.tests[0], divisionEntries)} */}
          {getDressageAccordion(eventDivision.tests[0], divisionEntries)}
          {getStadiumChip(eventDivision.tests[1], divisionEntries)}
          {getXCChip(eventDivision.tests[2], divisionEntries)}
        </Stack>
      );
    } else {
      return <Chip label="No Tests" color="secondary" />;
    }
  }

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        width: 400,
        '&:hover': {
          boxShadow: 'md',
          borderColor: 'neutral.outlinedHoverBorder',
        },
        display: 'flex',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography variant="h6" mb={4}>
            {eventDivision.name}
          </Typography>
        </CardContent>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'left', pl: 1, pb: 1 }}>
        {getScheduledBadges()}
      </Box>
    </Card>
  );
};

export default EventDivisionStats;
