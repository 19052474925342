import React, { useState } from 'react';
import MinuteDroppable from '../MinuteDroppable';
import { DressageBox } from './DressageBox';
import { useDispatch } from 'react-redux';

import {
  updateEntryTimes,
  updateEntryTimesArray,
} from '../../../redux/actions/Events/entryActions';

export default function ScheduleRing({ ring, entries, onSelectEntry }) {
  const [selectedEntry, setSelectedEntry] = useState(null);
  // const tmpEventId = 115;

  const dispatch = useDispatch();

  // var minuteBoxes = [];

  var minutes = createMinuteData(ring);

  function createMinuteData() {
    var minutes = [];
    const durationMinutes = ring.duration * 60;
    var startTime = new Date(Date.parse(ring.startTime));
    for (let i = 0; i < durationMinutes; i++) {
      const newDateTime = new Date(startTime);
      newDateTime.setMinutes(startTime.getMinutes() + i);
      minutes.push({ id: i, time: newDateTime, visible: true });
    }
    return minutes;
  }

  function blockMinutes(entry, adding) {
    let entryNext = 0;
    const entryTime = new Date(entry.rideTimes.dressage.time);
    if (minutes) {
      minutes.forEach((minute) => {
        const minuteTime = new Date(minute.time);
        if (entryNext > 0) {
          minute.visible = !adding;
          entryNext--;
        }
        if (minuteTime.getTime() === entryTime.getTime()) {
          if (adding) {
            minute.entry = entry;
          } else {
            minute.entry = null;
          }
          entryNext = entry.rideTimes.dressage.minutes - 1;
        }
      });
    }
  }

  // function getNextCoveredBlock(minuteId, duration, currentId) {
  //   for (var i = 0; i < duration; i++) {
  //     let minute = minutes[minuteId + i];
  //     if (minute != null) {
  //       if (minute.block && minute.block.id !== currentId) {
  //         let returnBlock = minute.block;
  //         minute.block = null;
  //         return returnBlock;
  //       }
  //     }
  //   }
  // }

  // function doBlockMoved(minuteId, item, toTime, ringId) {
  //   var arrayToUpdate = [];
  //   let currentBlock = _.find(scheduleBlocks, { id: item.blockId });
  //   let newDate = new Date(toTime);
  //   arrayToUpdate.push({ startTime: newDate, id: item.blockId });
  //   var coveredBlock = getNextCoveredBlock(
  //     minuteId,
  //     item.duration,
  //     item.blockId
  //   );
  //   if (coveredBlock) {
  //     let nextDate = new Date(
  //       newDate.getTime() + currentBlock.duration * 60000
  //     );
  //     arrayToUpdate.push({ startTime: nextDate, id: coveredBlock.id });
  //   }
  //   minuteBoxes = null;
  //   dispatch(updateBlocks({ dataArray: arrayToUpdate, eventId: tmpEventId }));
  // }

  // function containsId(id, arrayToSearch) {
  //   var foundItem = _.find(arrayToSearch, { id: id });
  //   return foundItem != null;
  // }

  // function getBlocksCoveredByArray(dataArray) {
  //   // entry.rideTimes.dressage = {
  //   //   time: nextDate,
  //   //   ringId: ringId,
  //   //   minutes: test.minutes,
  //   //   testName: test.name,
  //   //   testType: test.type,
  //   // };
  //   var returnArray = [];
  //   if (entries) {
  //     entries.forEach((entry) => {
  //       if (!containsId(entry.id, dataArray)) {
  //         // containsId = Already moved block to a time.
  //         // Not moving same block
  //       }
  //     });
  //   }
  //   return returnArray;
  // }

  function collisionTest(id, newTime, minutes) {
    var entryTime = new Date(newTime);
    var endEntryTime = new Date(entryTime.getTime() + minutes * 59999);
    var returnEntry = null;
    entries.forEach((entry) => {
      if (entry.id !== id) {
        var startTime = new Date(entry.rideTimes.dressage.time);
        var endTime = new Date(
          startTime.getTime() + entry.rideTimes.dressage.minutes * 59999
        );
        // Moving Up
        if (
          entryTime.getTime() >= startTime.getTime() &&
          entryTime.getTime() <= endTime.getTime()
        ) {
          returnEntry = entry;
        }

        // Moving Down
        if (
          endEntryTime.getTime() >= startTime.getTime() &&
          endEntryTime.getTime() <= endTime.getTime()
        ) {
          returnEntry = entry;
        }
      }
    });
    return returnEntry;
  }

  function doEntryTimeChanged(entry, shiftByOne, newTime) {
    // console.log(shift);
    if (shiftByOne) {
      var collidedEntry = collisionTest(
        entry.id,
        newTime,
        entry.rideTimes.dressage.minutes
      );
      // console.log(collidedEntry);
      if (!collidedEntry) {
        var newDate = new Date(newTime);
        entry.rideTimes.dressage.time = newDate;
        dispatch(
          updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
        );
      } else {
        // var newDate = new Date(collidedEntry.rideTimes.dressage.time);
        var updateArray = [];
        collidedEntry.rideTimes.dressage.time = entry.rideTimes.dressage.time;
        entry.rideTimes.dressage.time = newDate;
        updateArray.push({ rideTimes: entry.rideTimes, entryId: entry.id });
        updateArray.push({
          rideTimes: collidedEntry.rideTimes,
          entryId: collidedEntry.id,
        });

        dispatch(
          updateEntryTimesArray({
            dataArray: updateArray,
            eventId: 115,
          })
        );
      }
    } else {
      dispatch(
        updateEntryTimes({ entryId: entry.id, rideTimes: entry.rideTimes })
      );
    }
  }

  function doEntryTimesArrayChanged(dataArray, eventId) {
    dataArray.forEach((block) => {});
    dispatch(
      updateEntryTimesArray({
        dataArray,
        eventId,
      })
    );
  }

  function renderMinuteBoxes() {
    var minuteBoxesNew = [];
    if (entries) {
      entries.forEach((entry) => {
        blockMinutes(entry, true);
      });
    }

    minutes.forEach((minute) => {
      if (minute.visible) {
        minuteBoxesNew.push(renderMinuteDroppable(minute, ring));
      }
    });
    return minuteBoxesNew;
  }

  // function selectEntry(entry)  {
  //   if(selectedEntry) {
  //     selectedEntry.selected = false;
  //   }
  //   entry.selected = true;
  //   setSelectedEntry(entry);
  // }

  function handleBoxClick(value) {
    onSelectEntry(value);
    setSelectedEntry(value);
  }

  function isSelectedEntry(entry) {
    return selectedEntry && entry.id === selectedEntry.id;
  }

  function renderMinuteDroppable(minute, ring) {
    const scheduledEntry = minute.entry ? (
      <DressageBox
        entry={minute.entry}
        ringId={ring.id}
        durationMinutes={minute.entry.rideTimes.dressage.minutes}
        onChangeTime={doEntryTimeChanged}
        onClick={handleBoxClick}
        selected={isSelectedEntry(minute.entry)}
      />
    ) : null;
    return (
      <div
        key={minute.id}
        style={{
          width: '100%',
        }}
      >
        <MinuteDroppable
          minuteId={minute.id}
          time={minute.time}
          ringId={ring.id}
          onEntryTimeChanged={doEntryTimeChanged}
          onEntryTimesArrayChanged={doEntryTimesArrayChanged}
        >
          {scheduledEntry}
        </MinuteDroppable>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '200px',
        height: '800px',
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid gray',
      }}
    >
      {renderMinuteBoxes()}
    </div>
  );
}
