import React, { useCallback, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import { useClickOutside } from './useClickOutside';

export const PopoverPicker = ({ color, onChange }) => {
  const popover = useRef();
  const [isOpen, toggle] = useState(false);

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);
  const presetColors = [
    '#FF0000', // Red
    '#0000FF', // Blue
    '#008000', // Green
    '#FFFF00', // Yellow
    '#00FF00', // Lime
    '#00FFFF', // Aqua
    '#808080', // Gray
    '#C0C0C0', // Silver
    '#FFFFFF', // White
    '#000000', // Black
  ];

  function swatchStyle(bgcolor) {
    return {
      width: '24px',
      height: '24px',
      margin: '4px',
      border: 'none',
      padding: '0',
      borderRadius: '4px',
      cursor: 'pointer',
      outline: 'none',
      backgroundColor: bgcolor,
    };
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          backgroundColor: color,
          width: '28px',
          height: '28px',
          borderRadius: '8px',
          border: '3px solid #fff',
          boxShadow:
            '0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1)',
          cursor: 'pointer',
        }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div
          // style={{
          //     position: absolute, top: calc(100% + 2px),
          //     left: 0,
          //     border-radius: 9px,
          //     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15), }}
          ref={popover}
        >
          <HexColorPicker color={color} onChange={onChange} />
          <div>
            {presetColors.map((presetColor) => (
              <button
                key={presetColor}
                style={swatchStyle(presetColor)}
                onClick={() => onChange(presetColor)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
