import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { initiatePasswordReset } from '../../redux/actions';
import { withRouter } from '../withRouter';
// import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const initialValues = {
  email: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required!"),
});

class InitiateResetPassFormik extends Component {

  onSubmit = (values) => {
    this.props.initiatePasswordReset({
      email: values.email,
    }, this.props.history);
  };

  render() {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.onSubmit}
      >
        <Form>
          <div className="form-control">
            <label htmlFor="email">E-mail</label>
            <Field type="email" id="email" name="email" />
            <ErrorMessage name="email" />
          </div>

          <Button variant="danger" type="submit">Reset Password</Button>
        </Form>
      </Formik>
    );
  }
}

export default connect(null, { initiatePasswordReset })(withRouter(InitiateResetPassFormik));
