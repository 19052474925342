import axios from 'axios';
import { JUMP_FETCHED, SET_JUMPS } from './types';

export function setJumps(jumps) {
  return {
    type: SET_JUMPS,
    jumps,
  };
}

export function jumpFetched(jump) {
  return {
    type: JUMP_FETCHED,
    jump,
  };
}

export function blockFetched(block) {
  return {
    type: JUMP_FETCHED,
    block,
  };
}

export function blocksFetched(blocks) {
  return {
    type: 'BLOCKS_FETCHED',
    blocks,
  };
}

export function blocksFetchedForRing(blocks) {
  console.log('blocks');
  console.log(blocks);
  return {
    type: 'BLOCKS_FETCHED_FOR_RING',
    blocks,
  };
}

export const fetchBlocks = (eventId) => async (dispatch) => {
  const res = await axios.get('/api/blocks/read/grouped/event/' + eventId);
  // const res = await axios.get('/api/blocks/read/event/' + eventId);
  dispatch(blocksFetched(res.data));
};

export const updateSchedule = (values) => async (dispatch) => {
  await axios.post('/api/schedule/update', values);
  // dispatch(jumpFetched(res.data));
};

export const createScheduleBlock = (values) => async (dispatch) => {
  await axios.post('/api/schedule/update', values);
  // dispatch(jumpFetched(res.data));
};



export const updateBlocks = (values) => async (dispatch) => {
  const res = await axios.post('/api/blocks/updateArray', values);
  dispatch(blocksFetched(res.data));
};



export const addNewJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/create', values);
  dispatch(jumpFetched(res.data));
};

export const fetchJumps = () => async (dispatch) => {
  const res = await axios.get('/api/jumps/read');
  dispatch(setJumps(res.data));
};

export const fetchJump = (id) => async (dispatch) => {
  const res = await axios.get('/api/jumps/read/' + id);
  dispatch(jumpFetched(res.data));
};

export const updateJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/update', values);
  dispatch(jumpFetched(res.data));
};

export const addPhotoToJump = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/photos/new', values);
  dispatch(jumpFetched(res.data));
};

export const deletePhoto = (values) => async (dispatch) => {
  const res = await axios.post('/api/jumps/photos/delete', values);
  dispatch(jumpFetched(res.data));
};
