import axios from 'axios';
import {
  FETCH_USER,
  ADD_TRAINER,
  TRAINER_FETCHED,
  SET_TRAINERS,
  SET_TRAINER_DOCUMENTS,
  SET_TRAINER_PHOTOS,
  SET_TRAINER_VIDEOS,
  TRAINER_DOCUMENT_FETCHED,
  TRAINER_PHOTO_FETCHED,
  TRAINER_VIDEO_FETCHED,
} from './types';

export function addTrainer(trainer) {
  return {
    type: ADD_TRAINER,
    trainer,
  };
}

export function trainerFetched(trainer) {
  return {
    type: TRAINER_FETCHED,
    trainer,
  };
}

export function setTrainers(trainers) {
  return {
    type: SET_TRAINERS,
    trainers,
  };
}

export function setTrainerDocuments(trainerDocuments) {
  return {
    type: SET_TRAINER_DOCUMENTS,
    trainerDocuments,
  };
}

export function trainerDocumentFetched(trainerDocument) {
  return {
    type: TRAINER_DOCUMENT_FETCHED,
    trainerDocument,
  };
}

export function setTrainerPhotos(trainerPhotos) {
  return {
    type: SET_TRAINER_PHOTOS,
    trainerPhotos,
  };
}

export function trainerPhotoFetched(trainerPhoto) {
  return {
    type: TRAINER_PHOTO_FETCHED,
    trainerPhoto,
  };
}

export function setTrainerVideos(trainerVideos) {
  return {
    type: SET_TRAINER_VIDEOS,
    trainerVideos,
  };
}

export function trainerVideoFetched(trainerVideo) {
  return {
    type: TRAINER_VIDEO_FETCHED,
    trainerVideo,
  };
}

export const submitTrainer = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/trainers/create', values);
  history.push('/trainers');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchTrainers = () => async (dispatch) => {
  const res = await axios.get('/api/trainers/read');
  dispatch(setTrainers(res.data));
};

export const saveTrainer = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/trainers/create', values);
  dispatch(trainerFetched(res.data));
};

export const fetchTrainer = (id) => async (dispatch) => {
  const res = await axios.get('/api/trainers/read/' + id);
  dispatch(trainerFetched(res.data));
};

export const updateTrainer = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainers/update', values);
  dispatch(trainerFetched(res.data));
};

export const addPhotoToTrainer = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainers/photos/new', values);
  dispatch(trainerPhotoFetched(res.data));
};

export const addVideoToTrainer = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainers/videos/new', values);
  dispatch(trainerVideoFetched(res.data));
};

export const deletePhotoFromTrainer = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainers/photos/delete', values);
  dispatch(trainerFetched(res.data));
};

export const setMainPhotoForTrainer = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainers/photos/setmain', values);
  dispatch(trainerFetched(res.data));
};

export const deleteTrainerVideo = (values) => async (dispatch) => {
  const res = await axios.post('/api/trainervideos/delete', values);
  dispatch(setTrainerVideos(res.data));
};

export const fetchTrainerPhotos = (trainerId) => async (dispatch) => {
  const res = await axios.get('/api/trainers/read/photos/fortrainer/' + trainerId);
  dispatch(setTrainerPhotos(res.data));
};

export const fetchTrainerVideos = (trainerId) => async (dispatch) => {
  const res = await axios.get('/api/trainers/read/videos/fortrainer/' + trainerId);
  dispatch(setTrainerVideos(res.data));
};
