import React from 'react';
import MinuteBox from './MinuteBox';
import { useDrop } from 'react-dnd';
import { ItemTypes } from './Constants';

import _ from 'lodash';

export default function MinuteDroppable({
  minuteId,
  time,
  ringId,
  onEntryTimeChanged,
  onEntryTimesArrayChanged,
  children,
}) {
  
  function moveScheduleBox(item, toTime, ringId) {
    if (item && item.type === ItemTypes.ENTRY_LIST) {
      setDressageTimes(item, toTime, ringId);
    } else if (item && item.type === ItemTypes.DRESSAGE_ENTRY) {
      setDressageTime(item, toTime, ringId);
      onEntryTimeChanged(item.entry);
    }
  }

  function getDressageTestFromDivision(eventDivision) {
    if (eventDivision && eventDivision.tests) {
      return _.find(eventDivision.tests, function (test) {
        if (test.type === 0) {
          return true;
        }
      });
    } else {
      return null;
    }
  }

  function setDressageTimes(item, toTime, ringId) {
    if (
      item &&
      item.divisionEntries &&
      item.eventDivision &&
      item.divisionEntries.length > 0
    ) {
      var arrayToUpdate = [];
      var test = getDressageTestFromDivision(item.eventDivision);
      let newDate = new Date(toTime);
      var offset = 0;
      item.divisionEntries.forEach((entry) => {
        let nextDate = new Date(newDate.getTime() + offset * 60000);
        entry.rideTimes.dressage = {
          time: nextDate,
          ringId: ringId,
          minutes: test.minutes,
          testName: test.name,
          testType: test.type,
        };
        offset = offset + test.minutes;
        arrayToUpdate.push({ rideTimes: entry.rideTimes, entryId: entry.id });
      });
      if (arrayToUpdate.length > 0) {
        onEntryTimesArrayChanged(arrayToUpdate, item.eventDivision.eventId);
      }
    }
  }

  function setDressageTime(item, toTime, ringId) {
    if (
      item &&
      item.entry &&
      item.entry.eventDivision &&
      item.entry.rideTimes.dressage
    ) {
      var test = getDressageTestFromDivision(item.entry.eventDivision);
      let newDate = new Date(toTime);
      item.entry.rideTimes.dressage = {
        time: newDate,
        ringId: ringId,
        minutes: test.minutes,
        testName: test.name,
        testType: test.type,
      };
    } else if (item && item.entry && item.eventDivision) {
      var test2 = getDressageTestFromDivision(item.eventDivision);
      if (test2) {
        if (!item.entry.rideTimes) {
          item.entry.rideTimes = {};
        }
        item.entry.eventDivision = item.eventDivision;
        let newDate = new Date(toTime);
        item.entry.rideTimes.dressage = {
          time: newDate,
          ringId: ringId,
          minutes: test2.minutes,
          testName: test2.name,
          testType: test2.type,
        };
      }
    }
  }

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [
        ItemTypes.SCHEDULE_BOX,
        ItemTypes.ENTRY_LIST,
        ItemTypes.DRESSAGE_ENTRY,
      ],
      drop: (item) => moveScheduleBox(item, time, ringId),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [time]
  );

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <MinuteBox minuteBoxId={minuteId} time={time}>
        {children}
      </MinuteBox>
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'yellow',
          }}
        />
      )}
    </div>
  );
}
