/* AUTO GEN */
/* peopleReducer.js */
import { USER_ENTRIES_FETCHED, USER_ENTRY_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

const userEntriesReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_ENTRY_FETCHED:
      var newArray = state.filter((item) => item.id !== action.userEntry.id);
      newArray.push(action.userEntry);
      return newArray;
    case USER_ENTRIES_FETCHED:
      return action.userEntries;
    default:
      return state;
  }
};

export default userEntriesReducer;
