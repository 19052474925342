/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchTrainer,
  updateTrainer,
} from '../../../redux/actions/trainerActions';
import { redirect } from 'react-router';
import TrainerForm from './TrainerForm';
import { Container } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TrainerFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchTrainer(this.props.match.params.id);
    }
  };

  saveTrainer = ({
    id,
    bio,
    trainingPhilosophy,
    experience,
    services,
    photoURL,
    active,
  }) => {
    if (id) {
      return this.props
        .updateTrainer({
    id,
    bio,
    trainingPhilosophy,
    experience,
    services,
    photoURL,
    active,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    } 
  };

  render() {
    return (
      <Container fluid>
        {this.state.redirect ? (
          redirect("/account/trainer")
        ) : (
          <TrainerForm
            trainer={this.props.trainer}
            saveTrainer={this.saveTrainer}
            auth={this.props.auth}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (state.auth) {
    return {
      trainer: state.trainers[state.auth.id],
      auth: state.auth,
    };
  }

  return {
    trainer: null,
    auth: state.auth,
  };
}

export default connect(
  mapStateToProps,
  { fetchTrainer,  updateTrainer }
)(TrainerFormPage);
