import React from 'react';
import {
  prelimMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
} from '../../core/MapTools';

export default function XCCourseInfoTableRow(props) {

  function getCourseMapButton(levelIndex) {
    if (levelIndex === 0)
      return prelimMapButton();
    if (levelIndex === 1)
      return trainingMapButton();
    if (levelIndex === 2)
      return noviceMapButton();
    if (levelIndex === 3)
      return begNoviceMapButton();
    if (levelIndex === 4)
      return starterMapButton();
    return '';
  }

  return (
    <tr key={props.key} >
      <td>
        {getCourseMapButton(props.xcCourse.level)}
      </td>
      <td>
        {props.xcCourse.lengthMeters}
      </td>
      <td>
        {props.xcCourse.optimumSpeed}
      </td>
      <td>
        {props.xcCourse.optimumTime.replaceAll('"', '')}
      </td>
      <td>
        {props.xcCourse.faultTime.replaceAll('"', '')}
      </td>
    </tr>
  );
}
