import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { Sunglasses } from 'react-bootstrap-icons';
import HelmetTracker from '../core/HelmetTracker';

const cardTheme = 'success';
const buttonTheme = 'dark';

export default function Schooling() {
  return (
    <>
      <HelmetTracker title="Schooling" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant={cardTheme}>
            <ListGroup>
              <ListGroupItem>
                <h3>
                  <Sunglasses />
                  &nbsp;&nbsp;Schooling
                </h3>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Facility Maps</Card.Title>
            </Card.Header>
            <Card.Body>
              <Button glyph="globe" href="/maps" variant={buttonTheme}>
                Click to see facility Maps
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Grounds Rules at Full Gallop</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  Riders MUST sign release EACH TIME they school and must be
                  signed BEFORE they ride! (Release and payment box is located
                  in the office in the middle of Barn A next to the main wash
                  racks)
                </ListGroupItem>
                <ListGroupItem>
                  No lone riders: Anyone riding on the XC course must be
                  accompanied by another person. The other person may be on
                  foot.
                </ListGroupItem>
                <ListGroupItem>
                  Cross Country vest required to school XC (Not JUST an Air
                  Vest)
                </ListGroupItem>
                <ListGroupItem>No Smoking</ListGroupItem>
                <ListGroupItem>No Dogs</ListGroupItem>
                <ListGroupItem>
                  Children must be supervised at all times
                </ListGroupItem>
                <ListGroupItem>
                  No motorized vehicles on the XC Course
                </ListGroupItem>
                <ListGroupItem>No standing martingales</ListGroupItem>
                <ListGroupItem>No abusive equipment</ListGroupItem>
                <ListGroupItem>
                  To preserve footing, no more than three attempts are allowed
                  at any one jump or combination.
                </ListGroupItem>
                <ListGroupItem>
                  Only equipment that is USEA/USEF approved can be used.
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Full Gallop Schooling Rates</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card className="mb-3">
                <Card.Header>
                  <Card.Title>
                    Full Schooling - $50 per horse includes:
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>Full use of XC Course</ListGroupItem>
                    <ListGroupItem>Stadium Schooling</ListGroupItem>
                    <ListGroupItem>Dressage Schooling</ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card className="mb-3">
                <Card.Header>
                  <Card.Title>
                    Stadium Schooling - $50 per horse includes:
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>Stadium Schooling</ListGroupItem>
                    <ListGroupItem>Dressage Schooling</ListGroupItem>
                  </ListGroup>
                </Card.Body>
                <Card.Footer>
                  * MUST contact us ahead of time for approval to use Stadium
                  arena. Certain limits will apply.
                </Card.Footer>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Dressage Schooling - $25 per horse includes:
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>Dressage Schooling</ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer>
              * Please contact us ahead of time if you wish to school XC so we
              can ensure the waters are filled for your use. We will only call
              you back if there is a problem. If we DO NOT call you back, plan
              to come school! :)
            </Card.Footer>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Additional Rates</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card className="mb-3">
                <Card.Header>
                  <Card.Title>Stall Rates</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>$50 for first night</ListGroupItem>
                    <ListGroupItem>$25 every additional night</ListGroupItem>
                    <ListGroupItem>$450 per month</ListGroupItem>
                    <ListGroupItem>
                      Use of a stall for the day $25 (Not overnight)
                    </ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>Hookups</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>$50 per night</ListGroupItem>
                    <ListGroupItem>$450 per month</ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer>
              <Card.Title as="h4">
                Please contact us for any additional needs
              </Card.Title>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}
