import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, Edit } from 'react-feather';

import {
  fetchEventHorses,
  //   updatePropertiesEventDivision,
} from '../../../redux/actions/Events/eventHorseActions';
import { useDispatch, useSelector } from 'react-redux';
import EventHorseForm from '../forms/EventHorseForm';

import {
  Button,
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary'].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

function EventHorses() {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  //   const [addingTest, setAddingTest] = React.useState(false);
  // const [selected, setSelected] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEventHorses());
  }, [dispatch]);

  const eventHorses = useSelector((state) => state.eventHorses);
  const selectedHorse = useSelector((state) =>
    state.eventHorses.find((x) => x.id === selected)
  );

  function genderToChip(type) {
    if (type === 0) {
      return <Chip label="Unassigned" color="warning" />;
    } else if (type === 1) {
      return <Chip label="Gelding" color="info" />;
    } else if (type === 2) {
      return <Chip label="Mare" color="error" />;
    } else if (type === 3) {
      return <Chip label="Stallion" color="success" />;
    } else {
      return '';
    }
  }

  const handleEditClick = (event, id) => {
    setSelected(id);
    setEditing(!editing);

    // Stops it from selecting Row and showing full edit form.
    event.stopPropagation();
  };

  const handleClick = (event, id) => {
    // if (!addingTest) {
    setSelected(id);
    // }
  };

  const handleAddClick = (event) => {
    setSelected(-1);
    setEditing(false);
    setCreating(!creating);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setEditing(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
    setEditing(false);
  };

  const isSelected = (id) => selected === id;

  function mapBody(eventHorses) {
    if (eventHorses && eventHorses.length > 0) {
      const tableBody = eventHorses.map((horse) => {
        const isItemSelected = isSelected(horse.id);
        return (
          <TableRow
            key={horse.id}
            aria-checked={isItemSelected}
            onClick={(event) => handleClick(event, horse.id)}
            selected={isItemSelected}
          >
            <TableCell component="th" scope="row">
              {horse.id}
            </TableCell>
            <TableCell>{horse.name}</TableCell>
            <TableCell>{horse.breed}</TableCell>
            <TableCell>{horse.color}</TableCell>
            <TableCell>{genderToChip(horse.gender)}</TableCell>
            <TableCell>
              <Stack>
                <Button
                  aria-label="addTest"
                  onClick={(event) => handleEditClick(event, horse.id)}
                  endIcon={<Edit />}
                  variant="outlined"
                  color="success"
                >
                  Edit
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  function showEventHorseForm() {
    if (selected && selected > 0) {
      if (selectedHorse && editing) {
        return (
          <EventHorseForm
            onOkClick={handleFormOkClick}
            onCancelClick={handleFormCancelClick}
            eventHorse={selectedHorse}
          />
        );
      }
    } else if (creating) {
      return (
        <EventHorseForm
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
        />
      );
    }
  }

  return (
    <>
      <Card mb={6}>
        <CardHeader
          action={
            <IconButton
              aria-label="settings"
              size="large"
              onClick={(event) => handleAddClick(event)}
            >
              <PlusCircle />
            </IconButton>
          }
          title="Horses"
        />
        <Paper>
          <TableWrapper>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Breed</TableCell>
                  <TableCell>Color</TableCell>
                  <TableCell>Gender</TableCell>
                  <TableCell>Properties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{mapBody(eventHorses)}</TableBody>
            </Table>
          </TableWrapper>
        </Paper>
        {showEventHorseForm()}
      </Card>
    </>
  );
}

export default EventHorses;
