import React from 'react';
import { TableCell, TableRow } from '@mui/material';

import {
  prelimMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
  prelimOptimumButton,
  trainingOptimumButton,
  noviceOptimumButton,
  begNoviceOptimumButton,
  starterOptimumButton,
} from '../../core/MapTools';

export default function SelectedRidesRow(props) {

  function getCourseMapButton(rideTimes) {
    if (rideTimes.division === 'P') return prelimMapButton();
    if (rideTimes.division === 'T' || rideTimes.division === 'PT')
      return trainingMapButton();
    if (rideTimes.division === 'N' || rideTimes.division === 'TN')
      return noviceMapButton();
    if (rideTimes.division === 'BN') return begNoviceMapButton();
    if (rideTimes.division === 'ST' || rideTimes.division === 'START')
      return starterMapButton();
    return '';
  }

  function getOptTime(levelIndex) {
    if (props.selectedEvent.xcCourses) {
      var result = props.selectedEvent.xcCourses.find((obj) => {
        return obj.level === levelIndex;
      });
      if (result) return result.optimumTime.replaceAll('"', '');
    }
  }

  function getHref() {
    return '/calendar/xcCourseInfo/' + props.selectedEvent.id;
  }

  function getOptimumTimeXC(rideTimes) {
    if (rideTimes.division === 'P') {
      return prelimOptimumButton(getOptTime(0), getHref());
    }
    if (rideTimes.division === 'T' || rideTimes.division === 'PT')
      return trainingOptimumButton(getOptTime(1), getHref());
    if (rideTimes.division === 'N' || rideTimes.division === 'TN')
      return noviceOptimumButton(getOptTime(2), getHref());
    if (rideTimes.division === 'BN')
      return begNoviceOptimumButton(getOptTime(3), getHref());
    if (rideTimes.division === 'ST' || rideTimes.division === 'START')
      return starterOptimumButton(getOptTime(4), getHref());
    return '';
  }

  return (
    <TableRow key={props.rideTime.id}>
      <TableCell component="th" scope="row">
        {props.rideTime.rider}
      </TableCell>
      <TableCell>{props.rideTime.horse}</TableCell>
      <TableCell>{props.rideTime.division}</TableCell>
      <TableCell>{props.rideTime.dressageTime}</TableCell>
      <TableCell>{props.rideTime.stadiumTime}</TableCell>
      <TableCell>{props.rideTime.xcTime}</TableCell>
      <TableCell>{getCourseMapButton(props.rideTime)}</TableCell>
      <TableCell>{getOptimumTimeXC(props.rideTime)}</TableCell>
    </TableRow>
  );
}
