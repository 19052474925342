import axios from 'axios';
import {
  FETCH_USER,
  HORSE_FETCHED,
  SET_HORSES,
} from './types';
import { horseToClass, horsesToClassArray } from '../classes/Horse';

export function horseFetched(horse) {
  return {
    type: HORSE_FETCHED,
    horse: horseToClass(horse),
  };
}

export function setHorses(horses) {
  return {
    type: SET_HORSES,
    horses: horsesToClassArray(horses),
  };
}

export const submitHorse = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/horses/create', values);
  history.push('/horses');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchHorses = () => async (dispatch) => {
  const res = await axios.get('/api/horses/read');
  dispatch(setHorses(res.data));
};

export const updateProperties = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/update/properties', values);
   dispatch(fetchHorse(res.data.id));
};

export const fetchHorse = (id) => async (dispatch) => {
  const res = await axios.get('/api/horses/readCustom/' + id);
  dispatch(horseFetched(res.data));
};

export const createHorse = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/horses/create', values);
  dispatch(fetchHorse(res.data.id));
};

export const updateHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/update', values);
  dispatch(fetchHorse(res.data.id));
};

export const updateFeed = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/update/feed', values);
  dispatch(horseFetched(res.data));
};

export const addFarrier = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/farriers/add', values);
  dispatch(horseFetched(res.data));
};

export const addDocToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/docs/upload', values);
  dispatch(fetchHorse(res.data.id));
};

export const addPhotoToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/new/multi', values);
  dispatch(fetchHorse(res.data.id));
};

export const addVideoToHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/videos/new', values);
  dispatch(fetchHorse(res.data.id));
};

export const setMainPhotoForHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/setmain', values);
  dispatch(fetchHorse(res.data.id));
};

export const deleteHorsePhoto = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/photos/delete', values);
  dispatch(fetchHorse(res.data.id));
};

export const deleteHorseVideo = (values) => async (dispatch) => {
  const res = await axios.post('/api/horses/videos/delete', values);
  dispatch(fetchHorse(res.data.id));
};
