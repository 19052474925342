import React from 'react';
import moment from 'moment';

export function datesToText(startDate, endDate, oneDay) {
  if (oneDay) {
    return <div>{dateToText(startDate)}</div>;
  }
  return (
    <div>
      <p>{dateToText(startDate)}</p>
      to
      <p>{dateToText(endDate)}</p>
    </div>
  );
}

export function dateToText(date) {
  return moment(date).utc().format('M/D/YY');
}

export function dateTimeToText(date) {
  return moment(date).utc().format('M/D/YY - hh:mm');
}

export function dateToTime(date) {
  return moment(date).utc().format('hh:mm');
}

export function last7Days() {
  var returnArray = [];
  returnArray.push(moment().subtract(6, 'days').format('M/D/YY'));
  returnArray.push(moment().subtract(5, 'days').format('M/D/YY'));
  returnArray.push(moment().subtract(4, 'days').format('M/D/YY'));
  returnArray.push(moment().subtract(3, 'days').format('M/D/YY'));
  returnArray.push(moment().subtract(2, 'days').format('M/D/YY'));
  returnArray.push(moment().subtract(1, 'days').format('M/D/YY'));
  returnArray.push(moment().format('M/D/YY'));
  return returnArray;
}
