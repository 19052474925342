/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  fetchMedia,
  fetchMedias,
  createMedia,
  updateMedia,
  deleteMedia,
  addMediaWithPhotos,
} from '../../../redux/actions/mediaActions';
import { redirect } from 'react-router';
import MediaForm from './core/MediaForm';
import { Container } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class MediaFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    if (this.props.match && this.props.match.params.id) {
      this.props.fetchMedia(this.props.match.params.id);
      this.props.fetchMedias();
    }
  };

  saveMedia = ({ id, content, type }) => {
    if (id) {
      return this.props
        .updateMedia({
          id,
          content,
          type,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    } else {
      return this.props
        .createMedia({
          content,
          type,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  doDeleteMedia = ({ id }) => {
    if (id) {
      return this.props
        .deleteMedia({
          id,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  doAddMediaWithPhotos = ( data ) => {
    console.log('data :>> ', data);
    if (data) {
      return this.props.addMediaWithPhotos(data).then(() => {
        this.setState({ redirect: true });
      });
    }
  };

  render() {
    return (
      <Container fluid>
        {this.state.redirect ? (
          redirect("/media")
        ) : (
          <MediaForm
            media={this.props.media}
            saveMedia={this.saveMedia}
            auth={this.props.auth}
            deleteMediaCall={this.doDeleteMedia}
            addMediaWithPhotosCall={this.doAddMediaWithPhotos}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match && props.match.params.id) {
    return {
      media: state.medias[props.match.params.id],
      medias: state.medias,
      auth: state.auth,
    };
  }

  return {
    media: null,
    medias: state.medias,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  fetchMedia,
  fetchMedias,
  createMedia,
  updateMedia,
  deleteMedia,
  addMediaWithPhotos,
})(MediaFormPage);
