import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { PatchQuestion } from 'react-bootstrap-icons';
import CalendarEntryStatusTable from '../core/CalendarEntryStatusTable';

class CalendarEntryStatus extends Component {
  render() {
    return (
      <div>
        <Alert>
          <h2>
            <PatchQuestion />
            &nbsp; Entry Status{' '}
          </h2>
          <h4> You are welcome here at Full Gallop</h4>
        </Alert>
        <CalendarEntryStatusTable />
      </div>
    );
  }
}

export default CalendarEntryStatus;
