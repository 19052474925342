/* (( *** AUTO GEN *** )) */
/* oculusEventActions.js */
import axios from 'axios';
// import { SET_OCULUS_EVENTS, OCULUS_EVENT_FETCHED } from './types';

// export function oculusEventFetched(oculusEvent) {
//   return {
//     type: OCULUS_EVENT_FETCHED,
//     oculusEvent,
//   };
// }

// export function setOculusEvents(oculusEvents) {
//   return {
//     type: SET_OCULUS_EVENTS,
//     oculusEvents,
//   };
// }

// export const fetchOculusEvent = (id) => async (dispatch) => {
//   const res = await axios.get('/api/oculusevents/read/' + id);
//   dispatch(oculusEventFetched(res.data));
// };
// export const fetchOculusEventsById = (id) => async (dispatch) => {
//   const res = await axios.get('/api/oculusevents/read/' + id);
//   dispatch(setOculusEvents(res.data));
// };

export const createOculusEvent = (values) => async () => {
  axios.post('/api/oculusevents/create', values).catch(function (error) {
    console.log(error.message);
  });
  //   dispatch(oculusEventFetched(res.data));
};

// export const updateOculusEvent = (values) => async (dispatch) => {
//   const res = await axios.post('/api/oculusevents/update', values);
//   dispatch(oculusEventFetched(res.data));
// };

// export const deleteOculusEvent = (values) => async (dispatch) => {
//   const res = await axios.post('/api/oculusevents/update', values);
//   dispatch(setOculusEvents(res.data));
// };
