/* eslint-disable no-unused-vars */
import React from 'react';

/* eslint-enable no-unused-vars */

export default function EntryStatusTableRow(props) {

  return (
    <tr key={props.key}>
      <td>
        {props.entryStatus.rider}
      </td>
      <td>
        {props.entryStatus.horse}
      </td>
      <td>
        {props.entryStatus.division}
      </td>
      <td>
        {props.entryStatus.status}
      </td>
      <td>
        {props.entryStatus.balanceDue}
      </td>
      <td>
        {props.entryStatus.depositBalance}
      </td>
      <td>
        {props.entryStatus.missing}
      </td>
      <td>
        {props.entryStatus.incomplete}
      </td>
      <td>
        {props.entryStatus.stabling}
      </td>
      <td>
        {props.entryStatus.group}
      </td>
    </tr>
  );
}
