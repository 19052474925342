import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchMediaFeed } from '../redux/actions/mediaActions';
import { Card, Col, Container, Button } from 'react-bootstrap';
import { Cart } from 'react-bootstrap-icons';
import { LinkContainer } from 'react-router-bootstrap';
import MediaFeed from './media/MediaFeed';
import AddMedia from './media/AddMedia';
import HelmetTracker from './core/HelmetTracker';

export default function Landing() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMediaFeed());
  }, [dispatch]);

  function getSaleHorseButton() {
    return (
      <LinkContainer to="/sales">
        <Button variant="danger">
          <Cart />
          &nbsp;&nbsp;Shop All Sales Horses
        </Button>
      </LinkContainer>
    );
  }

  return (
    <Container>
      <HelmetTracker title="FGF Home" />
      <Col xs={12} md={12}>
        <Card bg="dark">
          <Card.Header>
            <Card.Title>Welcome to Full Gallop Farm</Card.Title>
          </Card.Header>
          <Card.Body>{getSaleHorseButton()}</Card.Body>
        </Card>
        <Card bg="info">
          <Card.Header>
            <Card.Title>Full Gallop Posts</Card.Title>
          </Card.Header>
          <AddMedia />
          <MediaFeed />
        </Card>
      </Col>
    </Container>
  );
}
