import React from 'react';
import { Card, Row, Col, Container, Button } from 'react-bootstrap';
import HelmetTracker from '../../core/HelmetTracker';

/* MATERIAL UI */
import {
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import UserInfoForm from '../forms/UserInfoForm';
import EventHorses from '../core/EventHorses';
import EventPeople from '../core/EventPeople';
import EventEntries from '../core/EventEntries';

import { useSelector } from 'react-redux';

export function UserPage() {
  const user = useSelector((state) => state.auth);

  return (
    <Container>
      <HelmetTracker title="My Home" />
      <Row>
        <Button>Start Here, {user && user.firstName}</Button>
        <Col xs={12} md={12}>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>1</Avatar>
                <Card.Title>
                  <Box>Check Your Account Info</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Your Basic Info</Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <UserInfoForm />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>2</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Horses</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Horses: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventHorses />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>3</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Riders/People</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>People: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventPeople />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
          <Card bg="light">
            <Card.Header bg="light">
              <Stack direction="row" spacing={5} alignItems="center">
                <Avatar>3</Avatar>
                <Card.Title>
                  <Box>Add (or Edit) Your Entries</Box>
                </Card.Title>
              </Stack>
            </Card.Header>
            <Card.Body>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <Typography>Entries: </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: 'gray',
                  }}
                >
                  <EventEntries />
                </AccordionDetails>
              </Accordion>
            </Card.Body>
          </Card>
          <br></br>
        </Col>
      </Row>
    </Container>
  );
}

export default UserPage;
