import {
    EVENT_TESTS_FETCHED,
    EVENT_TEST_CREATED,
    EVENT_TEST_UPDATED,
  } from '../../actions/types';
  
  const INITIAL_STATE = [];
  
  export default function reduce(state = INITIAL_STATE, action) {
    switch (action.type) {
      case EVENT_TESTS_FETCHED:
        return [...action.eventTests];
      case EVENT_TEST_CREATED:
        return [...action.eventTests, action.eventTest];
      case EVENT_TEST_UPDATED:
        var newState = state.map((item) => {
          if (item.id === action.eventTest.id) {
            return action.eventTest;
          } else return item;
        });
        return [...newState];
      default:
        return state;
    }
  }
  