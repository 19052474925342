/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { fetchJumps } from '../../../redux/actions/jumpActions';
import _ from 'lodash';
import JumpTableRow from './JumpTableRow';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class JumpList extends Component {
  componentDidMount() {
    // this.props.fetchJumps();
  }

  renderJumps() {
    return _.values(this.props.jumps).map((jump) => {
      return <JumpTableRow jump={jump} key={jump.id} auth={this.props.auth} />;
    });
  }

  render() {
    return (
      <Table striped bordered    hover>
        <thead>
          <tr>
            <th>Photo</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>{this.renderJumps()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ jumps, auth }) {
  return { jumps, auth };
}

export default connect(mapStateToProps)(JumpList);

