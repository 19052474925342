/* (( *** AUTO GEN *** )) */
/* mediaActions.js */
import axios from 'axios';
import {
  SET_MEDIAS,
  MEDIA_FETCHED,
  MEDIA_FEED_FETCHED,
  SET_MEDIA_FEED,
} from './types';
import { mediaToClass, mediasToClassArray } from '../classes/Media';

export function mediaFetched(media) {
  return {
    type: MEDIA_FETCHED,
    media,
  };
}

export function mediaFeedFetched(media) {
  return {
    type: MEDIA_FEED_FETCHED,
    media: mediaToClass(media),
  };
}

export function setMedias(medias) {
  return {
    type: SET_MEDIAS,
    medias,
  };
}

export function setMediaFeed(medias) {
  return {
    type: SET_MEDIA_FEED,
    medias: mediasToClassArray(medias),
  };
}

export const fetchMedia = (id) => async (dispatch) => {
  const res = await axios.get('/api/medias/read/' + id);
  dispatch(mediaFetched(res.data));
};

export const fetchMedias = () => async (dispatch) => {
  const res = await axios.get('/api/medias/read');
  dispatch(setMedias(res.data));
};

export const fetchMediaFeed = () => async (dispatch) => {
  const res = await axios.get('/api/medias/feed/read');
  dispatch(setMediaFeed(res.data));
};

export const createMedia = (values) => async (dispatch) => {
  const res = await axios.post('/api/medias/create', values);
  dispatch(mediaFetched(res.data));
  dispatch(mediaFeedFetched(res.data));
};

export const updateMedia = (values) => async (dispatch) => {
  const res = await axios.post('/api/medias/update', values);
  dispatch(setMedias(res.data));
  dispatch(fetchMediaFeed());
};

export const deleteMedia = (values) => async (dispatch) => {
  const res = await axios.post('/api/medias/delete', values);
  dispatch(setMedias(res.data));
};

export const addMediaWithPhotos = (values) => async (dispatch) => {
  const res = await axios.post('/api/medias/photos/new/multi', values);
  dispatch(setMedias(res.data));
};
