import React from 'react';
import { Card, Container, Row, Col, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { verifyAccount } from '../../redux/actions/users/userActions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function Verify() {
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function getID() {
    return '(User ID: ' + searchParams.get('id') + ')';
  }

  function getToken() {
    return '(Token: ' + searchParams.get('token') + ')';
  }

  function verifyAccountNow() {
    console.log('verifyAccountNow');
    dispatch(
      verifyAccount({
        userId: searchParams.get('id'),
        token: searchParams.get('token'),
      }, navigate)
    );
  }

  return (
    <Container>
      <Row className="show-grid">
        <Col xs={12} sm={3} md={4}>
          <Card variant="danger">
            <Card.Header>
              <Card.Title>User Logged In</Card.Title>
            </Card.Header>
            <Card.Body>
              {getID()} <br></br>
              {getToken()}
              <Button onClick={verifyAccountNow}> Click to Verify Now</Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Verify;
