/* (( *** AUTO GEN *** )) */
/* communityInviteActions.js */
import axios from 'axios';
import {
  SET_COMMUNITY_INVITES,
  COMMUNITY_INVITE_FETCHED,
  SET_COMMUNITY_INVITES_TO_ME,
  COMMUNITY_INVITE_TO_ME_FETCHED,
} from '../types';
import { fetchCommunityUsers } from './communityUserActions';

export function communityInviteFetched(communityInvite) {
  return {
    type: COMMUNITY_INVITE_FETCHED,
    communityInvite,
  };
}

export function setCommunityInvites(communityInvites) {
  return {
    type: SET_COMMUNITY_INVITES,
    communityInvites,
  };
}
export function communityInviteToMeFetched(communityInvite) {
  return {
    type: COMMUNITY_INVITE_TO_ME_FETCHED,
    communityInvite,
  };
}

export function setCommunityInvitesToMe(communityInvites) {
  return {
    type: SET_COMMUNITY_INVITES_TO_ME,
    communityInvites,
  };
}

export const fetchCommunityInvite = (id) => async (dispatch) => {
  const res = await axios.get('/api/communityinvites/read/' + id);
  dispatch(communityInviteFetched(res.data));
};

export const fetchCommunityInvites = () => async (dispatch) => {
  const res = await axios.get('/api/communityinvites/read');
  dispatch(setCommunityInvites(res.data));
};

export const fetchCommunityInviteToMe = (id) => async (dispatch) => {
  const res = await axios.get('/api/communityinvites/read/' + id);
  dispatch(communityInviteToMeFetched(res.data));
};

export const fetchCommunityInvitesToMe = () => async (dispatch) => {
  const res = await axios.get(
    '/api/communityinvites/read/tome/byinvitedUserId'
  );
  dispatch(setCommunityInvitesToMe(res.data));
};

export const createCommunityInvite = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityinvites/create', values);
  dispatch(communityInviteFetched(res.data));
};

export const updateCommunityInvite = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityinvites/update', values);
  dispatch(communityInviteFetched(res.data));
};

export const acceptCommunityInvite = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityinvites/accept', values);
  dispatch(setCommunityInvitesToMe(res.data));
  dispatch(fetchCommunityInvites());
  dispatch(fetchCommunityUsers());
};

export const declineCommunityInvite = (values) => async (dispatch) => {
  const res = await axios.post('/api/communityinvites/decline', values);
  console.log(res.data);
  dispatch(setCommunityInvitesToMe(res.data));
};
