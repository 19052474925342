import { SET_JUMPS, JUMP_UPDATED, JUMP_FETCHED } from '../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case JUMP_FETCHED:
      // action.payload === { _id: 'svsdv', jumpName: 'Bob' ... }
      return { ...state, [action.jump.id]: action.jump };
    case SET_JUMPS:
      // action.payload === [ jump1, jump2, jump3 ]
      const newJumps = _.mapKeys(action.jumps, 'id');
      return { ...state, ...newJumps };
    case JUMP_UPDATED:
      // action.payload === { _id: 'svsdv', jumpName: 'Bob' ... }
      return { ...state, [action.jump.id]: action.jump };
    default:
      return state;
  }
}
