/* (( *** AUTO GEN *** )) */
/* eventHorseActions.js */
import axios from 'axios';
import { SET_EVENT_HORSES, EVENT_HORSE_FETCHED } from '../types';

export function eventHorseFetched(eventHorse) {
  return {
    type: EVENT_HORSE_FETCHED,
    eventHorse,
  };
}

export function setEventHorses(eventHorses) {
  return {
    type: SET_EVENT_HORSES,
    eventHorses,
  };
}

export const fetchEventHorse = (id) => async (dispatch) => {
  const res = await axios.get('/api/eventhorses/read/' + id);
  dispatch(eventHorseFetched(res.data));
};

// ALSO FILTERS BY USER ID BUT SERVER SIDE IF LOGGED IN
export const fetchEventHorses = () => async (dispatch) => {
  const res = await axios.get('/api/eventhorses/read');
  dispatch(setEventHorses(res.data));
};

export const fetchEventHorsesByUserId = (userId) => async (dispatch) => {
  const res = await axios.get('/api/eventhorses/read/userId/' + userId);
  dispatch(setEventHorses(res.data));
};

export const createEventHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/eventhorses/create', values);
  dispatch(eventHorseFetched(res.data));
};

export const updateEventHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/eventhorses/update', values);
  dispatch(eventHorseFetched(res.data));
};

export const deleteEventHorse = (values) => async (dispatch) => {
  const res = await axios.post('/api/eventhorses/update', values);
  dispatch(setEventHorses(res.data));
};
