/* AUTO GEN */
/* productCategoriesReducer.js */
import {
  SET_PRODUCT_CATEGORIES,
  PRODUCT_CATEGORY_FETCHED,
} from '../../actions/types';

const INITIAL_STATE = [];

const productCategoriesReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCT_CATEGORY_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.productCategory.id) {
          // Item UPDATED
          itemFound = true;
          return action.productCategory;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.productCategory);
      }
      return [...newState];
    case SET_PRODUCT_CATEGORIES:
      return action.productCategories;
    default:
      return state;
  }
};
export default productCategoriesReducer;
