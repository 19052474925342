/* AUTO GEN */
/* HorseLogItem.js */
export class HorseLogItem {
    constructor(
      id, 
      userId, 
      horseId, 
      log, 
      type, 
      dataStatus,
      createdAt, 
      userName
    ) {
      this.id = id;
      this.userId = userId;
      this.horseId = horseId;
      this.log = log;
      this.type = type;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
      this.userName = userName;
    }
  }
  
  export function horseLogItemToClass(horseLogItem) {
    if (horseLogItem) {
      return new HorseLogItem(
        horseLogItem.id, 
        horseLogItem.userId, 
        horseLogItem.horseId, 
        horseLogItem.log, 
        horseLogItem.type, 
        horseLogItem.dataStatus,
        horseLogItem.createdAt,
        horseLogItem.userName
      );
    } else {
      return null;
    }
  }
  
  export function horseLogItemsToClassArray(horseLogItems) {
    if (horseLogItems) {
      const horseLogItemsArray = horseLogItems.map((item) => {
        return horseLogItemToClass(item);
      });
      return horseLogItemsArray;
    }
  }
  
  