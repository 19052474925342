import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IoMdLogOut } from 'react-icons/io';

function CreateAccount() {
    const navigate = useNavigate();

  function handleClick() {
    navigate('/createaccount');
  }

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      startIcon={<IoMdLogOut />}
    >
      Create Account
    </Button>
  );
}

export default CreateAccount;
