import { SET_HORSES, HORSE_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HORSE_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.horse.id) {
          // Item UPDATED
          itemFound = true;
          return action.horse;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.horse);
      }
      return [...newState];
    case SET_HORSES:
      return action.horses;
    default:
      return state;
  }
}
