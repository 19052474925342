import {
  SET_EVENTS,
  EVENT_FETCHED,
} from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.event.id) {
          // Item UPDATED
          itemFound = true;
          return action.event;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.event);
      }
      return [...newState];
    case SET_EVENTS:
      return action.events;
    default:
      return state;
  }
}

// const INITIAL_STATE = {};

// export default function (state = INITIAL_STATE, action) {
//   switch (action.type) {
//     case EVENT_FETCHED:
//       return { ...state, [action.event.id]: action.event };
//     case EVENT_DOCUMENT_FETCHED:
//       const newEvent = state[action.eventDocument.eventId];
//       console.log('newEvent :>> ', newEvent);
//       if (newEvent) {
//         newEvent.docs.push(action.eventDocument);
//       }
//       return { ...state, [newEvent.id]: newEvent };
//     case SET_EVENTS:
//       // const newPosts = _.mapKeys(action.events, 'id');
//       return { ...state, ...action.events };
//     case EVENT_UPDATED:
//       return { ...state, [action.payload.id]: action.event };
//     default:
//       return state;
//   }
// }
