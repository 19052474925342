import React from 'react';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { MoreVertical } from 'react-feather';
import { useNavigate } from 'react-router-dom';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress as MuiLinearProgress,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const LinearProgress = styled(MuiLinearProgress)`
  height: 14px;
  width: 180px;
  border-radius: 3px;
  background: ${(props) => props.theme.palette.grey[200]};
`;

function getTotalVisits(pageCounts) {
  var returnCount = 0;
  if (pageCounts && pageCounts.length > 0) {
    pageCounts.forEach((pageCount) => {
      returnCount += pageCount.pageCount;
    });
  }
  return returnCount;
}

function PageCountTable({ theme, title, pageCounts }) {
  const navigate = useNavigate();
  const totalVisits = getTotalVisits(pageCounts);

  function percentVisits(count) {
    return (count / totalVisits) * 100;
  }

  function totalTitle() {
    return '% Visits (' + totalVisits + ' Total)';
  }

  function mapBody(pageCounts) {
    var i = 0;
    console.log(pageCounts);
    if (pageCounts && pageCounts.length > 0) {
      const tableBody = pageCounts.map((pageCount) => {
        // function needs to be in here, else trying to pass 
        // object into function means it calls it anyways. 
        function handleClick() {
          if(pageCount) {
            if(pageCount.objectId) {
              navigate('/horses/manage/' + pageCount.objectId);
            }
          } 
        }
        return (
          <TableRow key={i++} onClick={handleClick}>
            <TableCell scope="row" >{pageCount.page}</TableCell>
            <TableCell align="right">{pageCount.pageCount}</TableCell>
            <TableCell>
              <LinearProgress
                variant="determinate"
                value={percentVisits(pageCount.pageCount)}
                color="secondary"
              />
            </TableCell>
          </TableRow>
        );
      });
      return tableBody;
    }
  }

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title={title}
      />
      <Paper>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th" scope="row">
                  Page
                </TableCell>
                <TableCell align="right">Visits</TableCell>
                <TableCell>{totalTitle()}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{pageCounts ? mapBody(pageCounts) : ''}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}

export default withTheme(PageCountTable);
