import React from 'react';
import _ from 'lodash';
import HorseTableRow from './HorseTableRow';
import { Table } from 'react-bootstrap';

function HorseTable({ horses }) {
  function renderHorses() {
    return _.values(horses).map((horse) => {
      if (horse) {
        return <HorseTableRow horse={horse} key={horse.id} />;
      } else {
        return null;
      }
    });
  }

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Photo</th>
          <th>Id</th>
          <th>SaleHorse</th>
          <th>Name</th>
          <th>Info</th>
          <th>Barn</th>
        </tr>
      </thead>
      <tbody>{renderHorses()}</tbody>
    </Table>
  );
}

export default HorseTable;
