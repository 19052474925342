import React from 'react';
import styled from '@emotion/styled';
import { LinkContainer } from 'react-router-bootstrap';
import { Image } from 'react-bootstrap';
import { datesToText } from '../../../core/DateTools';
import { eventImage } from '../../../core/EventTools';
import { Chip as MuiChip } from '@mui/material';

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

export default function EventTableRow({ event }) {
  function clickLink() {
    if (event) {
      return '/events/manage/' + event.id;
    }
    return '/events';
  }

  function getDocumentsCount(event) {
    if (event && event.docs) {
      return event.docs.length;
    } else {
      return 0;
    }
  }

  function getDocumentsLabel(event) {
    if (event && event.docs) {
      if (event.docs.length === 1) {
        return getDocumentsCount(event) + ' Document';
      } else {
        return getDocumentsCount(event) + ' Documents';
      }
    }
  }

  function getDocumentsBadge() {
    if (event && event.docs) {
      return <Chip label={getDocumentsLabel(event)} color="info" />;
    }
    return <Chip label="0 Documents" color="error" />;
  }

  return (
      <LinkContainer to={clickLink()}>
        <tr key={event.id}>
          <td>
            <h3>{event.id}</h3>
          </td>
          <td>
            <h3>
              <Image src={eventImage(event)} />
            </h3>
          </td>
          <td>
            <h3>{event.title}</h3>
          </td>
          <td>
            <h6>{getDocumentsBadge()}</h6>
          </td>
          <td>
            <h3>{datesToText(event.startDate, event.endDate, event.oneDay)}</h3>
          </td>
        </tr>
      </LinkContainer>
  );
}
