import {
  CLEAR_BARN_USERS,
  SET_BARN_USERS,
  BARN_USER_FETCHED,
} from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_BARN_USERS:
      return INITIAL_STATE;
    case BARN_USER_FETCHED:
      // action.payload === { _id: 'svsdv', workorderName: 'Bob' ... }
      return { ...state, [action.barnUser.id]: action.barnUser };
    case SET_BARN_USERS:
      // action.payload === [ workorder1, workorder2, workorder3 ]
      const newPosts = _.mapKeys(action.barnUsers, 'id');
      // So this clears EVERY Search, otherwise, the user search form will
      // show the results of every search performed.
      return { ...newPosts };
    default:
      return state;
  }
}
