/* AUTO GEN */
/* community_usersReducer.js */
import {
  SET_COMMUNITY_USERS,
  COMMUNITY_USER_FETCHED,
} from '../../actions/types';
import _ from 'lodash';

const INITIAL_STATE = {};

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case COMMUNITY_USER_FETCHED:
      return { ...state, [action.communityUser.id]: action.communityUser };
    case SET_COMMUNITY_USERS:
      const newPosts = _.mapKeys(action.communityUsers, 'id');
      return { ...newPosts };
    // return { ...state, ...newPosts };
    default:
      return state;
  }
}
