/* AUTO GEN */
/* eventSelectablesReducer.js */
import { SET_EVENT_SELECTABLES } from '../../actions/types';

const INITIAL_STATE = [];

const eventSelectablesReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_EVENT_SELECTABLES:
      return action.events;
    default:
      return state;
  }
};
export default eventSelectablesReducer;
