/* AUTO GEN */
/* Person.js */
export class Person {
  constructor(
    id, 
    userId, 
    email, 
    firstName, 
    lastName, 
    phone, 
    address, 
    city, 
    state, 
    zipCode, 
    gender, 
    personType, 
    useaNumber, 
    usefNumber, 
    highestLevelCompeted, 
    dataStatus
  ) {
    this.id = id;
    this.userId = userId;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.address = address;
    this.city = city;
    this.state = state;
    this.zipCode = zipCode;
    this.gender = gender;
    this.personType = personType;
    this.useaNumber = useaNumber;
    this.usefNumber = usefNumber;
    this.highestLevelCompeted = highestLevelCompeted;
    this.dataStatus = dataStatus;
  }

  display() {
    return this.firstName + ' ' + this.lastName;
  }
}

export function personToClass(person) {
  if (person) {
    return new Person(
      person.id, 
      person.userId, 
      person.email, 
      person.firstName, 
      person.lastName, 
      person.phone, 
      person.address, 
      person.city, 
      person.state, 
      person.zipCode, 
      person.gender, 
      person.personType, 
      person.useaNumber, 
      person.usefNumber, 
      person.highestLevelCompeted, 
      person.dataStatus
    );
  } else {
    return null;
  }
}

export function peopleToClassArray(people) {
  if (people) {
    const peopleArray = people.map((item) => {
      return personToClass(item);
    });
    return peopleArray;
  }
}

