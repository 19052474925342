/* (( *** AUTO GEN *** )) */
/* pageviewActions.js */
import axios from 'axios';
// import { SET_PAGEVIEWS, PAGEVIEW_FETCHED } from '../types';

// export function pageviewFetched(pageview) {
//   return {
//     type: PAGEVIEW_FETCHED,
//     pageview,
//   };
// }

// export function setPageviews(pageviews) {
//   return {
//     type: SET_PAGEVIEWS,
//     pageviews,
//   };
// }

// export const fetchPageview = (id) => async (dispatch) => {
//   const res = await axios.get('/api/pageviews/read/' + id);
//   dispatch(pageviewFetched(res.data));
// };
// export const fetchPageviewsById = (id) => async (dispatch) => {
//   const res = await axios.get('/api/pageviews/read/' + id);
//   dispatch(setPageviews(res.data));
// };

// export const fetchPageviewsByUserId = (userId) => async (dispatch) => {
//   const res = await axios.get('/api/pageviews/read/userId/' + userId);
//   dispatch(setPageviews(res.data));
// };

// export const createPageviewUser = (values) => async (dispatch) => {
//   const res = await axios.post('/api/pageviews/create/user', values);
// //   dispatch(pageviewFetched(res.data));
// };

export const createPageview = (values) => async (dispatch) => {
  axios.post('/api/pageviews/create', values).catch(function (error) {
    console.log(error.message);
  });
  //   dispatch(pageviewFetched(res.data));
};

// export const updatePageview = (values) => async (dispatch) => {
//   const res = await axios.post('/api/pageviews/update', values);
//   dispatch(pageviewFetched(res.data));
// };

// export const deletePageview = (values) => async (dispatch) => {
//   const res = await axios.post('/api/pageviews/update', values);
//   dispatch(setPageviews(res.data));
// };
