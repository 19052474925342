import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../../redux/actions';

function LogoutButton() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

  function handleClick() {
    logoutUser(dispatch);
    navigate('/');
  }

  return <Button onClick={handleClick}>Logout</Button>;
}

export default LogoutButton;
