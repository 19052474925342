import axios from 'axios';
import {
  SET_USERS,
  USER_FETCHED,
  SEARCH_SET_USERS,
  SEARCH_USER_FETCHED,
  SEARCH_CLEAR_USERS,
  SEARCH_CLEAR_USER,
  USER_INFO_FETCHED,
  FETCH_USER,
  USER_LOGGED_IN,
} from '../types';
import { fetchUserOnly, loginUser } from '..';

export function setUsers(users) {
  return {
    type: SET_USERS,
    users,
  };
}

export function userFetched(user) {
  return {
    type: USER_FETCHED,
    user,
  };
}

export function userLoggedIn(user) {
  return {
    type: USER_LOGGED_IN,
    user,
  };
}

export function userInfoFetched(userInfo) {
  return {
    type: USER_INFO_FETCHED,
    userInfo,
  };
}

export function searchSetUsers(users) {
  return {
    type: SEARCH_SET_USERS,
    users,
  };
}

export function searchUserFetched(user) {
  return {
    type: SEARCH_USER_FETCHED,
    user,
  };
}

export function searchUsersClear() {
  return {
    type: SEARCH_CLEAR_USERS,
  };
}

export function searchUserClear(searchUser) {
  return {
    type: SEARCH_CLEAR_USER,
    searchUser,
  };
}

export const clearSearchUser = (value) => async (dispatch) => {
  dispatch(searchUserClear(value));
};

export const createUser = (values, navigate) => async (dispatch) => {
  // const { email, password, firstName, lastName } = req.body;
  var loginValues = { username: values.email, password: values.password };
  const res = await axios.post('/api/users/create', values);
  if (res.status !== 204) {
    dispatch(loginUser(loginValues, navigate));
  } else {
    navigate('/');
  }
};

export const getUser = (id) => async (dispatch) => {
  const res = await axios.get('/api/users/' + id);
  dispatch(userFetched(res.data));
};

export const verifyAccount = (values, navigate) => async (dispatch) => {
  console.log(values);
  await axios.post('/api/users/verify/', values);
  navigate('/login');
};

export const fetchUsers = () => async (dispatch) => {
  const res = await axios.get('/api/users');
  dispatch(setUsers(res.data));
};

export const searchUsers = (searchValue) => async (dispatch) => {
  if (searchValue === '') {
    dispatch(searchUsersClear());
  } else {
    const res = await axios.get('/api/users/search/byname/' + searchValue);
    dispatch(searchSetUsers(res.data));
  }
};

export const updateUser = (values) => async (dispatch) => {
  await axios.post('/api/users/update', values);
  dispatch(fetchUserOnly());
  // dispatch(userFetched(res.data));
};

export const updateCurrentUser = (values) => async (dispatch) => {
  console.log(values);
  await axios.post('/api/users/update', values);
  const res = await axios.get('/api/current_user');
  dispatch({ type: FETCH_USER, user: res.data });
};

export const clearUsers = () => async (dispatch) => {
  dispatch(searchUsersClear());
};

export const fetchAdminUser = (id) => async (dispatch) => {
  const res = await axios.get('/api/users/' + id);
  dispatch(searchUserFetched(res.data));
};

export const updateAdminUser = (values) => async (dispatch) => {
  const res = await axios.post('/api/users/update', values);
  dispatch(userFetched(res.data));
};

export const addPermToUser = (values) => async (dispatch) => {
  const res = await axios.post('/api/users/addperm', values);
  dispatch(searchUserFetched(res.data));
};

export const removePermFromUser = (values) => async (dispatch) => {
  const res = await axios.post('/api/users/removeperm', values);
  dispatch(searchUserFetched(res.data));
};

export const addFavoriteToUser = (values) => async (dispatch) => {
  await axios.post('/api/users/addfavorite', values);
  dispatch(fetchUserOnly());
};

export const removeFavoriteFromUser = (values) => async (dispatch) => {
  await axios.post('/api/users/removefavorite', values);
  dispatch(fetchUserOnly());
};

export const saveFavoriteNotes = (values) => async (dispatch) => {
  await axios.post('/api/users/savefavoritenotes', values);
  dispatch(fetchUserOnly());
};
