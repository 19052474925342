import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import { Pencil } from 'react-bootstrap-icons';
import moment from 'moment';
import Linkify from 'react-linkify';
import { LinkContainer } from 'react-router-bootstrap';
import Gallery from './Gallery';

export default function MediaItem({ media, auth }) {
  
  function getMediaContent(media) {
    if (media && media.content) {
      return media.content;
    }
  }

  function getUserName(media) {
    if (media && media.displayName) {
      return media.displayName;
    }
  }

  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm')}</p>;
  }

  function getMediaContentDate(media) {
    if (media && media.createdAt) {
      return dateToText(media.createdAt);
    }
  }

  function getEditLink(media) {
    if (media) {
      return '/media/edit/' + media.id;
    }
  }

  function getEditButton(media, auth) {
    if (auth && media.userId === auth.id) {
      return (
        <LinkContainer to={getEditLink(media)}>
          <Button className="pull-right" variant="info">
            <Pencil />
          </Button>
        </LinkContainer>
      );
    }
  }

  function getMediaPhotos(media) {
    if (media && media.photos && media.photos.length > 0) {
      return <Gallery photos={media.photos} />;
    }
  }

  function getPanelStyle(media) {
    if (media) {
      if (media.type === 0) {
        return 'primary';
      }
      if (media.type === 1) {
        return 'danger';
      }
      if (media.type === 2) {
        return 'warning';
      }
    } else {
      return 'primary';
    }
  }

  return (
    <Col xs={12} md={12}>
      <Card className="m-2" variant={getPanelStyle(media)}>
        <Card.Header>
          <Card.Title>{getUserName(media)}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Linkify><h5>{getMediaContent(media)}</h5></Linkify>
        </Card.Body>
        {getMediaPhotos(media)}
        <Card.Footer>
          {getEditButton(media, auth)}
          {getMediaContentDate(media)}
        </Card.Footer>
      </Card>
    </Col>
  );
}
