/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  saveTrainer,
  fetchTrainer,
  addPhotoToTrainer,
  setMainPhotoForTrainer,
  addVideoToTrainer,
} from '../../../redux/actions/trainerActions';
import {
  Col,
  Form,
  Card,
  Button,
  Container,
  Alert,
  Row,
  ButtonToolbar,
  Image,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import {
  textField,
  photoUploader,
} from '../../core/CoreForms';
import { LinkContainer } from 'react-router-bootstrap';
import _ from 'lodash';
import TrainerPhotoGroup from './TrainerPhotoGroup';
import TrainerVideoGroup from './TrainerVideoGroup';
import ModalImage from 'react-modal-image';
import { v4 as uuidv4 } from 'uuid';
/* eslint-enable no-unused-vars */

class TrainerManage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      id: this.props.trainer ? this.props.trainer.id : null,
      userId: this.props.trainer ? this.props.trainer.userId : null,
      displayName: this.props.trainer ? this.props.trainer.displayName : '',
      photoDescription: '',
      videoDescription: '',
      videoTitle: '',
      videoURL: '',
      photoURL: this.props.trainer
        ? this.props.trainer.photoURL
        : '/images/trainers/trainer_avatar.jpg',
      errors: '',
      loading: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.trainer) {
      this.setState({
        id: nextProps.trainer.id ? nextProps.trainer.id : null,
        userId: nextProps.trainer ? nextProps.trainer.userId : null,
        displayName: nextProps.trainer.displayName,
        photoURL: nextProps.trainer.photoURL,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { id, feed } = this.state;
    this.setState({ loading: true });
    this.props
      .updateFeed({
        id,
        feed,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.displayName]: e.target.value });
  };

  getTrainerEditPath() {
    if (this.props.trainer) {
      return '/account/trainer/edit';
    }
    return '/account/trainer';
  }

  getTrainerPreviewPath() {
    if (this.props.trainer) {
      return '/account/trainer/preview/' + this.props.trainer.id;
    }
    return '/account/trainer';
  }

  renderHeaderButtons() {
    return (
      <Card.Body>
        <ButtonToolbar>
          <LinkContainer to={'/account'}>
            <Button variant="warning">Return to Trainers</Button>
          </LinkContainer>
          <LinkContainer to={this.getTrainerEditPath()}>
            <Button variant="info">Edit Details</Button>
          </LinkContainer>
          <LinkContainer to={this.getTrainerPreviewPath()}>
            <Button variant="primary">Preview</Button>
          </LinkContainer>
        </ButtonToolbar>
      </Card.Body>
    );
  }

  renderPhotosPanel(auth) {
    if (auth) {
      return (
        <Card variant="danger">
          <Card.Header>
            <h2>Photos</h2>
          </Card.Header>
          <Card.Body>
            {this.renderImgTag(this.state.photoURL)}
            <h3>Add Photo</h3>
            <Form
              className={classnames('ui', 'form', {
                loading: this.state.loading,
              })}
              onSubmit={this.handleSubmitPhoto}
            >
              {textField('photoDescription', 'Description', this)}
              {photoUploader()}
              <Button type="submit" variant="info">
                Submit
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            <TrainerPhotoGroup
              trainerId={this.props.auth.id}
              onMakeMainPhoto={this.handleMakeMainPhoto}
            />
          </Card.Footer>
        </Card>
      );
    } else {
      return null;
    }
  }

  renderVideosPanel(auth) {
    if (auth) {
      return (
        <Card variant="danger">
          <Card.Header>
            <h2>Videos</h2>
          </Card.Header>
          <Card.Body>
            <h3>Add Video</h3>
            <Form
              className={classnames('ui', 'form', {
                loading: this.state.loading,
              })}
              onSubmit={this.handleSubmitVideo}
            >
              {textField('videoTitle', 'Video Title', this)}
              {textField('videoDescription', 'Video Description', this)}
              {textField('videoURL', 'Video URL', this)}
              <Button type="submit" variant="info">
                Submit
              </Button>
            </Form>
          </Card.Body>
          <Card.Footer>
            <TrainerVideoGroup trainerId={this.props.auth.id} />
          </Card.Footer>
        </Card>
      );
    } else {
      return null;
    }
  }

  handleSubmitPhoto = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { id, photoDescription } = this.state;
    const data = new FormData();
    data.append('description', photoDescription);
    data.append('title', '');
    data.append('userId', this.props.auth.id);
    data.append('trainerId', id);
    data.append('uuid', uuidv4());

    for (
      let i = 0, numFiles = e.target.photos.files.length;
      i < numFiles;
      i++
    ) {
      let file = e.target.photos.files[i];
      data.append('photos', file, file.displayName);
    }
    this.setState({ photoDescription: '', loading: true });
    e.target.photos.value = null; // blanks the file
    this.props
      .addPhotoToTrainer(data)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleSubmitVideo = (e) => {
    if (e) {
      e.preventDefault();
    }

    // id = trainerId
    const { id, videoTitle, videoDescription, videoURL } = this.state;
    this.setState({ loading: true });
    this.props
      .addVideoToTrainer({
        trainerId: id,
        title: videoTitle,
        description: videoDescription,
        URL: videoURL,
        userId: this.props.auth.id,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleMakeMainPhoto = ({ photoURL }) => {
    // id = trainerId
    const { id } = this.state;
    this.setState({ loading: true });
    this.props
      .setMainPhotoForTrainer({
        trainerId: id,
        photoURL,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  renderImgTag(photoURL) {
    if (photoURL && photoURL.length > 0) {
      return (
        <ModalImage
          small={photoURL}
          medium={photoURL}
          large={photoURL}
          alt="Hello World!"
          key={photoURL}
        />
      );
    } else {
      return (
        <Image
          src="/images/FGFarmLogo150h.PNG"
          alt="Avatar"
          rounded
          responsive
        />
      );
    }
  }

  /* eslint-disable max-len */
  render() {
    return (
      <Container fluid>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Alert>
              <Easel />
              {this.props.trainer ? this.props.trainer.displayName : ''}
              <small> Management Console</small>
            </Alert>
          </Col>
        </Row>
        <Row className="show-grid">
          <Card variant="danger">
            {this.renderHeaderButtons()}
            <Card.Body>
              <Col xs={12} sm={12} md={12}>
                {this.renderPhotosPanel(this.props.auth)}
              </Col>
              <Col xs={12} sm={12} md={12}>
                {this.renderVideosPanel(this.props.auth)}
              </Col>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (state.auth) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  trainer: state.trainers.find((item) => item.id === state.id),
      trainer: state.trainers[state.auth.id],
      barns: state.barns,
      auth: state.auth,
    };
  }

  return {
    trainer: null,
    barns: state.barns,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  saveTrainer,
  fetchTrainer,
  addPhotoToTrainer,
  setMainPhotoForTrainer,
  addVideoToTrainer,
})(TrainerManage);
