/* AUTO GEN */
/* Task.js */
export class Task {
  constructor(
    id,
    userId,
    title,
    type,
    priority,
    notes,
    properties,
    status,
    due,
    dataStatus
  ) {
    this.id = id;
    this.userId = userId;
    this.title = title;
    this.type = type;
    this.priority = priority;
    this.notes = notes;
    this.properties = properties;
    this.status = status;
    this.due = due;
    this.dataStatus = dataStatus;
  }

  getType() {
    switch (this.type) {
      case 0:
        return 'General';
      case 1:
        return 'Billing';
      case 2:
        return 'Ride';
      case 3:
        return 'Farrior';
      case 4:
        return 'Vet';
      case 5:
        return 'Event';
      default:
        return 'General';
    }
  }

  getPriority() {
    switch (this.priority) {
      case 0:
        return 'None';
      case 1:
        return 'High';
      case 2:
        return 'Medium';
      case 3:
        return 'Low';
      default:
        return 'None';
    }
  }

  getStatus() {
    switch (this.status) {
      case 0:
        return 'New';
      case 1:
        return 'Acknowledged';
      case 2:
        return 'In Progress';
      case 3:
        return 'Completed';
      case 4:
        return 'Cancelled';
      default:
        return 'New';
    }
  }

  getPriorityColor() {
    switch (this.priority) {
      case 0:
        return 'info';
      case 1:
        return 'error';
      case 2:
        return 'warning';
      case 3:
        return 'success';
      default:
        return 'info';
    }
  }
}

export function taskToClass(task) {
  if (task) {
    return new Task(
      task.id,
      task.userId,
      task.title,
      task.type,
      task.priority,
      task.notes,
      task.properties,
      task.status,
      task.due,
      task.dataStatus
    );
  } else {
    return null;
  }
}

export function tasksToClassArray(tasks) {
  if (tasks) {
    const tasksArray = tasks.map((item) => {
      return taskToClass(item);
    });
    return tasksArray;
  }
}
