import React from 'react';
import {
  ListGroupItem,
  Card,
  Button,
  ListGroup,
  Image,
  Table,
} from 'react-bootstrap';
import { eventImage } from '../../../core/EventTools';
import { LinkContainer } from 'react-router-bootstrap';
import CalendarTableRow from '../../../calendar/core/CalendarTableRow';

export default function EventDetailsPanel({ eventSelected }) {

  function getYesNo(value) {
    if (value) return <Image width="50" roundedCircle src="/images/yes.png" />;
    return <Image width="50" roundedCircle src="/images/no.png" />;
  }

  function getGeneralInformation() {
    if (eventSelected) {
      return (
        <ListGroup>
          <ListGroupItem>
            <Image src={eventImage(eventSelected)} />
          </ListGroupItem>
          <ListGroupItem>
            <h4>
              Title: {eventSelected ? eventSelected.title : ''}
            </h4>
          </ListGroupItem>
          <ListGroupItem>
            <h4>
              Description:{' '}
              {eventSelected ? eventSelected.description : ''}
            </h4>
          </ListGroupItem>
          <ListGroupItem>
            <h4>
              Notes: {eventSelected ? eventSelected.notes : ''}
            </h4>
          </ListGroupItem>
          <ListGroupItem>
            <h4>
              Accepting Entries:{' '}
              {getYesNo(eventSelected.acceptingEntries)}
            </h4>
          </ListGroupItem>
          <ListGroupItem>
            <h4>
              Visible On Site: {getYesNo(eventSelected.visibleOnSite)}
            </h4>
          </ListGroupItem>
        </ListGroup>
      );
    }
    return <ListGroup></ListGroup>;
  }

  function getEditButton() {
    var route = '';
    if (eventSelected && eventSelected.id)
      route = '/events/edit/' + eventSelected.id;
    return (
      <LinkContainer to={route}>
        <Button variant="danger">Edit</Button>
      </LinkContainer>
    );
  }

  return (
    <Card bg="primary">
      <Card.Header>
        <h2>Details &nbsp;&nbsp;{getEditButton()}</h2>
      </Card.Header>
      <Card.Body>{getGeneralInformation()}</Card.Body>
      <Card.Footer>
        <div>
          <h4> Calendar Preview</h4>
        </div>
        <div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Info</th>
              </tr>
            </thead>
            <tbody>
              {eventSelected ? (
                <CalendarTableRow event={eventSelected} />
              ) : (
                null
              )}
            </tbody>
          </Table>
        </div>
      </Card.Footer>
    </Card>
  );
}
