/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Container,
  Image,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import { fetchTrainer } from '../../../redux/actions/trainerActions';
import TrainerPhotoGroup from './TrainerPhotoGroup';
import TrainerVideoGroup from './TrainerVideoGroup';
/* eslint-enable no-unused-vars */

class TrainerListingPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      photos: this.props.trainer ? this.props.trainer.photos : [],
      videos: this.props.trainer ? this.props.trainer.videos : [],
    };
  }

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchTrainer(this.props.match.params.id);
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.trainer) {
      this.setState({
        photos: nextProps.trainer.photos,
        videos: nextProps.trainer.videos,
      });
    }
  };

  renderImgTag(trainer, photoURL, auth) {
    if (trainer.photoURL && trainer.photoURL.length > 1) {
      return <Image src={trainer.photoURL} alt="Avatar" rounded responsive />;
    } else {
      if (trainer.useId === auth.id) {
        return (
          <Image
            src="/images/horses/horse_avatar.jpg"
            alt="Avatar"
            rounded
            responsive
          />
        );
      } else {
        return (
          <Image
            src="/images/horses/horse_avatar_user.jpg"
            alt="Avatar"
            rounded
            responsive
          />
        );
      }
    }
  }

  getTrainerName() {
    if (this.props.trainer) {
      return this.props.trainer.displayName;
    }
    return 'loading';
  }

  getBio() {
    console.log('this.props.trainer :>> ', this.props.trainer);
    if (this.props.trainer) {
      return this.props.trainer.bio;
    }
    return '';
  }

  getTrainingPhilosophy() {
    if (this.props.trainer) {
      return this.props.trainer.trainingPhilosophy;
    }
    return '';
  }

  getExperience() {
    if (this.props.trainer) {
      return this.props.trainer.experience;
    }
    return '';
  }

  getListGroupFromString(listString) {
    let items = listString.split(',');
    const listItems = items.map((item, index) => (
      <ListGroupItem key={index}>{item.trim()}</ListGroupItem>
    ));
    return <ListGroup>{listItems}</ListGroup>;
  }

  getExperienceList(){
    if (this.props.trainer) {
      return this.getListGroupFromString(this.props.trainer.experience);
    }
    return '';
  }

  getServiceList() {
    if (this.props.trainer) {
      return this.getListGroupFromString(this.props.trainer.services);
    }
    return '';
  }

  getCellPhone() {
    if (this.props.trainer) {
      return this.props.trainer.cell;
    }
    return '';
  }

  getEmail() {
    if (this.props.trainer) {
      return this.props.trainer.email;
    }
    return '';
  }

  render() {
    const photosPanel = (
      <Card variant="danger">
        <Card.Header>
          <h2>Photos</h2>
        </Card.Header>
        <Card.Body>          
            <TrainerPhotoGroup 
              trainerId={this.props.match.params ? this.props.match.params.id : null}
            />
        </Card.Body>
        <Card.Footer />
      </Card>
    );
    const videosPanel = (
      <Card variant="warning">
        <Card.Header>
          <h2>Videos</h2>
        </Card.Header>
        <Card.Body>          
        <TrainerVideoGroup trainerId={this.props.match.params ? this.props.match.params.id : null} />
        </Card.Body>
        <Card.Footer />
      </Card>
    );
    return (
      <Container>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Alert>
              <Easel />
              {this.getTrainerName()}
              <small> You are welcome here at Full Gallop</small>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="warning">
              <Card.Header>
                <Card.Title    >Description</Card.Title>
              </Card.Header>
              <Card.Body>{this.getBio()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >
                  Training Philosophy
                </Card.Title>
              </Card.Header>
              <Card.Body>{this.getTrainingPhilosophy()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Experience</Card.Title>
              </Card.Header>
              <Card.Body>{this.getExperienceList()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Services</Card.Title>
              </Card.Header>
              <Card.Body>{this.getServiceList()}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {photosPanel}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            {videosPanel}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Cell Phone</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroupItem>{this.getCellPhone()}</ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Card variant="info">
              <Card.Header>
                <Card.Title    >Email</Card.Title>
              </Card.Header>
              <Card.Body>
                <ListGroup>
                  <ListGroupItem>{this.getEmail()}</ListGroupItem>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  horse: state.horses.find((item) => item._id === props.match.params._id),
      trainer: state.trainers[props.match.params.id],
      auth: state.auth,
    };
  }

  return {
    trainer: null,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {
  fetchTrainer,
})(TrainerListingPage);
