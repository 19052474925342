import React from 'react';
import styled from '@emotion/styled';

import { useDrag } from 'react-dnd';
import { ItemTypes } from '../Constants';

import { Chip as MuiChip } from '@mui/material';

const Chip = styled(MuiChip)`
  top: 16px;
  right: 16px;
  height: 20px;
  padding: 4px 0;
  font-size: 85%;

  span {
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

function getDressageLabel(test, divisionEntries) {
  if (test) {
    var scheduledCount = 0;
    divisionEntries.forEach((element) => {
      if (element.rideTimes && element.rideTimes['dressage']) {
        scheduledCount++;
      }
    });
    return 'Dressage (' + divisionEntries.length + ' : ' + scheduledCount + ')';
  } else {
    return '';
  }
}

const DressageListChip = ({ eventDivision, divisionEntries }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ENTRY_LIST,
    item: {
      eventDivision: eventDivision,
      divisionEntries: divisionEntries,
      type: ItemTypes.ENTRY_LIST,
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const style = {
    opacity: isDragging ? 0.95 : 1,
    cursor: 'move',
  };

  return (
    <>
      {eventDivision ? (
        <Chip
          ref={drag}
          label={getDressageLabel(eventDivision.tests[0], divisionEntries)}
          color="error"
          style={style}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DressageListChip;
