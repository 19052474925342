import React from 'react';
import EventHorses from '../core/EventHorses';
import HelmetTracker from '../../core/HelmetTracker';


// FAIRLY USELESS FOR NOW
// Adding in so we can add additional functionality at a later time, 
// such as viewing records and other sub data.  Or Entries. 

export function UserHorsesPage() {
  return (
    <>
      <HelmetTracker title="My Horses" />
      <EventHorses />
    </>
  );
}

export default UserHorsesPage;
