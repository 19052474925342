/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addNewJump, fetchJumps } from '../../../redux/actions/jumpActions';
import { redirect } from 'react-router';
import {
  okCancelButtons,
  textField,
  boolField,
  stringSelector,
  photoUploader,
  textArea,
} from '../../core/CoreForms';
import _ from 'lodash';
// import uuidv4 from 'uuid/v4';
/* eslint-enable no-unused-vars */

class JumpAddForm extends Component {
  constructor(props, context) {
    super(props, context);

    // this.handleChange = this.handleChange.bind(this);
    this.handleBoolChanged = this.handleBoolChanged.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    let nextid = 0;
    if (this.props.jumps && _.keys(this.props.jumps).length > 0) {
      nextid = _.keys(this.props.jumps).length + 1;
    }
    this.state = {
      redirect: false,
      jumpNumber: this.props.jumps ? nextid.toString() : '',
      description: this.props.jump ? this.props.jump.description : '',
      category: this.props.jump ? this.props.jump.category : 'N/A',
      level: this.props.jump ? this.props.jump.level : 'N/A',
      height: this.props.jump ? this.props.jump.height : 0,
      width: this.props.jump ? this.props.jump.width : 0,
      topSpread: this.props.jump ? this.props.jump.topSpread : 0,
      baseSpread: this.props.jump ? this.props.jump.baseSpread : 0,
      color: this.props.jump ? this.props.jump.color : '',
      notes: this.props.jump ? this.props.jump.notes : '',
      skinny: this.props.jump ? this.props.jump.skinny : false,
      portable: this.props.jump ? this.props.jump.portable : true,
      needsRepair: this.props.jump ? this.props.jump.needsRepair : false,
      // photoBin: this.props.jump ? this.props.jump.photoBin : uuidv4(),
      photoDescription: '',
      errors: '',
      loading: false,
    };
  }

  componentDidMount = () => {
    // if (this.props.match.params._id) {
    this.props.fetchJumps();
    // }
  };

  componentWillReceiveProps = (nextProps) => {
    let nextId = 0;
    if (this.props.jumps && _.keys(this.props.jumps).length > 0) {
      nextId = _.keys(this.props.jumps).length + 1;
    }
    this.setState({
      jumpNumber: nextId.toString(),
    });
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.description === '') {
      errors.description = 'Jump Description required!';
    }

    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const {
        jumpNumber,
        description,
        category,
        level,
        height,
        width,
        topSpread,
        baseSpread,
        color,
        skinny,
        needsRepair,
        portable,
        photoBin,
        photoDescription,
      } = this.state;
      const data = new FormData();
      data.append('jumpNumber', jumpNumber);
      data.append('description', description);
      data.append('category', category);
      data.append('level', level);
      data.append('height', height);
      data.append('width', width);
      data.append('topSpread', topSpread);
      data.append('baseSpread', baseSpread);
      data.append('color', color);
      data.append('skinny', skinny);
      data.append('portable', portable);
      data.append('needsRepair', needsRepair);
      data.append('userId', this.props.auth._id);
      data.append('photoBin', photoBin);
      data.append('photoDescription', photoDescription);
      for (
        let i = 0, numFiles = e.target.photos.files.length;
        i < numFiles;
        i++
      ) {
        let file = e.target.photos.files[i];
        data.append('photos', file, file.name);
      }
      this.setState({ loading: true });
      this.props
        .addNewJump(data)
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
      this.setState({ redirect: true });
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  /* eslint-disable max-len */
  render() {
    const categories = [
      'N/A',
      'Rolltop',
      'Table',
      'Coop',
      'Log',
      'Feeder',
      'Barn/Cabin',
      'Corner',
      'Scoop',
      'Ramp',
      'Ditch',
      'Ditch & Wall',
      'Trakhener',
      'Bank',
      'Water',
      'Other',
    ];
    const levels = [
      'N/A',
      'Intermediate',
      'Preliminary',
      'Training',
      'Novice',
      'Beginner Novice',
      'Starter',
      'Other',
    ];
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        <div>
          <h1>Add Jump</h1>
          {okCancelButtons('/jumps')}
        </div>
        {textField('jumpNumber', 'JumpNumber', this)}
        {textField('description', 'Description', this)}
        {stringSelector('category', 'Category', this, categories)}
        {stringSelector('level', 'Level', this, levels)}
        {textField('height', 'Height', this)}
        {textField('width', 'Width', this)}
        {textField('topSpread', 'Top Spread', this)}
        {textField('baseSpread', 'Base Spread', this)}
        {textField('color', 'Color', this)}
        {textArea('notes', 'Notes', this)}
        {boolField('skinny', 'Skinny', this)}
        {boolField('portable', 'Portable', this)}
        {boolField('needsRepair', 'Needs Repair', this)}
        {photoUploader()}
        {textField('photoDescription', 'Photo Description', this)}
        {okCancelButtons('/jumps')}
      </Form>
    );
    return (
      <div>
        <Col xs={12} sm={12} md={12}>
          {this.state.redirect ? redirect("/jumps") : form}
        </Col>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    jumps: state.jumps,
  };
}

export default connect(
  mapStateToProps,
  { addNewJump, fetchJumps }
)(JumpAddForm);
