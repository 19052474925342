/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { Image, Thumbnail, Col } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

// const levels = [
//   'N/A',
//   'Intermediate',
//   'Preliminary',
//   'Training',
//   'Novice',
//   'Beginner Novice',
//   'Starter',
//   'Other',
// ];

export default function JumpTableRow({ jump }) {
  function clickLink() {
    if (jump) {
      return '/jumps/edit/' + jump.id;
    }
    return '/jumps';
  }

  function getJumpPhoto() {
    if (jump._photos && jump._photos.length > 0) {
      return (
        <div>
          <Col xsHidden smHidden mdHidden lg={12}>
            <Image
              src={jump._photos[0].amazons3URL}
              width="600px"
              responsive
              rounded
            />
          </Col>
          <Col xsHidden smHidden md={12} lgHidden>
            <Image
              src={jump._photos[0].amazons3URLHalf}
              width="600px"
              responsive
              rounded
            />
          </Col>
          <Col xs={12} sm={12} mdHidden lgHidden>
            <Image
              src={jump._photos[0].amazons3URLThumb}
              width="120px"
              responsive
              rounded
            />
          </Col>
        </div>
      );
    }
    return <div />;
  }

  function getJumplabel() {
    let intermediateStyle = {
      color: 'white',
      backgroundColor: 'red',
      padding: '5px',
      borderRadius: '5px',
    };
    let preliminaryStyle = {
      color: 'white',
      backgroundColor: 'green',
      padding: '5px',
      borderRadius: '5px',
    };
    let trainingStyle = {
      color: 'white',
      backgroundColor: 'black',
      padding: '5px',
      borderRadius: '5px',
    };
    let noviceStyle = {
      color: 'black',
      backgroundColor: 'white',
      padding: '5px',
      borderRadius: '5px',
    };
    let beginnerNoviceStyle = {
      color: 'black',
      backgroundColor: 'yellow',
      padding: '5px',
      borderRadius: '5px',
    };
    let starterStyle = {
      color: 'white',
      backgroundColor: 'dodgerBlue',
      padding: '5px',
      borderRadius: '5px',
    };
    let defaultStyle = {
      color: 'black',
      backgroundColor: 'grey',
      padding: '5px',
      borderRadius: '5px',
    };
    let output = jump.jumpNumber.toString() + '. ' + jump.description;
    switch (jump.level) {
      case 'Intermediate':
        return <label style={intermediateStyle}>{output}</label>;
      case 'Preliminary':
        return <label style={preliminaryStyle}>{output}</label>;
      case 'Training':
        return <label style={trainingStyle}>{output}</label>;
      case 'Novice':
        return <label style={noviceStyle}>{output}</label>;
      case 'Beginner Novice':
        return <label style={beginnerNoviceStyle}>{output}</label>;
      case 'Starter':
        return <label style={starterStyle}>{output}</label>;
      default:
        return <label style={defaultStyle}>{output}</label>;
    }
  }

  return (
    <LinkContainer to={clickLink()}>
      <tr>
        <td>{getJumpPhoto()}</td>
        <td>{getJumplabel()}</td>
      </tr>
    </LinkContainer>
  );
}

JumpTableRow.propTypes = {
  jump: PropTypes.object.isRequired,
};
