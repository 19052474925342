import {
  DIVISIONS_FETCHED,
  DIVISION_CREATED,
  DIVISION_UPDATED,
} from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DIVISIONS_FETCHED:
      return [...action.divisions];
    case DIVISION_CREATED:
      return [...action.divisions, action.division];
    case DIVISION_UPDATED:
      var newState = state.map((item) => {
        if (item.id === action.division.id) {
          return action.division;
        } else return item;
      });
      return [...newState];
    default:
      return state;
  }
}
