import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalObjSelector,
} from '../../core/FinalCoreForms';

import { useDispatch } from 'react-redux';
import { createPerson, updatePerson } from '../../../redux/actions/users/peopleActions';

import { Stack } from '@mui/material';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function EventPersonForm({ person, user, onOkClick, onCancelClick }) {
  const dispatch = useDispatch();

  const initialValues = {
    id: person && person.id ? person.id : null,
    userId: user && user.id ? user.id : null,
    firstName:
      person && person.firstName ? person.firstName : '',
    lastName:
      person && person.lastName ? person.lastName : '',
    email: person && person.email ? person.email : '',
    phone: person && person.phone ? person.phone : '',
    address: person && person.address ? person.address : '',
    city: person && person.city ? person.city : '',
    state: person && person.state ? person.state : '',
    zipCode: person && person.zipCode ? person.zipCode : '',
    gender: person && person.gender ? person.gender : 0,
    personType:
      person && person.personType ? person.personType : 1,
    useaNumber:
      person && person.useaNumber ? person.useaNumber : 0,
    usefNumber:
      person && person.usefNumber ? person.usefNumber : 0,
    highestLevelCompeted:
      person && person.highestLevelCompeted
        ? person.highestLevelCompeted
        : '',
  };

  const onSubmit = async (values) => {
    await sleep(300);
    if (person) {
      Object.assign(person, values);
      dispatch(updatePerson(person));
    } else {
      let newData = {};
      Object.assign(newData, values);
      dispatch(createPerson(newData));
    }
    onOkClick();
  };

  const genderOptionsList = [
    { key: 'Not Selected', value: '0' },
    { key: 'Male', value: '1' },
    { key: 'Female', value: '2' },
  ];

  // const personTypeOptionsList = [
  //   { key: 'Main Person', value: '0' },
  //   { key: 'Added Person', value: '1' },
  // ];

  return (
    <div>
      {renderFormTitle(person, 'Person')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Stack direction="column" spacing={5} alignItems="center">
              {finalTextField('firstName', 'First Name')}
              {finalTextField('lastName', 'Last Name')}
              {finalTextField('email', 'Email')}
              {finalTextField('phone', 'Phone')}
              {finalTextField('address', 'Address')}
              {finalTextField('city', 'City')}
              {finalTextField('state', 'State')}
              {finalTextField('zipCode', 'Zip Code')}
              {finalObjSelector('gender', 'Gender', genderOptionsList)}
              {finalTextField('useaNumber', 'Usea Number')}
              {finalTextField('usefNumber', 'Usef Number')}
              {finalTextField('highestLevelCompeted', 'Highest Level Competed')}

              {okCancel(onCancelClick)}
            </Stack>
          </form>
        )}
      />
    </div>
  );
}
