import React from 'react';

import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
// import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import 'yet-another-react-lightbox/styles.css';
// import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function Gallery({ photos }) {
  const [index, setIndex] = React.useState(-1);

  // const thumbnailsRef = React.useRef(null);

  // Maps will not be on a slider since customers probably do not want to scroll to their map if not prelim
  const swapToCarouselCount = 5;

  // const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

  // const unsplashPhotos = [
  //   {
  //     id: "ts1zXzsD7xc",
  //     width: 1080,
  //     height: 1620,
  //   },

  // const unsplashLink = (id: string, width: number, height: number) =>
  // `https://source.unsplash.com/${id}/${width}x${height}`;

  // const photosDev = [
  //   {
  //     src: 'https://fullgallopfarm-dev.s3.us-east-2.amazonaws.com/users/undefined/media/photos/fullundefinedfullundefined330903908_5951973081563688_1070050197217680975_n.jpg',
  //     height: 901,
  //     width: 1200,
  //   },
  //   {
  //     src: 'https://fullgallopfarm-dev.s3.us-east-2.amazonaws.com/users/undefined/media/photos/fullundefinedfullundefined330952030_6215251465188740_2658446936674590286_n.jpg',
  //     height: 901,
  //     width: 1200,
  //   },
  // ];

  const slides = photos.map((photo) => {
    // const width = photo.width * 4;
    // const height = photo.height * 4;
    return {
      src: photo.amazons3URL,
      width: photo.fullWidth,
      height: photo.fullHeight,
    };
  });

  return (
    <>
      {photos && photos.length > swapToCarouselCount ? (
        <Lightbox
          index={0}
          slides={slides}
          open={true}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Inline]}
          inline={{
            style: { width: '100%', maxWidth: '1200px', aspectRatio: '3 / 2' },
          }}
        />
      ) : (
        ''
      )}

      {photos && photos.length <= swapToCarouselCount ? (
        <>
          <Lightbox
            index={index}
            slides={slides}
            open={index >= 0}
            close={() => setIndex(-1)}
            plugins={[Fullscreen]}
            // thumbnails={{ ref: thumbnailsRef }}
            // on={{
            //   click: () => {
            //     (thumbnailsRef.current?.visible
            //       ? thumbnailsRef.current?.hide
            //       : thumbnailsRef.current?.show)?.();
            //   },
            // }}
          />
          <PhotoAlbum
            layout="rows"
            photos={slides}
            onClick={({ index: current }) => setIndex(current)}
          />
        </>
      ) : (
        ''
      )}
    </>
  );
}
