/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAdminUser, updateAdminUser } from '../../../redux/actions/users/userActions';
// import { fetchAdminBarns } from '../../../redux/actions/users/barnActions';
import { redirect } from 'react-router';
import UserAdminEditForm from './UserAdminEditForm';
/* eslint-enable no-unused-vars */

class UserAdminFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    // this.props.fetchAdminBarns();
    if (this.props.match.params._id) {
      this.props.fetchAdminUser(this.props.match.params._id);
    }
  };

  updateAdminUser = ({
    _id,
    firstName,
    lastName,
    email,
    cell,
  }) => {
    if (_id) {
      return this.props
        .updateAdminUser({
          _id,
          firstName,
          lastName,
          email,
          cell,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    return (
      <div>
        {this.state.redirect ? (
          redirect("/workorders/admin")
        ) : (
          <UserAdminEditForm
            user={this.props.user}
            updateAdminUser={this.updateAdminUser}
            auth={this.props.auth}
            adminBarns={this.props.adminBarns}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params._id) {
    return {
      user: state.searchedUsers[props.match.params._id],
      auth: state.auth,
      adminBarns: state.adminBarns,
    };
  }

  return { workorder: null, auth: null };
}

export default connect(
  mapStateToProps,
  { fetchAdminUser, updateAdminUser }
)(UserAdminFormPage);
