/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { searchUsers } from '../../../../redux/actions/users/userActions';
import { addUserToBarn } from '../../../../redux/actions/users/barnActions';
import _ from 'lodash';
import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  ButtonToolbar,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import { textField } from '../../../core/CoreForms';
import { LinkContainer } from 'react-router-bootstrap';
/* eslint-enable no-unused-vars */

class BarnForm extends Component {
  constructor(props, context) {
    super(props, context);
    this.addUserToBarnClick = this.addUserToBarnClick.bind(this);
    this.state = {
      id: this.props.barn ? this.props.barn.id : null,
      name: this.props.barn ? this.props.barn.name : '',
      barn: this.props.barnUsers ? this.props.barnUsers : [],
      searchString: '',
      errors: '',
      loading: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.barn) {
      this.setState({
        id: nextProps.barn.id ? nextProps.barn.id : null,
        name: nextProps.barn.name,
        barnUsers: nextProps.barnUsers,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.name === '') errors.name = 'Barn Name required!';

    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    console.log('SAVING BARN');
    if (isValid) {
      const { id, name } = this.state;
      console.log(id);
      console.log(name);
      this.setState({ loading: true });
      this.props
        .saveBarn({
          id,
          name,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  renderFormTitle() {
    if (this.state.id && this.state.id.length > 3) {
      return (
        <div>
          <h1>Edit Barn</h1>
        </div>
      );
    } else {
      return (
        <div>
          <h1>Add Barn</h1>
        </div>
      );
    }
  }

  cancelButton() {
    return <Button variant="warning">Cancel</Button>;
  }

  saveButton() {
    return (
      <Button type="submit" variant="primary">
        Save
      </Button>
    );
  }

  usersToListGroup(barnUsers) {
    return _.values(barnUsers).map((barnUser) => {
      if (barnUser.barnId === this.props.barn.id) {
        return (
          <ListGroupItem key={barnUser.id}>
            {barnUser.displayNameUser}
          </ListGroupItem>
        );
      } else {
        return null;
      }
    });
  }

  isUserThisBarnUser(communityUser) {
    for (let key in this.props.barnUsers) {
      if (this.props.barnUsers.hasOwnProperty(key)) {
        if (
          this.state.id === this.props.barnUsers[key].barnId &&
          this.props.barnUsers[key].barnUserId === communityUser.communityUserId
        ) {
          return true;
        }
      }
    }
    return false;
  }

  addUserToBarnClick(communityUser) {
    const { id } = this.state;
    this.setState({ loading: true });
    if (
      communityUser &&
      this.props.auth &&
      this.props.auth.id !== communityUser.communityUserId
    ) {
      this.props
        .addUserToBarn({
          barnId: id,
          barnUserId: communityUser.communityUserId,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  }

  communityUsersToListGroup(communityUsers) {
    return _.values(communityUsers).map((communityUser) => {
      if (!this.isUserThisBarnUser(communityUser)) {
        return (
          <ListGroupItem key={communityUser.id}>
            {communityUser.displayNameCommunityUser}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="primary"
              onClick={this.addUserToBarnClick.bind(this, communityUser)}
            >
              Add To Barn
            </Button>
          </ListGroupItem>
        );
      } else {
        return null;
      }
    });
  }

  okCancelButtons() {
    return (
      <ButtonToolbar>
        <LinkContainer to={'/barns'}>{this.cancelButton()}</LinkContainer>
        {this.saveButton()}
      </ButtonToolbar>
    );
  }

  showPanelIfEditingNotAdding(id, panel) {
    if (id) {
      return panel;
    }
  }

  /* eslint-disable max-len */
  render() {
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        {this.renderFormTitle()}
        {!!this.state.errors.global && (
          <div className="ui negative message">
            <p>{this.state.errors.global}</p>
          </div>
        )}
        {textField('name', 'Barn Name', this)}
        {this.okCancelButtons()}
      </Form>
    );
    const usersPanel = (
      <Card variant="danger">
        <Card.Header>
          <h2>Users</h2>
        </Card.Header>
        <Card.Body>
          <Card variant="danger">
            <Card.Header>
              <h2>Assigned Users</h2>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                {this.usersToListGroup(this.state.barnUsers)}
              </ListGroup>
            </Card.Body>
          </Card>
          <Card variant="danger">
            <Card.Header>
              <h3>Available Users</h3>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                {this.communityUsersToListGroup(this.props.communityUsers)}
              </ListGroup>
            </Card.Body>
          </Card>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            {form}
            {this.showPanelIfEditingNotAdding(this.state.id, usersPanel)}
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps({ auth, communityUsers, barnUsers }) {
  return {
    communityUsers,
    barnUsers,
    auth,
  };
}

export default connect(mapStateToProps, { searchUsers, addUserToBarn })(
  BarnForm
);
