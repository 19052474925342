// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import sentryMiddleware from './sentryMiddleware';

import authReducer from './reducers/authReducer';
import barnsReducer from './reducers/barns/barnsReducer';
import barnUsersReducer from './reducers/barns/barnUsersReducer';
import calendarReducer from './reducers/calendarReducer';
import communityInvitesReducer from './reducers/community/communityInvitesReducer';
import coInvitesToMeReducer from './reducers/community/coInvitesToMeReducer';
import communityUsersReducer from './reducers/community/communityUsersReducer';
import eventsReducer from './reducers/events/eventsReducer';
import eventLevelsReducer from './reducers/events/eventLevelsReducer';
import horsesReducer from './reducers/horses/horsesReducer';
import jumpsReducer from './reducers/jumpsReducer';
import mediasReducer from './reducers/mediasReducer';
import mediaFeedReducer from './reducers/mediaFeedReducer';
import saleHorsesReducer from './reducers/saleHorsesReducer';
import usersSearchReducer from './reducers/usersSearchReducer';

import scheduleBlocksReducer from './reducers/scheduler/scheduleBlocksReducer';
import ringsReducer from './reducers/scheduler/ringsReducer';
import divisionsReducer from './reducers/events/divisionsReducer';
import entriesReducer from './reducers/events/entriesReducer';
import eventDivisionsReducer from './reducers/events/eventDivisionsReducer';
import eventHorsesReducer from './reducers/events/eventHorsesReducer';
import eventTestsReducer from './reducers/events/eventTestsReducer';
import eventSelectablesReducer from './reducers/events/eventSelectablesReducer';
import peopleReducer from './reducers/events/peopleReducer';
import userEntriesReducer from './reducers/events/userEntriesReducer';

import taskReducer from './reducers/tasks/taskReducer';

// STORE
import productsReducer from './reducers/store/productsReducer';
import productCategoriesReducer from './reducers/store/productCategoriesReducer';
import productItemsReducer from './reducers/store/productItemsReducer';

// ADMIN
import permissionsReducer from './reducers/admin/permissionsReducer';

// OCULUS
import reportingReducer from './reducers/oculus/reportingReducer';

// SHOPPING
import cartReducer from './slices/cartSlice';

const reducer = {
  auth: authReducer,
  horses: horsesReducer,
  reporting: reportingReducer,
  cart: cartReducer,
  barns: barnsReducer,
  barnUsers: barnUsersReducer,
  calendar: calendarReducer,
  communityInvites: communityInvitesReducer,
  communityInvitesToMe: coInvitesToMeReducer,
  communityUsers: communityUsersReducer,
  divisions: divisionsReducer,
  entries: entriesReducer,
  eventDivisions: eventDivisionsReducer,
  eventHorses: eventHorsesReducer,
  events: eventsReducer,
  eventSelectables: eventSelectablesReducer,
  eventLevels: eventLevelsReducer,
  eventTests: eventTestsReducer,
  jumps: jumpsReducer,
  medias: mediasReducer,
  mediaFeed: mediaFeedReducer,
  people: peopleReducer,
  permissions: permissionsReducer,
  productCategories: productCategoriesReducer,
  productItems: productItemsReducer,
  products: productsReducer,
  rings: ringsReducer,
  saleshorses: saleHorsesReducer,
  scheduleBlocks: scheduleBlocksReducer,
  searchedUsers: usersSearchReducer,
  tasks: taskReducer,
  userEntries: userEntriesReducer,
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryMiddleware);
  },
  devTools: process.env.NODE_ENV !== 'amazonProduction',
});
