/* AUTO GEN */
/* Permission.js */
import PermissionProperties from './PermissionProperties';

export class Permission {
  constructor(id, permission, type, properties, notes, dataStatus) {
    this.id = id;
    this.permission = permission;
    this.type = type;
    if (properties) {
      this.properties = propertiesToArray(properties);
    }
    this.notes = notes;
    this.dataStatus = dataStatus;
  }
}

function propertiesToArray(properties) {
  var newArray = [];
  if (properties) {
    properties.forEach((property) =>
      newArray.push(
        new PermissionProperties(property.propertyName, property.propertyTypeId)
      )
    );
    return newArray;
  } else {
    return newArray;
  }
}

export function permissionToClass(permission) {
  if (permission) {
    return new Permission(
      permission.id,
      permission.permission,
      permission.type,
      permission.properties,
      permission.notes,
      permission.dataStatus
    );
  } else {
    return null;
  }
}

export function permissionsToClassArray(permissions) {
  if (permissions) {
    const permissionsArray = permissions.map((item) => {
      return permissionToClass(item);
    });
    return permissionsArray;
  }
}
