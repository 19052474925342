/* (( *** AUTO GEN *** )) */
/* taskActions.js */
import axios from 'axios';
import { SET_TASKS, TASK_FETCHED } from './types';
import { taskToClass, tasksToClassArray } from '../classes/tasks/Task';

export function taskFetched(task) {
  return {
    type: TASK_FETCHED,
    task: taskToClass(task),
  };
}

export function setTasks(tasks) {
  return {
    type: SET_TASKS,
    tasks: tasksToClassArray(tasks),
  };
}

export const fetchTask = (id) => async (dispatch) => {
  const res = await axios.get('/api/tasks/read/' + id);
  dispatch(taskFetched(res.data));
};

export const fetchTasks = () => async (dispatch) => {
  const res = await axios.get('/api/tasks/read');
  dispatch(setTasks(res.data));
};

export const fetchTasksById = (id) => async (dispatch) => {
  const res = await axios.get('/api/tasks/read/' + id);
  dispatch(setTasks(res.data));
};

export const fetchTasksByUserId = (userId) => async (dispatch) => {
  const res = await axios.get('/api/tasks/read/userId/' + userId);
  dispatch(setTasks(res.data));
};

export const createTask = (values) => async (dispatch) => {
  const res = await axios.post('/api/tasks/create', values);
  dispatch(taskFetched(res.data));
};

export const updateTask = (values) => async (dispatch) => {
  const res = await axios.post('/api/tasks/update', values);
  dispatch(taskFetched(res.data));
};

export const deleteTask = (values) => async (dispatch) => {
  const res = await axios.post('/api/tasks/update', values);
  dispatch(setTasks(res.data));
};
