import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCalendar } from '../../../redux/actions/Events/eventActions';
import _ from 'lodash';
import { Table, Button } from 'react-bootstrap';
import CalendarTableRow from './CalendarTableRow';

export default function CalendarTable({ showPrior }) {
  function sortedEvents(events) {
    if (events) {
      let sortedEvents = _.orderBy(events, ['startDate'], ['asc']);
      return sortedEvents;
    }
    return null;
  }
  const calendar = useSelector((state) => sortedEvents(state.calendar));

  const [dateOrder, setDateOrder] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCalendar());
  }, [dispatch]);

  function changeDateSort() {
    if (dateOrder != null) {
      setDateOrder(!dateOrder);
    }
  }

  function dateOrderToText(order) {
    if (order) {
      return 'Sort Old to New';
    }
    return 'Sort New to Old';
  }

  // Bit repetitive due to double filter.  Eh... works.
  function tableEvents() {
    var date = new Date();
    date.setDate(date.getDate() - 7);
    if (dateOrder) {
      return _.orderBy(calendar, ['startDate']).map((event) => {
        var newDate = new Date(event.startDate);
        if (showPrior) {
          if (newDate < date)
            return <CalendarTableRow event={event} key={event.id} />;
        } else {
          if (newDate > date)
            return <CalendarTableRow event={event} key={event.id} />;
        }
        return '';
      });
    }
    return _.orderBy(calendar, ['startDate'], ['desc']).map((event) => {
      var newDate = new Date(event.startDate);
      if (showPrior) {
        if (newDate < date)
          return <CalendarTableRow event={event} key={event.id} />;
      } else {
        if (newDate > date)
          return <CalendarTableRow event={event} key={event.id} />;
      }
      return '';
    });
  }

  return (
    <Table striped bordered overflow={+true}>
      <thead>
        <tr>
          <th>
            <h3>Date</h3>
            <Button variant="danger" onClick={() => changeDateSort()}>
              {dateOrderToText(dateOrder)}
            </Button>
          </th>
          <th>
            <h3>Description</h3>
            <br></br>
          </th>
          <th>
            <h3>Info</h3>
            <br></br>
          </th>
        </tr>
      </thead>
      <tbody>{tableEvents()}</tbody>
    </Table>
  );
}
