import React from 'react';
import {
  Button
} from 'react-bootstrap';

export function prelimMapButton() {
  return (
    <Button
      variant="success"
      href="/maps/Prelim.jpg"
    >
      Preliminary
    </Button>
  );
}

export function prelimOptimumButton(time, href) {
  return (
    <Button
      variant="success"
      href={href}
    >
      {time}
    </Button>
  );
}

export function trainingMapButton() {
  return (
    <Button
      variant="secondary"
      href="/maps/Training.jpg"
    >
      Training
    </Button>
  );
}

export function trainingOptimumButton(time, href) {
  return (
    <Button
      variant="secondary"
      href={href}
    >
      {time}
    </Button>
  );
}

export function noviceMapButton() {
  return (
    <Button
      variant="light"
      href="/maps/Novice.jpg"
    >
      Novice
    </Button>
  );
}

export function noviceOptimumButton(time, href) {
  return (
    <Button
      variant="light"
      href={href}
    >
      {time}
    </Button>
  );
}

export function begNoviceMapButton() {
  return (
    <Button
      variant="warning"
      href="/maps/BegNovice.jpg"
    >
      Beg Novice
    </Button>
  );
}

export function begNoviceOptimumButton(time, href) {
  return (
    <Button
      variant="warning"
      href={href}
    >
      {time}
    </Button>
  );
}

export function starterMapButton() {
  return (
    <Button
      variant="info"
      href="/maps/Starter.jpg"
    >
      Starter
    </Button>
  );
}

export function starterOptimumButton(time, href) {
  return (
    <Button
      variant="info"
      href={href}
    >
      {time}
    </Button>
  );
}