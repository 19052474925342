/* AUTO GEN */
/* productCategoriesReducer.js */
import { SET_PRODUCT_ITEMS, PRODUCT_ITEM_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

const productItemsReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCT_ITEM_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.productItem.id) {
          // Item UPDATED
          itemFound = true;
          return action.productItem;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.productItem);
      }
      return [...newState];
    case SET_PRODUCT_ITEMS:
      return action.productItems;
    default:
      return state;
  }
};
export default productItemsReducer;
