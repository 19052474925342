/* AUTO GEN */
/* Event.js */

import { documentsToClassArray } from './Document';
export class Event {
    constructor(
      id, 
      title, 
      description, 
      type, 
      startDate, 
      endDate, 
      visibleOnSite, 
      acceptingEntries, 
      notes, 
      oneDay, 
      useaNumber, 
      eeNumber, 
      entryStatusData, 
      rideTimesData, 
      sjCourses, 
      xcCourses, 
      dataStatus, 
      createdAt, 
      updatedAt, 
      docs
    ) {
      this.id = id;
      this.title = title;
      this.description = description;
      this.type = type;
      this.startDate = startDate;
      this.endDate = endDate;
      this.visibleOnSite = visibleOnSite;
      this.acceptingEntries = acceptingEntries;
      this.notes = notes;
      this.oneDay = oneDay;
      this.useaNumber = useaNumber;
      this.eeNumber = eeNumber;
      this.entryStatusData = entryStatusData;
      this.rideTimesData = rideTimesData;
      this.sjCourses = sjCourses;
      this.xcCourses = xcCourses;
      this.dataStatus = dataStatus;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      if (docs) {
        this.docs = documentsToClassArray(docs);
      }
    }
  }
  
  export function eventToClass(event) {
    if (event) {
      return new Event(
        event.id, 
        event.title, 
        event.description, 
        event.type, 
        event.startDate, 
        event.endDate, 
        event.visibleOnSite, 
        event.acceptingEntries, 
        event.notes, 
        event.oneDay, 
        event.useaNumber, 
        event.eeNumber, 
        event.entryStatusData, 
        event.rideTimesData, 
        event.sjCourses, 
        event.xcCourses, 
        event.dataStatus, 
        event.createdAt, 
        event.updatedAt, 
        event.docs
      );
    } else {
      return null;
    }
  }
  
  export function eventsToClassArray(events) {
    if (events) {
      const eventsArray = events.map((item) => {
        return eventToClass(item);
      });
      return eventsArray;
    }
  }
  
  