/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTackPhotos, deleteTackPhoto } from '../../../redux/actions/tackActions';
import _ from 'lodash';
import TackPhotoCard from '../../core/TackPhotoCard';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TackPhotoGroup extends Component {
  componentDidMount() {
    if (this.props.tackId) {
      this.props.fetchTackPhotos(this.props.tackId);
    }
  } 

  handleDeletePhoto = ({ tackPhotoId }) => {
    this.props
      .deleteTackPhoto({
        tackId: this.props.tackId,
        tackPhotoId: tackPhotoId,
      });
  };

  renderTackPhotos() {
    if (this.props.tack) {
      if (this.props.tack.tackPhotos) {
        return _.values(this.props.tack.tackPhotos).map((tackPhoto) => {
          return (
            <tr key={tackPhoto.id}>
              <TackPhotoCard
                tackPhoto={tackPhoto} 
                onDelete={this.handleDeletePhoto} 
                onMakeMainPhoto={this.props.onMakeMainPhoto}
              />
            </tr>
          );
        });
      }
    }
    return null;
  }

  render() {
    return (
      <Table striped bordered    hover>
        <tbody>{this.renderTackPhotos()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.tackId && state.tacks) {
    return {
      /* eslint-disable max-len */
      // Below is hoe to get an item out of a pure Array.
      //  tack: state.tacks.find((item) => item.id === props.match.params.id),
      tack: state.tacks[props.tackId],
      tackPhotos: state.tackPhotos,
      auth: state.auth,
    };
  }

  return {
    tack: null,
    tackPhotos: state.tackPhotos,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { fetchTackPhotos, deleteTackPhoto })(TackPhotoGroup);
