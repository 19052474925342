/* (( *** AUTO GEN *** )) */
/* productItemActions.js */
import axios from 'axios';
import { SET_PRODUCT_ITEMS, PRODUCT_ITEM_FETCHED } from '../types';
import { productItemToClass, productItemsToClassArray } from '../../classes/ProductItem';

export function productItemFetched(productItem) {
  return {
    type: PRODUCT_ITEM_FETCHED,
    productItem: productItemToClass(productItem),
  };
}

export function setProductItems(productItems) {
  return {
    type: SET_PRODUCT_ITEMS,
    productItems: productItemsToClassArray(productItems),
  };
}

export const fetchProductItem = (id) => async (dispatch) => {
  const res = await axios.get('/api/productitems/read/' + id);
  dispatch(productItemFetched(res.data));
};
export const fetchProductItems = () => async (dispatch) => {
  const res = await axios.get('/api/productitems/read');
  dispatch(setProductItems(res.data));
};

export const fetchProductItemsById = (id) => async (dispatch) => {
  const res = await axios.get('/api/productitems/read/' + id);
  dispatch(setProductItems(res.data));
};

export const fetchProductItemsByProductId = (productId) => async (dispatch) => {
  const res = await axios.get('/api/productitems/read/productId/' + productId);
  dispatch(setProductItems(res.data));
};

export const createProductItem = (values) => async (dispatch) => {
  const res = await axios.post('/api/productitems/create', values);
  dispatch(productItemFetched(res.data));
};

export const updateProductItem = (values) => async (dispatch) => {
  const res = await axios.post('/api/productitems/update', values);
  dispatch(productItemFetched(res.data));
};

export const deleteProductItem = (values) => async (dispatch) => {
  const res = await axios.post('/api/productitems/update', values);
  dispatch(setProductItems(res.data));
};
