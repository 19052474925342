/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchJump, updateJump } from '../../../redux/actions/jumpActions';
import { redirect } from 'react-router';
import JumpEForm from './JumpEForm';
import { Container } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class JumpEFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {
    if (this.props.match.params.id) {
      this.props.fetchJump(this.props.match.params.id);
    }
  };

  updateJump = ({
    id,
    jumpNumber,
    description,
    category,
    level,
    height,
    width,
    topSpread,
    baseSpread,
    color,
    notes,
    skinny,
    portable,
    needsRepair,
  }) => {
    if (id) {
      return this.props
        .updateJump({
          id,
          jumpNumber,
          description,
          category,
          level,
          height,
          width,
          topSpread,
          baseSpread,
          color,
          notes,
          skinny,
          portable,
          needsRepair,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    return (
      <Container fluid>
        {this.state.redirect ? (
          redirect("/jumps")
        ) : (
          <JumpEForm
            jump={this.props.jump}
            updateJump={this.updateJump}
            auth={this.props.auth}
          />
        )}
      </Container>
    );
  }
}

function mapStateToProps(state, props) {
  if (props.match.params.id) {
    return {
      jump: state.jumps[props.match.params.id],
      auth: state.auth,
    };
  }

  return { jump: null, auth: null };
}

export default connect(
  mapStateToProps,
  { fetchJump, updateJump }
)(JumpEFormPage);
