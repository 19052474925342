import axios from 'axios';
import {
  FETCH_USER,
  ADD_TACK_CATEGORY,
  TACK_CATEGORY_FETCHED,
  TACK_CATEGORY_UPDATED,
  SET_TACK_CATEGORIES,
} from './types';

export function addTackCategory(tackCategory) {
  return {
    type: ADD_TACK_CATEGORY,
    tackCategory,
  };
}

export function tackCategoryFetched(tackCategory) {
  return {
    type: TACK_CATEGORY_FETCHED,
    tackCategory,
  };
}

export function tackCategoryUpdated(tackCategory) {
  return {
    type: TACK_CATEGORY_UPDATED,
    tackCategory,
  };
}

export function setTackCategories(tackCategories) {
  return {
    type: SET_TACK_CATEGORIES,
    tackCategories,
  };
}

export const submitTackCategory = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/tackcategories/create', values);
  history.push('/tackcategories');
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchTackCategories = () => async (dispatch) => {
  const res = await axios.get('/api/tackcategories/read');
  dispatch(setTackCategories(res.data));
};

export const fetchTackCategory = (id) => async (dispatch) => {
  const res = await axios.get('/api/tackcategories/read/' + id);
  dispatch(tackCategoryFetched(res.data));
};

export const saveTackCategory = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/tackcategories/create', values);
  dispatch(tackCategoryFetched(res.data));
};

export const updateTackCategory = (values) => async (dispatch) => {
  const res = await axios.post('/api/tackcategories/update', values);
  dispatch(tackCategoryFetched(res.data));
};
