import React from 'react';
import { Form } from 'react-final-form';
import {
  okCancel,
  renderFormTitle,
  finalTextField,
  finalDataStatusSelector,
} from '../../../core/FinalCoreForms';
import {
  createBarn,
  updateBarn,
} from '../../../../redux/actions/users/barnActions';
import { useDispatch } from 'react-redux';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default function BarnForm({ barn, auth, onOkClick }) {
  const dispatch = useDispatch();

  const initialValues = {
    id: barn && barn.id ? barn.id : null,
    name: barn && barn.name ? barn.name : '',
    dataStatus: barn && barn.dataStatus ? barn.dataStatus : 0,
  };

  function submitBarn(barn) {
    if (barn && barn.id) {
      return dispatch(updateBarn(barn)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createBarn(barn)).then(() => {
        //   navigateBack();
      });
    }
  }

  const onSubmit = async (values) => {
    await sleep(300);
    if (barn && submitBarn) {
      Object.assign(barn, values);
      barn.userId = auth.id;
      submitBarn(barn);
    } else {
      let newData = {};
      Object.assign(newData, values);
      newData.userId = auth.id;
      submitBarn(newData);
    }
    if (onOkClick) {
      onOkClick();
    }
  };

  return (
    <div>
      {renderFormTitle(barn, 'barns')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalTextField('name', 'Name')}
            {finalDataStatusSelector()}
            {okCancel(onOkClick)}
          </form>
        )}
      />
    </div>
  );
}
