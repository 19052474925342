import React from 'react';
import { Alert } from 'react-bootstrap';
import { Clock } from 'react-bootstrap-icons';
import CalendarRideTimesTable from '../core/CalendarRideTimesTable';

export default function CalendarRideTimesPage() {
  return (
    <div>
      <Alert>
        <h2>
          <Clock />
          &nbsp;&nbsp; Ride Times
        </h2>
        <h4> Click on your Time and see what happens!</h4>
      </Alert>
      <CalendarRideTimesTable />
    </div>
  );
}
