import { FETCH_USER } from '../actions/types';
import { USER_LOGGED_IN } from '../actions/types';

export default function reduce(state = null, action) {
  switch (action.type) {
    case FETCH_USER:
      return action.payload || false;
    case USER_LOGGED_IN:
      return action.user || false;
    default:
      return state;
  }
}
