/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import classnames from 'classnames';
import { Col, Image, Form, Button, Card, Row } from 'react-bootstrap';
import {
  authorizedOkCancelChangesButtons,
  textField,
  stringSelector,
  boolField,
  textArea,
  photoUploader,
} from '../../core/CoreForms';
import { addPhotoToJump, deletePhoto } from '../../../redux/actions/jumpActions';
import { connect } from 'react-redux';
// import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { LinkContainer } from 'react-router-bootstrap';
import PhotoCard from '../../core/PhotoCard';
/* eslint-enable no-unused-vars */

// const WO_STATUS_ACTIVE = 0;
// const WO_STATUS_COMPLETED = 1;
// const WO_STATUS_CANCELLED = 2;
// const WO_STATUS_ARCHIVED = 3;

class JumpEForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleBoolChanged = this.handleBoolChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.state = {
      id: this.props.jump ? this.props.jump.id : null,
      jumpNumber: this.props.jump ? this.props.jump.jumpNumber : '',
      description: this.props.jump ? this.props.jump.description : '',
      category: this.props.jump ? this.props.jump.category : 'N/A',
      level: this.props.jump ? this.props.jump.level : 'N/A',
      color: this.props.jump ? this.props.jump.color : '',
      notes: this.props.jump ? this.props.jump.notes : '',
      skinny: this.props.jump ? this.props.jump.skinny : false,
     photos: this.props.jump ? this.props.jump._photos : [],
      // photoBin: this.props.jump ? this.props.jump.photoBin : uuidv4(),
      photoDescription: '',
      errors: '',
      loading: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.jump) {
      this.setState({
        id: nextProps.jump.id ? nextProps.jump.id : null,
        jumpNumber: nextProps.jump.jumpNumber,
        description: nextProps.jump.description,
        category: nextProps.jump.category,
        level: nextProps.jump.level,
        color: nextProps.jump.color,
        notes: nextProps.jump.notes,
        skinny: nextProps.jump.skinny,
        photos: nextProps.jump._photos,
        photoBin: nextProps.jump.photoBin,
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleBoolChanged = (e) => {
    this.setState({ [e.target.id]: e.target.checked });
    if (e.target.id === 'oneDay') {
      if (e.target.checked) {
        this.setState({ endDate: this.state.startDate });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.description === '') {
      errors.description = 'Jump Description required!';
    }

    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const {
        id,
        jumpNumber,
        description,
        category,
        level,
        height,
        width,
        topSpread,
        baseSpread,
        color,
        notes,
        skinny,
        portable,
        needsRepair,
      } = this.state;
      this.setState({ loading: true });
      this.props
        .updateJump({
          id,
          jumpNumber,
          description,
          category,
          level,
          height,
          width,
          topSpread,
          baseSpread,
          color,
          notes,
          skinny,
          portable,
          needsRepair,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleSubmitPhoto = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { id, photoDescription, photoBin } = this.state;
    const data = new FormData();
    data.append('description', photoDescription);
    data.append('title', '');
    // ownerId = remnants of admin editing
    data.append('ownerId', this.props.auth.id);
    data.append('userId', this.props.auth.id);
    data.append('jumpId', id);
    data.append('photoBin', photoBin);
    for (
      let i = 0, numFiles = e.target.photos.files.length;
      i < numFiles;
      i++
    ) {
      let file = e.target.photos.files[i];
      data.append('photos', file, file.name);
    }
    this.setState({ photoDescription: '', loading: true });
    e.target.photos.value = null; // blanks the file
    this.props
      .addPhotoToJump(data)
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  onDeleteClick = (id) => {
    this.props.deletePhoto({
      docId: id,
      userId: this.props.auth.id,
      jumpId: this.props.jump.id,
    });
  };

  renderPhotos() {
    if (this.state.photos && this.state.photos.length > 0) {
      return this.state.photos.map((photo) => {
        if (photo.dataStatus !== 3) {
          return (
            <div key={photo.id}>
              <hr />
              <PhotoCard
                photo={photo}
                key={photo.id}
                parent={this.props.jump}
                onDelete={this.onDeleteClick}
                auth={this.props.auth}
              />
            </div>
          );
        } else {
          return <p></p>;
        }
      });
    } else {
      return (
        <div>
          <Image
            src="/images/FGFarmLogo150h.PNG"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      );
    }
  }

  renderPhotoUploadForm() {
    if (this.props.auth) {
      return (
        <div>
          <h3>Add Photo</h3>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleSubmitPhoto}
          >
            {textField('photoDescription', 'Description', this)}
            {photoUploader()}
            <Button type="submit" variant="info">
              Submit
            </Button>
          </Form>
        </div>
      );
    }
  }

  /* eslint-disable max-len */
  render() {
    const categories = [
      'N/A',
      'Rolltop',
      'Table',
      'Coop',
      'Log',
      'Feeder',
      'Barn/Cabin',
      'Corner',
      'Scoop',
      'Ramp',
      'Ditch',
      'Ditch & Wall',
      'Trakhener',
      'Bank',
      'Water',
      'Other',
    ];
    const levels = [
      'N/A',
      'Intermediate',
      'Preliminary',
      'Training',
      'Novice',
      'Beginner Novice',
      'Starter',
      'Other',
    ];
    const form = (
      <Card variant="danger">
        <Card.Header>
          <h2>Edit Jump</h2>
          <LinkContainer to={'/jumps'}>
            <Button variant="info">Back to Jumps</Button>
          </LinkContainer>
        </Card.Header>
        <Card.Body>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleSubmit}
          >
            {textField('jumpNumber', 'JumpNumber', this)}
            {textField('description', 'Description', this)}
            {stringSelector('category', 'Category', this, categories)}
            {stringSelector('level', 'Level', this, levels)}
            {textField('color', 'Color', this)}
            {textArea('notes', 'Notes', this)}
            {boolField('skinny', 'Skinny', this)}
            {authorizedOkCancelChangesButtons(this.props.auth, '/jumps')}
          </Form>
        </Card.Body>
      </Card>
    );
    const photosPanel = (
      <Card variant="danger">
        <Card.Header>
          <h2>Photos</h2>
        </Card.Header>
        <Card.Body>
          {this.renderPhotoUploadForm()}
          {this.renderPhotos()}
        </Card.Body>
        <Card.Footer />
      </Card>
    );
    return (
      <Row className="show-grid">
        <Col xs={12} sm={6} md={6}>
          {form}
        </Col>
        <Col xs={12} sm={6} md={6}>
          {photosPanel}
        </Col>
      </Row>
    );
  }
}

export default connect(null, { addPhotoToJump, deletePhoto })(JumpEForm);
