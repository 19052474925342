/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import moment from 'moment';
import ReactPlayer from 'react-player';
/* eslint-enable no-unused-vars */

export default function HorseVideoCard({ horseVideo, onDelete }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderPlayer() {
    if (
      horseVideo &&
      horseVideo.amazons3URL &&
      horseVideo.amazons3URL.length > 0
    ) {
      return (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={horseVideo.amazons3URL}
            width="100%"
            height="100%"
          />
        </div>
      );
    } else {
      return (
        <Image src="/images/videos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getUploadDate() {
    if (horseVideo && horseVideo.createdAt) {
      return dateToText(horseVideo.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  function handleDelete(e) {
    if (e) {
      e.preventDefault();
    }
    const videoId = horseVideo.horseVideoId;
    onDelete(videoId);
  }

  function getDisplayName() {
    if (horseVideo && horseVideo.id) {
      return 'Video: ' + horseVideo.id;
    } else {
      return 'Video: ';
    }
  }

  function getDeleteButton() {
    if (onDelete) {
      return (
        <Button onClick={handleDelete} variant="danger">
          Delete
        </Button>
      );
    }
  }

  return (
    <td>
      <div className="card">
        {renderPlayer()}
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          <p>{getDeleteButton()}</p>
        </div>
      </div>
    </td>
  );
}
