/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Image } from 'react-bootstrap';
import moment from 'moment';
import ModalImage from 'react-modal-image';
/* eslint-enable no-unused-vars */

export default function TackPhotoCard({ tackPhoto }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function renderImgTag() {
    if (
      tackPhoto &&
      tackPhoto.amazons3URLHalf &&
      tackPhoto.amazons3URLHalf.length > 0
    ) {
      return (
        <ModalImage
          small={tackPhoto.amazons3URLHalf}
          medium={tackPhoto.amazons3URLHalf}
          large={tackPhoto.amazons3URL}
          alt="Hello World!"
          key={tackPhoto.id}
        />
      );
    }
    if (
      tackPhoto &&
      tackPhoto.amazons3URL &&
      tackPhoto.amazons3URL.length > 0
    ) {
      return (
        <ModalImage
          small={tackPhoto.amazons3URL}
          medium={tackPhoto.amazons3URL}
          alt="Hello World!"
          key={tackPhoto.id}
        />
      );
    } else {
      return (
        <Image src="/images/photos/blank.png" alt="Avatar" rounded responsive />
      );
    }
  }

  function getDisplayName() {
    if (tackPhoto && tackPhoto.title) {
      return tackPhoto.title;
    } else {
      if (tackPhoto && tackPhoto.description) {
        return tackPhoto.description;
      } else if (tackPhoto) {
        return 'id:' + tackPhoto.id;
      } else {
        return 'null photo';
      }
    }
  }

  function getUploadDate() {
    if (tackPhoto && tackPhoto.createdAt) {
      return dateToText(tackPhoto.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  return (
    <td>
      <div className="card">
        {renderImgTag()}
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
        </div>
      </div>
    </td>
  );
}
