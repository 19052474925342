import { SET_SALE_HORSES, SALE_HORSE_FETCHED } from '../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SALE_HORSE_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.salehorse.id) {
          // Item UPDATED
          itemFound = true;
          return action.salehorse;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.salehorse);
      }
      return [...newState];
    case SET_SALE_HORSES:
      return action.salehorses;
    default:
      return state;
  }
}
