import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../../redux/actions';
import { IoMdLogOut } from 'react-icons/io';

function LogoutButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleClick() {
    logoutUser(dispatch);
    navigate('/');
  }

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      startIcon={<IoMdLogOut />}
      color="info"
    >
      Logout
    </Button>
  );
}

export default LogoutButton;
