import React from 'react';
import { PopoverPicker } from './PopoverPicker';

export default function ColorProperties({ eventDivision, pickColor, onColorChange }) {

  function handleChange(color) {
    onColorChange(eventDivision, color);
  }

  return (
    <div>
      <PopoverPicker
        color={pickColor}
        onChange={handleChange}
      />
    </div>
  );
}
