/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateCurrentUser } from '../../../redux/actions/users/userActions';
import { fetchBarns } from '../../../redux/actions/users/barnActions';
import { redirect } from 'react-router';
import UserEditForm from './UserEditForm';
/* eslint-enable no-unused-vars */

class UserFormPage extends Component {
  state = {
    redirect: false,
  };

  componentDidMount = () => {

  };

  updateCurrentUser = ({
    id,
    firstName,
    lastName,
    cell,
  }) => {
    if (id) {
      return this.props
        .updateCurrentUser({
          id,
          firstName,
          lastName,
          cell,
        })
        .then(() => {
          this.setState({ redirect: true });
        });
    }
  };

  render() {
    return (
      <div>
        {this.state.redirect ? (
          redirect("/account")
        ) : (
          <UserEditForm
            updateCurrentUser={this.updateCurrentUser}
            auth={this.props.auth}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
      auth: state.auth,
      barns: state.barns,
    };
}

export default connect(
  mapStateToProps,
  { updateCurrentUser, fetchBarns }
)(UserFormPage);
