/* (--- AUTO GEN ---) */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
/* eslint-disable max-len */
import {
  fetchCommunityInvitesToMe,
  acceptCommunityInvite,
  declineCommunityInvite,
} from '../../../redux/actions/users/communityInviteActions';
/* eslint-enable max-len */
import _ from 'lodash';
import CommunityInvitesToMeTableRow from './CommunityInvitesToMeTableRow';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class CommunityInvitesToMeTable extends Component {
  constructor(props, context) {
  super(props, context);
  this.doAcceptClick = this.doAcceptClick.bind(this);
  this.doDeclineClick = this.doDeclineClick.bind(this);
}
  componentDidMount() {
    this.props.fetchCommunityInvitesToMe();
  }

  renderCommunityInvitesToMe() {
    return _.values(this.props.communityInvitesToMe).map(
      (communityInviteToMe) => {
        return (
          <CommunityInvitesToMeTableRow
            communityInviteToMe={communityInviteToMe}
            key={communityInviteToMe.id}
            auth={this.props.auth}
            acceptClick={this.doAcceptClick}
            declineClick={this.doDeclineClick}
          />
        );
      }
    );
  }

  doAcceptClick(invite) {
    // window.confirm('Accepted!' + invite.id);    
    this.props
    .acceptCommunityInvite({
      id: invite.id,
      invitingUserId: invite.userId,
    })
    .catch((err) =>
      err.response
        .json()
        .then(({ errors }) => this.setState({ errors, loading: false }))
    );
  }

  doDeclineClick(invite) {
    this.props
    .declineCommunityInvite({
      id: invite.id,
    })
    .catch((err) =>
      err.response
        .json()
        .then(({ errors }) => this.setState({ errors, loading: false }))
    );
  }

  render() {
    return (
      <Table striped bordered    hover>
        <thead>
          <tr>
            <th>Invited By</th>
            <th halign="center">Accept</th>
            <th halign="center">Decline</th>
          </tr>
        </thead>
        <tbody>{this.renderCommunityInvitesToMe()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ communityInvitesToMe, auth }) {
  return { communityInvitesToMe, auth };
}

export default connect(mapStateToProps, {
  fetchCommunityInvitesToMe,
  acceptCommunityInvite,
  declineCommunityInvite,
})(CommunityInvitesToMeTable);
