/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTrainerVideos, deleteTrainerVideo } from '../../../redux/actions/trainerActions';
import _ from 'lodash';
import TrainerVideoCard from '../../core/TrainerVideoCard';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TrainerVideoGroup extends Component {
  componentDidMount() {
    if (this.props.trainerId) {
      this.props.fetchTrainerVideos(this.props.trainerId);
    }
  }

  handleDeleteVideo = ({ videoId }) => {
    this.props
      .deleteTrainerVideo({
        trainerId: this.props.trainerId,
        trainerVideoId: videoId,
      });
  };

  getUserLoggedInIsTrainer(){
    return this.props.auth && this.props.trainerId && this.props.auth.id === this.props.trainerId;
  }

  renderTrainerVideos() {
    if (this.props.trainerVideos) {
      return _.values(this.props.trainerVideos).map((trainerVideo) => {
        return (
          <tr key={trainerVideo.id}>
            <TrainerVideoCard
              trainerVideo={trainerVideo}            
              onDelete={this.handleDeleteVideo}
              onMakeMainVideo={null}
              userLoggedInIsTrainer={this.getUserLoggedInIsTrainer()}
            />
          </tr>
        );
      });
    }
    return null;
  }

  render() {
    return (
      <Table striped bordered    hover>
        <tbody>{this.renderTrainerVideos()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ trainerVideos, auth }) {
  return { trainerVideos, auth };
}

export default connect(mapStateToProps, { fetchTrainerVideos, deleteTrainerVideo })(TrainerVideoGroup);
