import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { PinMapFill } from 'react-bootstrap-icons';
import ModalImage from 'react-modal-image';
import {
  prelimMapButton,
  trainingMapButton,
  noviceMapButton,
  begNoviceMapButton,
  starterMapButton,
} from '../core/MapTools';
import HelmetTracker from '../core/HelmetTracker';

const cardTheme = 'info';
const buttonTheme = 'danger';

export default function Maps() {
  return (
    <>
      <HelmetTracker title="Maps" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant={cardTheme}>
            <ListGroup>
              <ListGroupItem>
                <h3>
                  <PinMapFill />
                  &nbsp;&nbsp;Maps
                </h3>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Course Maps</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>{prelimMapButton()}</ListGroupItem>
                <ListGroupItem>{trainingMapButton()}</ListGroupItem>
                <ListGroupItem>{noviceMapButton()}</ListGroupItem>
                <ListGroupItem>{begNoviceMapButton()}</ListGroupItem>
                <ListGroupItem>{starterMapButton()}</ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Address</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup className="pb-3">
                <ListGroupItem>3828 Wagener Rd</ListGroupItem>
                <ListGroupItem>Aiken, SC 29805</ListGroupItem>
                <ListGroupItem>
                  The ONLY GPS that has accurate maps near us is GOOGLE MAPS!
                </ListGroupItem>
              </ListGroup>
              <Button
                variant={buttonTheme}
                href="https://www.google.com/maps/place/Full+Gallop+Farm+-+Barn+A,+3828+Wagener+Rd,+Aiken,+SC+29805/@33.5722481,-81.5841106,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8546a0eccd06d:0x40b37c872084e0d8!8m2!3d33.5722573!4d-81.5819417"
              >
                Google GPS
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Main Farm Layout</Card.Title>
            </Card.Header>
            <Card.Body>
              <ModalImage
                small="/maps/FarmStallLayout2018.PNG"
                medium="/maps/FarmStallLayout2018.PNG"
                large="/maps/FarmStallLayout2018.PNG"
                alt="Hello World!"
                key="0"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Paddock Map</Card.Title>
            </Card.Header>
            <Card.Body>
              <ModalImage
                small="/maps/PaddockMap.png"
                medium="/maps/PaddockMap.png"
                large="/maps/PaddockMap.png"
                alt="Hello World!"
                key="1"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
