import {
  EVENT_DIVISIONS_FETCHED,
  EVENT_DIVISION_CREATED,
  EVENT_DIVISION_UPDATED,
} from '../../actions/types';

const INITIAL_STATE = [];

export default function reduce(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_DIVISIONS_FETCHED:
      return [...action.eventDivisions];
    case EVENT_DIVISION_CREATED:
      return [...state, action.eventDivision];
    case EVENT_DIVISION_UPDATED:
      var newState = state.map((item) => {
        if (item.id === action.eventDivision.id) {
          return action.eventDivision;
        } else return item;
      });
      return [...newState];
    default:
      return state;
  }
}
