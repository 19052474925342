/* AUTO GEN */
/* Permission.js */
export default class PermissionProperties {
  constructor(name, type) {
    this.types = [
      { key: 'None', value: 0 },
      { key: 'Integer', value: 1 },
      { key: 'String', value: 2 },
      { key: 'Date', value: 3 },
      { key: 'Boolean', value: 4 },
      { key: 'JSON', value: 5 },
    ];
    this.name = name;
    this.type = type;
  }

  getTypeName() {
    return this.types[this.type].key;
  }

  toString() {
    return this.name + ' - ' + this.getTypeName();
  }
}
