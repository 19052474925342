import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { PlusCircle, XCircle } from 'react-feather';

import { useSelector } from 'react-redux';
import HorseLogForm from './HorseLogForm';

import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { spacing } from '@mui/system';

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 700;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : 'primary']};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

export default function HorseLogs({ horse }) {
  const [selected, setSelected] = React.useState(-1);
  const [creating, setCreating] = React.useState(false);

  const auth = useSelector((state) => state.auth);
  const logs = horse ? horse.horseLogs : [];


  function logOwnerChip(name, isUser) {
    if (isUser) {
      return <Chip label={name} color="info" />;
    } else {
      return <Chip label={name} color="error" />;
    }
  }

  function logTypeChip(type) {
    if (type === 0) {
      return <Chip label={'System'} color="error" />;
    } else if (type === 1) {
      return <Chip label={'User'} color="info" />;
    } else {
      return <Chip label={'None'} color="error" />;
    }
  }

  const handleClick = (event, id) => {
    setSelected(id);

    setCreating(false);
  };

  const handleFormOkClick = (event) => {
    setCreating(false);
    setSelected(-1);
  };

  const handleFormCancelClick = (event) => {
    setCreating(false);
  };

  const handleAddCancelClick = (event) => {
    if (selected > 0) {
      setSelected(-1);
    } else {
      setCreating(!creating);
    }
  };

  // For multi row select
  //   const isSelected = (id) => selected.indexOf(id) !== -1;

  // Single select
  const isSelected = (id) => selected === id;

  function mapBody(logs) {
    if (auth && logs && logs.length > 0) {
    //   console.log(logs);
    //   console.log(auth);
      const tableBody = logs.map((log) => {
        const isItemSelected = isSelected(log.id);
        return (
          <Fragment key={log.id}>
            <TableRow
              key={log.id}
              aria-checked={isItemSelected}
              onClick={(event) => handleClick(event, log.id)}
              selected={isItemSelected}
            >
              <TableCell component="th" scope="row">
                {log.id}
              </TableCell>
              <TableCell>
                {logOwnerChip(log.userName, log.userId === auth.id)}
              </TableCell>
              <TableCell>{log.log}</TableCell>
              <TableCell>{logTypeChip(log.type)}</TableCell>
              <TableCell>{log.createdAt}</TableCell>
            </TableRow>
          </Fragment>
        );
      });
      return tableBody;
    }
  }

  function showLogsForm() {
    if (creating) {
      return (
        <HorseLogForm
          auth={auth}
          horse={horse}
          onOkClick={handleFormOkClick}
          onCancelClick={handleFormCancelClick}
        />
      );
    }
  }

  const title = horse ? 'Logs for ' + horse.name : 'Logs for (Loading)';

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton
            aria-label="settings"
            size="large"
            onClick={(event) => handleAddCancelClick(event)}
          >
            {creating || selected > 0 ? <XCircle /> : <PlusCircle />}
          </IconButton>
        }
        title={title}
      />
      {showLogsForm()}
      <Paper>
        <TableWrapper>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Log Id</TableCell>
                <TableCell>User</TableCell>
                <TableCell>Log</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{mapBody(logs)}</TableBody>
          </Table>
        </TableWrapper>
      </Paper>
    </Card>
  );
}
