import React from 'react';
import { Stack } from '@mui/material';
import ProductItemsPage from '../../store/pages/ProductItemsPage';
// import { CheckoutElement } from '../../stripe/CheckoutElement';
// import { Signature } from '../../stripe/Signature';

// FAIRLY USELESS FOR NOW
// Adding in so we can add additional functionality at a later time,
// such as viewing records and other sub data.  Or Entries.

export default function ReleasePage() {
  return (
    <Stack>
        Release
      <ProductItemsPage />
      {/* <CheckoutElement /> */}
      {/* <Signature /> */}
    </Stack>
  );
}
