import * as Sentry from '@sentry/react';

// src/app/sentryMiddleware.js

// const sentryMiddleware = store => next => action => {
//     Sentry.addBreadcrumb({
//       category: 'redux-action',
//       message: action.type,
//       level: Sentry.Severity.Info,
//     });
  
//     return next(action);
//   };

  const sentryMiddleware = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
    // EXAMPLE of how to OMIT
    // actionTransformer: (action) => {
    //     if (action.type === "GOVERNMENT_SECRETS") {
    //       // Return null to not log the action to Sentry
    //       return null;
    //     }
    //     if (action.type === "SET_PASSWORD") {
    //       // Return a transformed action to remove sensitive information
    //       return {
    //         ...action,
    //         password: null,
    //       };
    //     }
    
    //     return action;
    //   },

    // EXAMPLE how to remove state
    // stateTransformer: (state) => {
    //     if (state.topSecret.doNotSend) {
    //       // Return null to not send this version of the state.
    //       return null;
    //     }
    
    //     // Transform the state to remove sensitive information
    //     const transformedState = {
    //       ...state,
    //       topSecret: {
    //         ...state.topSecret,
    //         // Replace sensitive information with something else
    //         nuclearLaunchCodes: "I love pizza",
    //         // or just remove it entirely
    //         hiddenTreasureLocation: null,
    //       },
    //       // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
    //       giganticState: null,
    //     };
    
    //     return transformedState;
    //   },

      stateTransformer: (state) => {
        // Transform the state to remove sensitive information
        const transformedState = {
          ...state,
          auth: {
            ...state.auth,
            favorites: null,
            perms: null,
          },
          // You should also remove large data that is irrelevant to debugging to not clutter your Sentry issues
          communityUsers: null,
          divisions: null,
          entries: null,
          eventDivisions: null,
          eventSelectables: null,
          eventLevels: null,
          eventTests: null,
          jumps: null,
          medias: null,
          mediaFeed: null,
          rings: null,
          scheduleBlocks: null,
          searchedUsers: null,
          tasks: null,
          userEntries: null,
        };
    
        return transformedState;
      },
  });
  
  export default sentryMiddleware;
  