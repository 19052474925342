import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import HelmetTracker from '../core/HelmetTracker';

const cardTheme = 'primary';
const buttonTheme = 'secondary';

export default function Boarding() {
  return (
    <>
      <HelmetTracker title="Boarding" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant={cardTheme}>
            <ListGroup>
              <ListGroupItem>
                <h3>
                  <Easel />
                  &nbsp;&nbsp;Boarding
                </h3>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Facility Maps</Card.Title>
            </Card.Header>
            <Card.Body>
              <Button glyph="globe" href="/maps" variant={buttonTheme}>
                Click to see facility Maps
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Full Gallop Boarding Rates</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card className="mb-3">
                <Card.Header>
                  <Card.Title>Pasture Board - $450 per month</Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Horse to be turned out in large pasture in a group with
                    other geldings or mares. Hay is provided when fields are not
                    in growth, and we provide feeding twice a day. Owner
                    provides feed.
                  </p>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Dry Stall Self Care Board - $450 per month
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <p>
                    Includes a stall and rotational turn-out in an individual or
                    group pasture depending on the owner’s wishes. Availability
                    dependent.
                  </p>
                </Card.Body>
              </Card>
            </Card.Body>
            <Card.Footer>
              * Custom boarding programs are available upon request
            </Card.Footer>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Temporary and Winter Boarding</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card className="mb-3">
                <Card.Header>
                  <Card.Title>Stalls (Bulk shavings included)</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>$50 for first night</ListGroupItem>
                    <ListGroupItem>$25 every additional night</ListGroupItem>
                    <ListGroupItem>$450 per month</ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>Hookups</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem>$50 per night</ListGroupItem>
                    <ListGroupItem>$450 per month</ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} lg={4}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Additional Services Available</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>Grooming</ListGroupItem>
                <ListGroupItem>Blanketing</ListGroupItem>
                <ListGroupItem>De-worming</ListGroupItem>
                <ListGroupItem>Clipping</ListGroupItem>
                <ListGroupItem>Handle horse for vets</ListGroupItem>
                <ListGroupItem>Handle horse for farriers</ListGroupItem>
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <Card.Title as="h4">
                Please contact us for any additional needs
              </Card.Title>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
}
