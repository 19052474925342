import React from 'react';
import EventEntries from '../core/EventEntries';
import HelmetTracker from '../../core/HelmetTracker';

// FAIRLY USELESS FOR NOW
// Adding in so we can add additional functionality at a later time,
// such as viewing records and other sub data.  Or Entries.

export function UserEntriesPage() {
  return (
    <>
      <HelmetTracker title="My Entries" />
      <EventEntries />
    </>
  );
}

export default UserEntriesPage;
