import React from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router-dom';
import LoginFormik from '../../formik/LoginFormik';
import HelmetTracker from '../../core/HelmetTracker';

function NewLogin() {
  let { sourcePage, sourceId } = useParams();

  const form = (
    <Card variant="success">
      <Card.Header>
        <Card.Title>Login to System</Card.Title>
      </Card.Header>
      <Card.Body>
        <Card variant="warning">
          <Card.Header>
            <Card.Title>Local Login</Card.Title>
          </Card.Header>
          <Card.Body>
            <LoginFormik source={sourcePage} sourceId={sourceId} />
          </Card.Body>
          <Card.Footer>
            <h2>Never logged in?</h2>
            <LinkContainer to={'/createaccount'}>
              <Button variant="info">Create Account</Button>
            </LinkContainer>
          </Card.Footer>
        </Card>
      </Card.Body>
      <Card.Footer>
        <h2>Need to reset your password?</h2>
        <LinkContainer to={'/initiateresetpass'}>
          <Button variant="danger">Reset Password</Button>
        </LinkContainer>
      </Card.Footer>
    </Card>
  );

  return (
    <>
      <HelmetTracker title="Login" />
      <Row className="show-grid">
        <Col xs={12} sm={12} md={12}>
          {form}
        </Col>
      </Row>
    </>
  );
}

export default NewLogin;
