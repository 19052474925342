import React from 'react';
import { Image } from 'react-bootstrap';
import { ButtonToolbar } from 'react-bootstrap';
import { eventImage } from '../../core/EventTools';
import { datesToText } from '../../core/DateTools';
import { Button } from 'react-bootstrap';
import {
  renderOmnibusButton,
  renderEntryStatusPdfButton,
  renderEntryFormButton,
  renderRideTimesButton,
  renderRideTimesPdfButton,
  renderResultsButton,
  renderStablingButton,
  renderEntryStatusButton,
  renderProgramPdfButton,
  renderEnterOnlineButton,
  sjCoursesButton,
  xcCoursesButton,
} from '../../core/CalendarTools';

export default function CalendarTableRow(props) {
  const divStyle = {
    float: 'left',
  };

  const margin = 'mx-1';
  const DOC_TYPE_INFO = 7;

  function showDocs(event) {
    if (event && event.docs) {
      return event.docs
        .filter((document) => document.type === DOC_TYPE_INFO)
        .map((filteredDoc) => (
          <Button
            variant="info"
            href={filteredDoc.amazons3URL}
            className={margin}
          >
            {filteredDoc.title}
          </Button>
        ));
    } else {
      return '';
    }
  }

  function renderTableRow(event) {
    return (
      <tr key={event.id}>
        <td>
          <div>
            <h4>{datesToText(event.startDate, event.endDate, event.oneDay)}</h4>
          </div>
        </td>
        <td>
          <div>
            <h4>{event.title}</h4>
          </div>
          <div style={divStyle}>
            <Image src={eventImage(event)} className="mx-1" />
            {sjCoursesButton(event)}
            {xcCoursesButton(event)}
          </div>
        </td>
        <td>
          <h5>{event.description}</h5>
          <ButtonToolbar>
            {renderOmnibusButton(event)}
            {renderResultsButton(event)}
            {renderEntryFormButton(event)}
            {renderRideTimesButton(event)}
            {renderRideTimesPdfButton(event)}
            {renderStablingButton(event)}
            {renderEntryStatusButton(event)}
            {renderEntryStatusPdfButton(event)}
            {renderProgramPdfButton(event)}
            {renderEnterOnlineButton(event)}
            {showDocs(event)}
          </ButtonToolbar>
        </td>
      </tr>
    );
  }

  return renderTableRow(props.event);
}
