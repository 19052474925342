/* AUTO GEN */
/* eventHorsesReducer.js */
import { SET_EVENT_HORSES, EVENT_HORSE_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

const eventHorsesReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case EVENT_HORSE_FETCHED:
      var newArray = state.filter(item => item.id !== action.eventHorse.id);
      newArray.push(action.eventHorse);
      return newArray;
    case SET_EVENT_HORSES:
      // const newPosts = _.mapKeys(action.eventHorses, 'id');
      return action.eventHorses;
    default:
      return state;
  }
}
export default eventHorsesReducer;
