import React from 'react';
import {
  Row,
  Col,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
  Button,
} from 'react-bootstrap';
import { Easel } from 'react-bootstrap-icons';
import HelmetTracker from '../core/HelmetTracker';

const cardTheme = 'warning';
const buttonTheme = 'primary';

export default function ContactUs() {
  return (
    <>
      <HelmetTracker title="Contact Us" />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Alert variant={cardTheme}>
            <ListGroup>
              <ListGroupItem>
                <h3>
                  <Easel />
                  &nbsp;&nbsp; Contact Us
                </h3>
              </ListGroupItem>
            </ListGroup>
          </Alert>
        </Col>
      </Row>
      <Row className="pb-3">
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Mailing Address</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup className="mb-3">
                <ListGroupItem>3828 Wagener Rd</ListGroupItem>
                <ListGroupItem>Aiken, SC 29805</ListGroupItem>
                <ListGroupItem>
                  The ONLY GPS that has accurate maps near us is GOOGLE MAPS!
                </ListGroupItem>
              </ListGroup>
              <Button
                variant={buttonTheme}
                href="https://www.google.com/maps/place/Full+Gallop+Farm+-+Barn+A,+3828+Wagener+Rd,+Aiken,+SC+29805/@33.5722481,-81.5841106,17z/data=!3m1!4b1!4m5!3m4!1s0x88f8546a0eccd06d:0x40b37c872084e0d8!8m2!3d33.5722573!4d-81.5819417"
              >
                Google GPS
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Cell Phones</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>Lara Anderson - (803) 215-6590</ListGroupItem>
                <ListGroupItem>Daniel Brown - (803) 295-2421</ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={6} md={6} lg={6}>
          <Card bg={cardTheme}>
            <Card.Header>
              <Card.Title>Emails</Card.Title>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroupItem>
                  Lara Anderson - fullgallopfarm@gmail.com
                </ListGroupItem>
                <ListGroupItem>
                  Daniel Brown - innotivity@gmail.com
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Card bg={cardTheme} className="mb-3">
            <Card.Header>
              <Card.Title>Facility Maps</Card.Title>
            </Card.Header>
            <Card.Body>
              <Button glyph="globe" href="/maps" variant={buttonTheme}>
                Click to see facility Maps
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
