/* AUTO GEN */
/* productCategoriesReducer.js */
import { SET_PRODUCTS, PRODUCT_FETCHED } from '../../actions/types';

const INITIAL_STATE = [];

const productsReducer = function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRODUCT_FETCHED:
      var itemFound = false;
      var newState = state.map((item) => {
        if (item.id === action.product.id) {
          // Item UPDATED
          itemFound = true;
          return action.product;
        } else return item;
      });
      if (!itemFound) {
        // Item CREATED
        newState.push(action.product);
      }
      return [...newState];
    case SET_PRODUCTS:
      return action.products;
    default:
      return state;
  }
};

export default productsReducer;
