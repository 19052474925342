import React from 'react';
import { Form } from 'react-final-form';
import {
  finalTextField,
  finalObjSelector,
  renderFormTitle,
  okCancel,
} from '../../core/FinalCoreForms';
import { useDispatch } from 'react-redux';
import {
  createProduct,
  updateProduct,
} from '../../../redux/actions/store/productsActions';
import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';

const _ = require('lodash');

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const schema = Yup.object().shape({
  name: Yup.string().required(),
  type: Yup.number().required(),
  categoryId: Yup.number().required(),
});

const validate = makeValidate(schema);

const ProductForm = (props) => {
  const dispatch = useDispatch();

  function submitProduct(product) {
    if (product && product.id) {
      return dispatch(updateProduct(product)).then(() => {
        //   navigateBack();
      });
    } else {
      return dispatch(createProduct(product)).then(() => {
        //   navigateBack();
      });
    }
  }

  const initialValues = {
    id: props.product && props.product.id ? props.product.id : null,
    type: props.product && props.product.type ? props.product.type : 0,
    name: props.product && props.product.name ? props.product.name : '',
    description:
      props.product && props.product.description
        ? props.product.description
        : '',
    categoryId:
      props.product && props.product.categoryId ? props.product.categoryId : 0,
  };

  const typeOptionsList = [
    { key: 'None', value: '0' },
    { key: 'Inventory', value: '1' },
    { key: 'Service', value: '2' },
    { key: 'Entry', value: '3' },
  ];

  const onSubmit = async (values) => {
    await sleep(300);
    if (props.product) {
      Object.assign(props.product, values);
      submitProduct(props.product);
    } else {
      let newData = {};
      Object.assign(newData, values);
      submitProduct(newData);
    }
    if (props.onOkClick) {
      props.onOkClick();
    }
  };

  function productCategoriesList() {
    // var zeroExists = false;
    if (props.productCategories) {
      let array = _.map(props.productCategories, (idobj) => {
        // if (parseInt(idobj.id) === 0) {
        //   zeroExists = true;
        // }
        return {
          value: idobj.id,
          key: idobj.categoryName,
        };
      });
      // Needs to be a category with a parent category to be in system
      // if (!zeroExists) {
      //   array.splice(0, 0, { value: null, key: 'None' });
      // }
      return array;
    }
    return null;
  }

  return (
    <div>
      {renderFormTitle(props.product, 'Product')}

      <Form
        initialValues={initialValues}
        onSubmit={onSubmit} validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            {finalObjSelector('type', 'Type', typeOptionsList)}
            {finalTextField('name', 'Name')}
            {finalTextField('description', 'Description')}
            {finalObjSelector(
              'categoryId',
              'Category',
              productCategoriesList()
            )}

            {okCancel(props.onOkClick)}
            <pre>{JSON.stringify(values, 0, 2)}</pre>
          </form>
        )}
      />
    </div>
  );
};

export default ProductForm;
