import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MenuItemsNav from './core/menus/MenuItemsNav';
import UserMenuItems from './core/menus/UserMenuItems';
import AdminMenuItems from './core/menus/AdminMenuItems';
import LoginMenuItem from './core/NavItems/LoginMenuItem';

const clientVersion = 'v2.8.0';

function Navigation() {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  const showNavMenu = false;

  return (
    <div>
      {showNavMenu &&
        !location.pathname.includes('scheduling') &&
        !location.pathname.includes('user') &&
        !location.pathname.includes('events') &&
        !location.pathname.includes('horses') &&
        !location.pathname.includes('pages') && (
          <Navbar
            bg="primary"
            variant="dark"
            expand="lg"
            collapseOnSelect
            sticky="top"
          >
            <Navbar.Brand className="ms-4">
              <LinkContainer to={'/'}>
                <Nav.Item>Full Gallop Farm</Nav.Item>
              </LinkContainer>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <MenuItemsNav />
              <Nav className="me-5 justify-content-end">
                {auth ? <UserMenuItems /> : null}
                {auth && auth.isAdmin() ? <AdminMenuItems version={clientVersion} /> : null}
              </Nav>
              {auth ? null : <LoginMenuItem />}
            </Navbar.Collapse>
          </Navbar>
        )}
    </div>
  );
}

export default Navigation;
