/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTrainerPhotos } from '../../../redux/actions/trainerActions';
import _ from 'lodash';
import TrainerTableRow from './TrainerTableRow';
import TrainerPhotoCard from '../../core/TrainerPhotoCard';
import { Table } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class TrainerPhotoGroup extends Component {
  componentDidMount() {
    if (this.props.trainerId) {
      this.props.fetchTrainerPhotos(this.props.trainerId);
    }
  }

  renderTrainerPhotos() {
    if (this.props.trainerPhotos) {
      return _.values(this.props.trainerPhotos).map((trainerPhoto) => {
        return (
          <tr key={trainerPhoto.id}>
            <TrainerPhotoCard
              trainerPhoto={trainerPhoto}
              onDelete={null}
              onMakeMainPhoto={this.props.onMakeMainPhoto}
            />
          </tr>
        );
      });
    }
    return null;
  }

  render() {
    return (
      <Table striped bordered    hover>
        <tbody>{this.renderTrainerPhotos()}</tbody>
      </Table>
    );
  }
}

function mapStateToProps({ trainerPhotos, auth }) {
  return { trainerPhotos, auth };
}

export default connect(mapStateToProps, { fetchTrainerPhotos })(
  TrainerPhotoGroup
);
